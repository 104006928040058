import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalApiUrlService } from '../global-api-url.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http:HttpClient,
    private url: GlobalApiUrlService
  ) { }

  uploadFileService(data: any) {
    if(data){
      const formData = new FormData()
      formData.append('file', data)      
      var API_URL = this.url.REST_API + '/import-calendrier'
      return this.http.post(API_URL, formData)
    }
  }

  uploadTableauDisplay(data:any){
    if(data){
      const formData = new FormData()
      formData.append('file', data.file)      
      formData.append('id_departement', data.id_departement)      
      formData.append('id_onglet', data.id_onglet)    
      formData.append('id_sous_departement', data.id_sous_departement)    
      var API_URL = this.url.REST_API + '/import-display-excel'
      return this.http.post(API_URL, formData)
    }
  }

  downloadFormationProcedureService(data:any = null){
    var API_URL = this.url.REST_API + '/download-formation-procedure';
    return this.http.post(API_URL, data, {responseType: 'blob'})
  }

  uploadDemandeExterneService(data:any){
    if(data){
      const formData = new FormData()
      formData.append('file', data.file)         
      var API_URL = this.url.REST_API + '/import-demande-externe'
      return this.http.post(API_URL, formData)
    }
  }

  importationDataLMS(data:any){
    if(data){
      const formData = new FormData()
      formData.append('file', data.file)         
      var API_URL = this.url.REST_API + '/import-data-lms'
      return this.http.post(API_URL, formData)
    }
  }

  importationDataMF(data:any){
    if(data){
      const formData = new FormData()
      formData.append('file', data.file)         
      var API_URL = this.url.REST_API + '/import-data-mf'
      return this.http.post(API_URL, formData)
    }
  }
  
}
