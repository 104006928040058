import { CdkDragDrop, CdkDragMove, CdkDragRelease, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { DateTime } from 'luxon';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/services/users/users.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  joursSemaine: { jour: string; date: Date; formateurs: any }[] = [];
  month: string = '';
  year: number = 0;
  formateurs: any
  selectedWeek: any
  heures_travail = [
    '07:00:00', '08:00:00', '09:00:00', '10:00:00', '11:00:00', '12:00:00',
    '13:00:00', '14:00:00', '15:00:00', '16:00:00', '17:00:00', '18:00:00'
  ];
  heures = ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'];
  activites: any
  liste_activite: any
  new_activite: any = ""
  new_formateur: any = ""
  liste_to_select: any
  is_loading: any = false
  is_updating:any = false

  is_downloading:any = false

  constructor(
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private userService: UsersService
  ) {
    this.getUser()
    this.getCurrentWeek()
  }

  access:any = false
  user_log:any = []
  role_access:any = [99,10]
  is_formateur:any = false
  id_formateur:any

  exporterTableau(){
    this.is_downloading = true;
    const current_date = DateTime.fromJSDate(new Date()).setZone('UTC').toFormat("yyyy-MM-dd")
    this.calendrierService.exportAgendaService().subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${current_date} Agenda des formateurs`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }
  
  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      this.user_log = data
      this.access = this.role_access.includes(this.user_log.role)
      this.is_formateur = this.user_log.role == 9
      if(this.is_formateur) {
        this.id_formateur = this.user_log.id_formateur
      }
      // console.log(this.user_log)
    })
  }

  ngOnInit(): void {
  }
  
  getCurrentWeek() {
    this.getActivite()
    this.is_loading = true
    const today = DateTime.local();
    // const today = DateTime.fromISO("2025-04-02");
    const offsetInMinutes = today.offset
    const offsetInHours = offsetInMinutes / 60;
    const formattedOffset = (offsetInHours >= 0 ? '+' : '') + offsetInHours;
    var formattedDate = formattedOffset == "-5" ? today.plus({days:1}).toFormat("yyyy-MM-dd") : today.toFormat('yyyy-MM-dd');
    const weekNumber = DateTime.fromISO(formattedDate).weekNumber
    const year = DateTime.fromISO(formattedDate).year;
    // const currentDate = new Date(formattedDate);    
    // const firstDayOfYear = new Date(year, 0, 1);
    // const days = Math.floor((currentDate.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000));
    // const weekNumber = Math.ceil((days + 1) / 7);
    this.selectedWeek = `${year}-W${String(weekNumber).padStart(2, '0')}`;
    this.changeWeek(this.selectedWeek)
  }

  changeWeek(week: any) {
    var splitedWeekData = week.split('-')
    this.year = splitedWeekData[0]
    var weekNumber = splitedWeekData[1].toString().substring(1)
    var mondayOfweek = this.getMondayOfWeek(this.year, weekNumber)
    this.month = this.getMoisEnFrancais(mondayOfweek)
    this.setJoursSemaine(mondayOfweek)
  }

  getMondayOfWeek(year: any, week: any) {
    const date = new Date(year, 0, 1);
    const dayOfWeek = date.getDay();
    const daysToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
    date.setDate(date.getDate() - daysToMonday);
    date.setDate(date.getDate() + (week - 1) * 7);
    return date;
  }

  setJoursSemaine(debutSemaine: any) {
    this.joursSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi'].map((jour, i) => ({
      jour,
      date: new Date(debutSemaine.getTime() + i * 24 * 60 * 60 * 1000),
      formateurs: []
    }));
    var startDate = DateTime.fromJSDate(new Date(this.joursSemaine[0].date)).toFormat("yyyy-MM-dd")
    var endDate = DateTime.fromJSDate(new Date(this.joursSemaine[3].date)).toFormat("yyyy-MM-dd")
    this.getAgenda(startDate, endDate)
  }

  getMoisEnFrancais(date: Date): string {
    return new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date);
  }

  getFormateur() {
    this.calendrierService.getFormateurService().subscribe((data: any) => {
      this.formateurs = data
      this.adaptationData()
      // console.log(this.formateurs)
    })
  }

  adaptationData() {
    this.joursSemaine.forEach(js => {
      this.formateurs.forEach(f => {
        if (f.statut != 0) {
          js.formateurs.push({ nom_formateur: f.nom_formateur, id_formateur: f.id_formateur, activite: [], color_formateur: f.color })
        }
      });
      this.heures_travail.forEach(ht => {
        js.formateurs.forEach(jsf => {
          jsf.activite.push({ heure: ht, libelle_activite: "", duree: 1, color: "" })
        });
      })
      this.activites.forEach(acvt => {
        var date_activite = DateTime.fromJSDate(new Date(acvt.date_activite)).toFormat("yyyy-MM-dd")
        var date_week = DateTime.fromJSDate(new Date(js.date)).toFormat("yyyy-MM-dd")
        if (date_activite == date_week) {
          js.formateurs.forEach(jsf => {
            if (acvt.id_formateur == jsf.id_formateur) {
              jsf.activite.forEach(jsfa => {
                if (acvt.heure_debut == jsfa.heure) {
                  jsfa.libelle_activite = acvt.libelle_activite
                  jsfa.duree = acvt.duree,
                  jsfa.color = jsfa.libelle_activite.toLowerCase().includes("congé") ? "#f50000" : this.getRandomColor()
                }
              });
            }
          });
        }
      })
    })
    this.is_loading = false
    this.joursSemaine.map(j => j.formateurs.map(f => f.activite)).flat().forEach(data => {
      data.forEach((element, index) => {
        if(element.duree > 1) {
          data.splice((index+1), (element.duree-1))
        }
      });
    })
  }

  deleteInPlage(plage: any, objets: any) {
    plage.forEach(heure => {
      const index = objets.findIndex(item => item.heure == heure)
      if (index != -1) objets.splice(index, 1)
    })
    return objets
  }

  getAgenda(startDate: any, endDate: any) {
    var dataObj = { from: startDate, to: endDate }
    this.calendrierService.getAgendaService(dataObj).subscribe((data: any) => {
      let data_verifie = this.supprimerActiviteEnDouble(data)
      let result = this.decalageHeures(data_verifie)      
      result.forEach(i => {
        i.heure_debut = i.heure_debut.replace(":30:00",":00:00")
        i.heure_fin = i.heure_fin.replace(":30:00",":00:00")
        i.duree = (parseInt(i.heure_fin) - parseInt(i.heure_debut))
        i.plage_horaire = this.genererPlageHoraire(i.heure_debut, i.heure_fin)
        let date_format = DateTime.fromISO(i.date_activite).toFormat("yyyy-MM-dd")
        if (date_format != i.date_activite_text) {
          i.date_activite = DateTime.fromISO(i.date_activite).plus({ days: 1 }).toJSDate()
        }
        this.insertDataFormationActivite(i.id_formateur, i.heure_debut, i.heure_fin, i.date_activite, i.libelle_activite)
      })
      this.activites = result
      this.getFormateur()
    })
  }

  insertDataFormationActivite(id_formateur: any, heure_debut: any, heure_fin:any, jour: any, activite: any){
    const result = this.liste_activite.filter(i => i.libelle_activite == activite)
    var dataObj = {
      id_formateur: id_formateur,
      heure_debut: heure_debut,
      heure_fin: heure_fin,
      id_activite: result.length == 0 ? "" : result[0].id_activite,
      libelle_activite: activite,
      date: DateTime.fromJSDate(new Date(jour)).toFormat("dd/MM/yyyy")
    }
    this.calendrierService.updateAgendaService(dataObj).subscribe((data: any) => { })
  }

  updateActiviteAgenda(formateur: any, heure: any, duree: any, jour: any, activite: any, add: boolean = false) {
    this.is_updating = true
    const result = this.liste_activite.filter(i => i.libelle_activite == activite)
    var dataObj = {
      id_formateur: formateur.id_formateur,
      heure_debut: heure,
      heure_fin: add ? this.ajoutHeureLuxon(heure, duree + 1) : this.ajoutHeureLuxon(heure, duree - 1),
      id_activite: result.length == 0 ? "" : result[0].id_activite,
      libelle_activite: activite,
      date: DateTime.fromJSDate(new Date(jour)).toFormat("dd/MM/yyyy")
    }
    this.calendrierService.updateAgendaService(dataObj).subscribe((data: any) => { this.is_updating = false })
  }

  supprimerActiviteEnDouble(activites: any[]): any[] {
    const activitesMap = new Map<string, any[]>();
    activites.forEach(activite => {
      const key = `${activite.date_activite}-${activite.id_formateur}-${activite.libelle_activite}`;
      const groupeActivites = activitesMap.get(key) || [];
      groupeActivites.push(activite);
      activitesMap.set(key, groupeActivites);
    });
    const activitesFiltrees: any[] = [];
    activitesMap.forEach(groupeActivites => {
      if (groupeActivites.length > 1) {
        const activiteASupprimer = groupeActivites.find(a => a.num_formation != null);
        activitesFiltrees.push(...groupeActivites.filter(a => a !== activiteASupprimer));
      } else {
        activitesFiltrees.push(...groupeActivites);
      }
    });

    return activitesFiltrees;
  }

  decalageHeures(formations) {
    let formateursDateMap = {};
    formations.forEach(formation => {
      const { id_formateur, date_activite, heure_debut, heure_fin, num_formation } = formation;
      if(num_formation != null){
        const key = `${id_formateur}-${date_activite}`;
        if (!formateursDateMap[key]) {
          formateursDateMap[key] = [];
        }
      
        const index = formateursDateMap[key].length;
        const newHeureDebut = this.incrementHeure(heure_debut, index * 2);
        const newHeureFin = this.incrementHeure(heure_fin, index * 2);
        formation.heure_debut = newHeureDebut;
        formation.heure_fin = newHeureFin;
        formateursDateMap[key].push(formation);
      }
    });
  
    return formations;
  }
  
  incrementHeure(heure, increment) {
    let [h, m, s] = heure.split(':').map(num => parseInt(num, 10));
    h += increment;
    if (h >= 24) {
      h -= 24;
    }
    return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}`;
  }

  genererPlageHoraire(heureDebut, heureFin) {
    let dateDebut = new Date('1970-01-01T' + heureDebut + 'Z');
    let dateFin = new Date('1970-01-01T' + heureFin + 'Z');
    let plageHoraire = [];
    while (dateDebut < dateFin) {
      let heureFormattee = dateDebut.toISOString().substr(11, 8);
      plageHoraire.push(heureFormattee);
      dateDebut.setHours(dateDebut.getHours() + 1);
    }
    return plageHoraire;
  }

  getRandomColor(): string {
    const colorType = Math.floor(Math.random() * 3);

    let r, g, b;

    if (colorType === 0) {
      r = Math.floor(Math.random() * 101) + 150;
      g = Math.floor(Math.random() * 100);
      b = Math.floor(Math.random() * 100);
    } else if (colorType === 1) {
      r = Math.floor(Math.random() * 101) + 100;
      g = Math.floor(Math.random() * 100);
      b = Math.floor(Math.random() * 101) + 100;
    } else {
      r = Math.floor(Math.random() * 50);
      g = Math.floor(Math.random() * 101) + 100;
      b = Math.floor(Math.random() * 101) + 150;
    }
    const alpha = 0.2;
    return `rgba(${r},${g},${b},${alpha})`;
  }

  getActivite() {
    this.calendrierService.getActiviteService().subscribe((data: any) => {
      this.liste_activite = data
      this.liste_to_select = data.filter(d => d.is_formation == 0)
    })
  }

  AddHour(formateur: any, heure: any, duree: any, jour: any, activite: any) {
    let index = formateur.activite.findIndex(item => item.heure == heure)
    if (formateur.activite.length > index + 1) {
      let next_activite = formateur.activite[index + 1]
      if (next_activite.libelle_activite == "") {
        formateur.activite.forEach(f => {
          if (f.heure == heure) {
            f.duree += 1
            let new_index = index + 1
            formateur.activite.splice(new_index, 1)
          }
        })
        this.updateActiviteAgenda(formateur, heure, duree, jour, activite, true)
      }
    }
  }

  RemoveHour(formateur: any, heure: any, duree: any, jour: any, activite: any) {
    let index = formateur.activite.findIndex(item => item.heure == heure);
    if (index !== -1) {
      let activity = formateur.activite[index]
      if (activity.duree > 1) {
        activity.duree -= 1
        let newHour = new Date(`1970-01-01T${activity.heure}Z`)
        newHour.setHours(newHour.getHours() + 1)
        let newHeure = newHour.toISOString().substr(11, 8)
        let newActivity = {
          heure: newHeure,
          libelle_activite: "",
          duree: 1,
          color: ""
        };
        formateur.activite.splice(index + 1, 0, newActivity)
        this.updateActiviteAgenda(formateur, heure, duree, jour, activite, false)
      }
      else {
        const result = this.liste_activite.filter(i => i.libelle_activite == activite)
        if (result[0].is_formation != 1) {
          Swal.fire({
            title: "Suppression",
            text: "Etes vous sûr de vouloir supprimer cette activité?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui, supprimée",
            cancelButtonText: "Annuler",
          }).then((result) => {
            if (result.isConfirmed) {
              this.addOrDeleteActivite(formateur, activite, heure, jour, false)
              activity.libelle_activite = ""
              activity.color = ""
            }
          });
        }
      }
    }
  }

  addOrDeleteActivite(formateur: any, activite: any, heure: any, jour: any, add: any = true) {
    const result = this.liste_activite.filter(i => i.libelle_activite == activite)
    var dataObj = {
      id_formateur: formateur.id_formateur,
      id_activite: result[0].id_activite,
      date: DateTime.fromJSDate(new Date(jour)).toFormat("dd/MM/yyyy"),
      heure_debut: heure,
      heure_fin: this.ajoutHeureLuxon(heure, 1)
    }
    if (add) {
      this.calendrierService.setFormationService(dataObj).subscribe((data: any) => {
        this.toast.Success("Activité enregistrée")
      })
    }
    else {
      this.calendrierService.deleteAgendaService(dataObj).subscribe((data: any) => {
        this.toast.Success("Activité supprimée")
      })
    }
  }

  ajoutHeureLuxon(heure: any, ajout: any) {
    var heure_split = heure.split(':')
    var object = { hour: parseInt(heure_split[0]), minute: parseInt(heure_split[1]), second: parseInt(heure_split[2]) }
    return DateTime.fromObject(object).plus({ hours: ajout }).toFormat("HH:mm:ss")
  }

  setActivite(type: any, libelle: any, id: any) {
    var dataObj = {
      type_action: type,
      libelle_activite: libelle,
      id_activite: id
    }
    if (type == 2) {
      Swal.fire({
        title: "Suppressin",
        text: "Voulez-vous vraiment supprimer cette activité?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimée",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          this.actionSetActivite(dataObj, type, libelle, id)
        }
      });
    }
    else {
      this.actionSetActivite(dataObj, type, libelle, id)
    }
  }

  actionSetActivite(dataObj, type, libelle, id) {
    this.calendrierService.setActiviteService(dataObj).subscribe((data: any) => {
      if (type == 1) {
        this.toast.Success("Nouvelle activité enregistrée")
        this.liste_to_select.push({ id_activite: data[0].id_activite, libelle_activite: libelle, is_formation: 0 })
        this.new_activite = ""
      } else if (type == 2) {
        this.toast.Success("Activité supprimée")
        const index = this.liste_to_select.findIndex(l => l.id_activite == id)
        this.liste_to_select.splice(index, 1)
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.formateurs, event.previousIndex, event.currentIndex);
    var dataObj = { type_action: 3, rang_formateur: [] }
    this.formateurs.forEach((element, index) => {
      element.rang = index + 1
      dataObj.rang_formateur.push({ rang: element.rang, id_formateur: element.id_formateur })
    });
    this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
      this.getCurrentWeek()
    })
  }

  setStatutFormateur(formateur: any) {
    var dataObj = { type_action: 1, statut: formateur.statut, id_formateur: formateur.id_formateur }
    this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
      this.getCurrentWeek()
    })
  }

  deleteFormateur(formateur: any, index: any) {
    Swal.fire({
      title: "Suppressin",
      text: "Voulez-vous vraiment supprimer ce formateur ?\n" + formateur.nom_formateur,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimée",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = { type_action: 2, id_formateur: formateur.id_formateur }
        this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
          this.formateurs.splice(index, 1)
          this.toast.Success("Formateur supprimé")
          this.getCurrentWeek()
        })
      }
    });
  }

  editFormateur(formateur: any) {
    var dataObj = { type_action: 4, id_formateur: formateur.id_formateur, nom_formateur: formateur.nom_formateur }
    this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
      this.toast.Success("Information modifiée")
      this.getCurrentWeek()
    })
  }

  addFormateur(formateur: any) {
    var dataObj = { type_action: 5, nom_formateur: formateur, rang: this.formateurs.length + 1 }
    this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
      this.toast.Success("Formateur ajouté avec succès")
      this.formateurs.push({ nom_formateur: formateur, rang: this.formateurs.length + 1, id_formateur: data[0].id_formateur, statut: 1, color: '#f8f8f8' })
      this.new_formateur = ""
      this.getCurrentWeek()
    })
  }

  setColorFormateur(formateur: any) {
    var dataObj = { type_action: 6, color: formateur.color, id_formateur: formateur.id_formateur }
    this.calendrierService.setFormateurService(dataObj).subscribe((data: any) => {
      this.getCurrentWeek()
    })
  }

}
