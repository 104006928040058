<div class="rounded en-tete shadow-sm d-flex flex-row align-items-center justify-content-between">
    <div class="p-5" style="color: rgb(52, 52, 52);">
        <div class="mb-2">
            <span class="h3">Les sauveteurs en espace clos <span style="font-size: 14px;">🛑⛑️</span></span>
        </div>
        <p>Composée de professionnels hautement entraînés, l’équipe est spécialisée dans les opérations de sauvetage en espace clos, la gestion des atmosphères dangereuses et l’évacuation des travailleurs en milieu minier. Face aux risques d’asphyxie, d’explosions ou d’effondrements, ils maîtrisent les techniques d’intervention en milieux confinés, l’utilisation d’équipements respiratoires avancés et les protocoles de sécurité stricts.</p>
        <p>Leur engagement et leur expertise garantissent un environnement minier plus sûr, où chaque intervention peut sauver des vies.</p>
        <button *ngIf="is_admin" class="btn-special" data-bs-toggle="modal" data-bs-target="#addSauveteur" (click)="resetFormAdd()">Ajouter un sauveteur</button>
    </div>
    <img src="assets/images/PREVENTIONISTE/sauveteur espace clos mining (1).jpg" alt="IMAGE"
        style="width: 140%; height: 100%; object-fit: cover; float: right;">
</div>
<app-load-page *ngIf="is_loading"></app-load-page>
<div class="mt-3" *ngIf="!is_loading" style="overflow: auto; height: 61vh;">
    <table class="table table-sm table-bordered">
        <thead style="position: sticky; top:-1px;">
            <tr>
                <th>Nom, Prénom</th>
                <th># employé</th>
                <th>Département</th>
                <th *ngIf="is_admin"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of liste_sauveteur_espace_clos; let i = index">
                <td>{{item.nom_prenom}}</td>
                <td>{{item.matricule}}</td>
                <td>{{item.departement | capitalizeFirstLetter}}</td>
                <td *ngIf="is_admin">
                    <button class="btn btn-sm btn-outline-warning border-0" data-bs-toggle="modal" data-bs-target="#addSauveteur" (click)="toEditSauvateurMinier(item.id_sauveteur_espace_clos)"> <i class="bi bi-pen-fill" style="font-size: 12px;"></i> </button>
                    <button class="btn btn-sm btn-outline-danger border-0 ms-1" (click)="deleteSauveteurMinier(item.id_sauveteur_espace_clos, i)"> <i class="bi bi-trash-fill" style="font-size: 12px;"></i> </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>


<!-- Modal -->
<div class="modal fade" id="addSauveteur" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h4 class="modal-title fs-5" id="exampleModalLabel">
                        <span *ngIf="!is_editing">Ajout d'un sauveteur en espace clos</span>
                        <span *ngIf="is_editing">Modification d'un sauveteur en espace clos</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="input-group input-group-sm mb-2" style="width: 100%">
                                <span class="input-group-text" id="basic-addon1">Recherche travailleur</span>
                                <input type="text" class="form-control" list="nom_prenom_matricule" [(ngModel)]="travailleur_search" (input)="filterWorkers()" (change)="selectingWorker()">
                            </div>
                            <datalist id="nom_prenom_matricule">
                                <option *ngFor="let worker of filteredTravailleurs" [value]="worker.unique_search">{{worker.id_personne}}</option>
                            </datalist>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="nom" [(ngModel)]="formAddSauveteurMinier.nom_sauveteur_espace_clos" [ngClass]="{'is-invalid': is_verify && formAddSauveteurMinier.nom_sauveteur_espace_clos == '' }" placeholder="name@example.com">
                                <label for="nom">Nom *</label>
                              </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="prenom" [(ngModel)]="formAddSauveteurMinier.prenom_sauveteur_espace_clos" [ngClass]="{'is-invalid': is_verify && formAddSauveteurMinier.prenom_sauveteur_espace_clos == '' }" placeholder="name@example.com">
                                <label for="prenom">Prénom *</label>
                              </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="Matricule" [(ngModel)]="formAddSauveteurMinier.matricule" [ngClass]="{'is-invalid': is_verify && formAddSauveteurMinier.matricule == '' }" placeholder="name@example.com">
                                <label for="Matricule">Matricule *</label>
                              </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="Département" [(ngModel)]="formAddSauveteurMinier.departement" [ngClass]="{'is-invalid': is_verify && formAddSauveteurMinier.departement == '' }" placeholder="name@example.com">
                                <label for="Département">Département *</label>
                              </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <button class="btn-special" (click)="saveSauveteur()" [disabled]="is_saving"> 
                            <i class="bi bi-save me-1" style="font-size: 11px;" *ngIf="!is_saving"></i> 
                            <div class="spinner-border spinner-border-sm me-1" *ngIf="is_saving" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        Enregistrer</button>
                        <button *ngIf="!is_editing" [disabled]="is_saving" class="btn btn-sm ms-2 me-3" style="background-color: #e9e9e9;" (click)="resetFormAdd()"> Réinitialiser </button>
                        <button *ngIf="is_editing" [disabled]="is_saving" class="btn btn-sm ms-2 me-3" style="background-color: #e9e9e9;" data-bs-dismiss="modal"> Annuler </button>
                        <span style="font-size: 10px;" class="text-secondary"> (*) Champs obligatoire </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>