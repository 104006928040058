import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mesure-urgence',
  templateUrl: './mesure-urgence.component.html',
  styleUrls: ['./mesure-urgence.component.css']
})
export class MesureUrgenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
