<!-- <div class="d-flex flex-row align-items-end justify-content-between">
    <div class="d-flex flex-row align-items-center">
        <div class="p-3 rounded shadow-sm text-center me-2" appDragdrop style="background-color: white;"
            (dropped)="onFilesDropped($event, true)" *ngIf="!is_uploading_lms">
            <span class="text-secondary text-center" style="font-size: 12px;"> <i class="bi bi-download me-1"></i> Déposer
                ici le fichier LMS à importer </span>
        </div>
        <div class="text-center text-secondary p-3 me-2 rounded shadow-sm" style="background-color: white;"
            *ngIf="is_uploading_lms">
            <div class="spinner-grow spinner-grow-sm text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    
    
        <div class="p-3 rounded shadow-sm text-center" appDragdrop style="background-color: white;"
            (dropped)="onFilesDropped($event, false)" *ngIf="!is_uploading_mf">
            <span class="text-secondary text-center" style="font-size: 12px;"> <i class="bi bi-download me-1"></i> Déposer
                ici
                le fichier MF à importer</span>
        </div>
        <div class="text-center text-secondary p-3 rounded shadow-sm" style="background-color: white;"
            *ngIf="is_uploading_mf">
            <div class="spinner-grow spinner-grow-sm text-success" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
    <div class="me-4 d-flex flex-row align-items-center">
        <button class="btn-special me-2" (click)="refreshTable()">Rafraichir l'affichage</button>  
        <div class="bg-white rounded px-3 py-1 border d-flex flex-row align-items-center">
            <span style="font-size: 14px;" class="text-secondary">Données affichées : {{nombre_data_affiche}}/10</span>
            <span class="me-3 ms-3" style="font-size: 10px;">|</span>
            <span style="font-size: 14px;" class="text-secondary">Nb données validées : {{nombre_data_valide}}</span>
            <span class="me-3 ms-3" style="font-size: 10px;">|</span>
            <span style="font-size: 14px;" class="text-secondary">Nb données restantes : {{nombre_data_restante}}</span>
        </div>
    </div>
</div>

<app-load-page *ngIf="is_loading"></app-load-page>
<div class="mt-3" *ngIf="!is_loading" style="overflow: auto; max-height: 84vh;">
    <div *ngFor="let item of data_affiche; let i = index" style="overflow-x: auto; width: 160%;">
        <span class="text-secondary" style="font-size: 13px;"> Différence : {{item.total_difference}}</span>
        <span *ngIf="item.total_difference >= 8" style="font-size: 11px;" class="text-secondary"> > Donnée probablement inexistante dans LMS ou MF</span>
        <button class="btn-special ms-3" (click)="validationData(item.id_lms_data, item.id_mf_data, i)" [disabled]="is_validating"> 
            <i class="bi bi-check-lg" style="font-size: 10px;"></i> Valider
        </button>
        <div class="mb-4 mt-1 d-flex flex-row align-items-center">
            <div class="card" style="width: 16rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_nom_prenom == 'ok', 'diff-ko':item.difference_nom_prenom == 'ko'}" style="font-size: 13px;">Nom & Prénom</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_nom_prenom}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_nom_prenom}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 8rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_matricule == 'ok', 'diff-ko':item.difference_matricule == 'ko'}" style="font-size: 13px;">Matricule</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_matricule}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_matricule}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 11rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_id == 'ok', 'diff-ko':item.difference_id == 'ko'}" style="font-size: 13px;">ID Personne</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_id_personne}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_id_personne}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 8rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_sexe == 'ok', 'diff-ko':item.difference_sexe == 'ko'}" style="font-size: 13px;">Sexe</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.ld_sexe}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_sexe}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 10rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_date_naissance == 'ok', 'diff-ko':item.difference_date_naissance == 'ko'}" style="font-size: 13px;">Date naissance</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_date_naissance}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_date_naissance}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 8rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_statut == 'ok', 'diff-ko':item.difference_statut == 'ko'}" style="font-size: 13px;">Statut</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_statut}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_statut}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 17rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_departement == 'ok', 'diff-ko':item.difference_departement == 'ko'}" style="font-size: 13px;">Département</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_departement}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_departement}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 18rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_employeur == 'ok', 'diff-ko':item.difference_employeur == 'ko'}" style="font-size: 13px;">Employeur</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_employeur}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_employeur}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 10rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_date_debut == 'ok', 'diff-ko':item.difference_date_debut == 'ko'}" style="font-size: 13px;">Date début</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_date_debut}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_date_debut}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 10rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_date_fin == 'ok', 'diff-ko':item.difference_date_fin == 'ko'}" style="font-size: 13px;">Date fin</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.lms_date_fin}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_date_fin}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 15rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_nom_utilisateur == 'ok', 'diff-ko':item.difference_nom_utilisateur == 'ko'}" style="font-size: 13px;">Username LMS</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.ld_nom_utilisateur}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_lms_username}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 20rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_courriel_pro == 'ok', 'diff-ko':item.difference_courriel_pro == 'ko'}" style="font-size: 13px;">Courriel PRO</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.ld_mail_pro}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_courriel_pro}}
                    </li>
                </ul>
            </div>
            <div class="card ms-2" style="width: 20rem;">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item text-center" [ngClass]="{'diff-ok':item.difference_courriel_perso == 'ok', 'diff-ko':item.difference_courriel_perso == 'ko'}" style="font-size: 13px;">Courriel PERSO</li>
                    <li class="list-group-item data-li">
                        <span>LMS:</span> {{item.ld_mail_perso}}
                    </li>
                    <li class="list-group-item data-li">
                        <span>MF:</span> {{item.mf_courriel_perso}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->


<p>En cours de développement</p>