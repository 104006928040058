import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { FormationService } from 'src/app/services/formation/formation.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css']
})
export class ListeComponent {

  liste_formation: any = []
  liste_formation_temp: any = []
  liste_formation_dynamique: any = []
  is_loading: any = false
  
  constructor(
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private fonction:FormationService
  ) {
    this.getDataSelect()
    this.getListeFormateur()
  }

  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    num_formation: false,
    nom_formation: false,
    categorie: false,
    interne: false,
    resultat: false,
    nb_heure_theorie: false,
    nb_heure_pratique: false,
    examen: false,
    annee_refresh: false,
    inclus_matrice: false,
    suivi_requisition: false,
    accessible_demande_superviseur: false
  }
  is_editing: any = false
  is_verifing:any = false

  formFormation: any = {
    nom_formation: "",
    num_formation: "",
    categorie: "",
    interne_externe: "",
    theorie: 0,
    pratique: 0,
    refresh: 0,
    examen: "",
    resultat: "",
    matrice: "",
    suivi: "",
    superviseur: "",
    formateurs: []
  }

  liste_resultat: any = []
  liste_categorie: any = []
  liste_formateur:any = []
  liste_formateur_dynamique:any = []
  formateur_added:any = ""

  getListeFormateur(){
    this.is_loading = true
    this.calendrierService.getFormateurService().subscribe((data:any) => {
      this.liste_formateur = data
      this.liste_formateur_dynamique = [...this.liste_formateur]
      this.getListeFormation()
    })
  }

  getListeFormation() {
    this.calendrierService.getListeFormationService().subscribe((data: any) => {
      this.liste_formation = data
      this.liste_formation_dynamique = [...this.liste_formation]
      this.liste_formation_temp = [...this.liste_formation]
      this.is_loading = false
    })
  }

  getDataSelect() {
    this.calendrierService.getDataSelectFormationService().subscribe((data: any) => {
      this.liste_resultat = data[1]
      this.liste_categorie = data[0]
    })
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.liste_formation_dynamique = this.liste_formation_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.liste_formation_dynamique = this.liste_formation_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.liste_formation_dynamique = [...this.liste_formation]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  deleteFormation(id: any, index: any) {
    var dataObj = { id_liste_formation: id, traitement: 0 }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette formation?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.traitementListeFormationService(dataObj).subscribe(() => {
          this.liste_formation_dynamique.splice(index, 1)
          this.liste_formation_temp.splice(index, 1)
          this.toast.Success("Formation supprimée")
        })
      }
    });
  }

  resetFormFormation(){
    this.is_verifing = false
    this.is_editing = false
    this.formFormation = {
      nom_formation: "",
      num_formation: "",
      categorie: "",
      interne_externe: "",
      theorie: 0,
      pratique: 0,
      refresh: 0,
      examen: "",
      resultat: "",
      matrice: "",
      suivi: "",
      superviseur: "",
      formateurs: []
    }
  }

  saveFormation(){
    this.is_verifing = true
    if(this.formFormation.nom_formation == "" || this.formFormation.num_formation == "" || this.formFormation.categorie == "") return
    if(this.formFormation.interne_externe == "" || this.formFormation.theorie == null || this.formFormation.pratique == null) return
    if(this.formFormation.refresh == null || this.formFormation.examen == "" || this.formFormation.resultat == "") return
    if(this.formFormation.matrice == "" || this.formFormation.suivi == "" || this.formFormation.superviseur == "") return
    this.formFormation.traitement = this.is_editing ? 2 : 1
    this.calendrierService.traitementListeFormationService(this.formFormation).subscribe((data:any) => {
      if(!this.is_editing) {
        this.resetFormFormation() 
        this.toast.Success("Formation ajoutée")
      } else {
        this.toast.Success("Formation modifiée")
        this.formFormation.save_formateurs = this.formFormation.formateurs
      }
      this.getListeFormation()
    })
  }

  toEditFormation(rows:any){
    this.is_editing = true
    this.formFormation.nom_formation = this.fonction.mettrePremierMotEnInitCap(rows.nom_formation)
    this.formFormation.num_formation = rows.num_formation
    this.formFormation.categorie = rows.id_cat_formation
    this.formFormation.interne_externe = rows.interne ? "1" : "0" 
    this.formFormation.theorie = parseInt(rows.nb_heure_theorie)
    this.formFormation.pratique = parseInt(rows.nb_heure_pratique)
    this.formFormation.refresh = parseInt(rows.annee_refresh)
    this.formFormation.examen = rows.examen ? "1" : "0"
    this.formFormation.resultat = rows.id_type_formation
    this.formFormation.matrice = rows.inclus_matrice ? "1" : "0"
    this.formFormation.suivi = rows.suivi_requisition ? "1" : "0"
    this.formFormation.superviseur = rows.accessible_demande_superviseur ? "1" : "0"
    this.formFormation.id_liste_formation = rows.id_liste_formation
    this.formFormation.save_formateurs = rows.formateurs
    this.formFormation.formateurs = [...this.formFormation.save_formateurs][0] == null ? [] : [...this.formFormation.save_formateurs]
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !this.formFormation.formateurs.some(f => f == l.id_formateur))]
  }

  formateurName(id:any){
    if(id != null && id != undefined && id != ""){
      return this.liste_formateur.find(l => l.id_formateur == id).nom_formateur
    }
    return ""
  }

  addFormateurToFormation(id:any, row:any){
    row.formateurs.push(id)
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !row.formateurs.some(f => f == l.id_formateur))]
    this.formateur_added = ""
  }

  dropFormateurFormation(row:any, index:any){
    row.formateurs.splice(index, 1)
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !row.formateurs.some(f => f == l.id_formateur))]
  }
}
