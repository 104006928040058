import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { MenuService } from '../services/menu/menu.service';
import { UsersService } from '../services/users/users.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CryptageService } from '../services/cryptage/cryptage.service';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportationComponent } from '../formation/importation/importation.component';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../services/toast/toast.service';

@Component({
  selector: 'app-body-form',
  templateUrl: './body-form.component.html',
  styleUrls: ['./body-form.component.css']
})
export class BodyFormComponent implements OnInit {

  liste_menu: any
  menuResize: boolean = true
  titleApplication: any = { name_application: "", nameMenuMax: "", nameMenuMin: "" }
  info_user: any
  username: any
  role: any
  titleComponent: any = ""
  role_access:any = [99,10]
  is_admin:any = false
  acces_fichier:any = [99,10,9,8]

  constructor(
    private mainComponent: AppComponent,
    private menuService: MenuService,
    private userService: UsersService,
    private router: Router,
    private cryptageService: CryptageService,
    private modalService: NgbModal,
    private cookies:CookieService,
    private toast:ToastService
  ) {
    sessionStorage.setItem("titleComponent","")
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      const activeRoute = this.router.routerState.root.firstChild?.snapshot.routeConfig?.path;
      this.changeTitle(activeRoute, "")
    });
    this.titleComponent = this.cryptageService.decryptDecode(sessionStorage.getItem("titleComponent"))
    this.getTitreApp()
    this.getUser()
  }

  openModal() {
    if(this.is_admin){
      this.modalService.open(ImportationComponent, {
        backdrop: 'static',
        keyboard: false,
        scrollable: true
      });
    } else {
      this.toast.Warning("Partie réservée: L'accès vous est interdit sans les permissions appropriées.")
    }
  }

  ngOnInit(): void { }
  logout() { this.mainComponent.logout() }
  changerMenuSize() { this.menuResize = !this.menuResize }

  getTitreApp() {
    this.menuService.getTitre().subscribe((data: any) => {
      this.titleApplication = {
        name_application: data[0].titreLogin,
        nameMenuMax: data[0].titreMenuMax,
        nameMenuMin: data[0].titreMenuMin
      }
    })
  }

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.info_user = data; 
      this.username = this.info_user.prenom_user + " " + this.info_user.nom_user; 
      this.role = this.info_user.role
      this.is_admin = this.role_access.includes(this.role)
      this.getMenu(this.role)
    })
  }

  getMenu(role:any){
    var accessibilite = 1
    if (role == 10) accessibilite = 3
    this.menuService.getMenu(accessibilite).subscribe((data: any) => {
      this.liste_menu = data
      
      const index_fichier_reference = this.liste_menu.filter(l => l.nom_menu == 'AUTRE')[0].sous_menu.findIndex(s => s.route_sous_menu.includes('reference'))
      if(!this.acces_fichier.includes(this.role)) this.liste_menu.filter(l => l.nom_menu == 'AUTRE')[0].sous_menu.splice(index_fichier_reference, 1)
      
        const index_historique = this.liste_menu.filter(l => l.nom_menu == 'AUTRE')[0].sous_menu.findIndex(s => s.route_sous_menu.includes('historique-journal'))
      if(!this.role_access.includes(this.role)) this.liste_menu.filter(l => l.nom_menu == 'AUTRE')[0].sous_menu.splice(index_historique, 1)
    })
  }

  changeTitle(route: any, routeSM: any) {
    const currentRoute = route || routeSM;
    this.titleComponent = this.router.config.find(route => route.path == currentRoute)?.data?.titreComponent
    sessionStorage.setItem("titleComponent", this.cryptageService.encryptEncode(this.titleComponent))
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 100);
    const a = 0.4;
    return `rgba(${r},${g},${b},${a})`;
  }

}
