import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalApiUrlService } from '../global-api-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CalendrierService {

  constructor(private http: HttpClient, private url: GlobalApiUrlService) { }

  getDataCalendrierService(data:any) {
    var API_URL = this.url.REST_API + '/get-calendrier';
    return this.http.post(API_URL, data, httpOptions)
  }

  getDataTableauService() {
    var API_URL = this.url.REST_API + '/get-tableau';
    return this.http.get(API_URL)
  }
}
