<div class="py-2 px-4">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
            <input type="week" id="week" class="form-control" name="week" [(ngModel)]="selectedWeek"
                (change)="changeWeek(selectedWeek)">
            <button class="btn btn-sm btn-outline-secondary ms-2" data-bs-toggle="modal"
                data-bs-target="#param-activite" [disabled]="is_loading" *ngIf="access"> <i class="bi bi-gear-fill"></i>
            </button>
            <button class="btn-special ms-2" (click)="exporterTableau()" style="width: 150px;">
                <span class="spinner-border spinner-border-sm me-1" aria-hidden="true" *ngIf="is_downloading"></span>
                <span *ngIf="!is_downloading"> <i class="bi bi-file-earmark-excel me-1" style="font-size: 12px;"></i></span>
                Exporter</button>
        </div>
        <div>
            <span class="h3">{{month | capitalizeFirstLetter}} {{year}}</span>
        </div>
    </div>

    <app-load-page *ngIf="is_loading"></app-load-page>
    <div *ngIf="!is_loading" style="overflow: auto; height: 84vh; margin-top: 10px;">
        <div *ngFor="let jour of joursSemaine; let x = index" class="mt-3">
            <table class="table table-sm table-bordered" style="vertical-align: middle; text-align: center;">
                <thead>
                    <tr>
                        <th style="width: 12%;">{{ jour.jour }} {{ jour.date | date: 'dd/MM' }}</th>
                        <th style="width: 7%;">07:00</th>
                        <th style="width: 7%;">08:00</th>
                        <th style="width: 7%;">09:00</th>
                        <th style="width: 7%;">10:00</th>
                        <th style="width: 7%;">11:00</th>
                        <th style="width: 7%;">12:00</th>
                        <th style="width: 7%;">13:00</th>
                        <th style="width: 7%;">14:00</th>
                        <th style="width: 7%;">15:00</th>
                        <th style="width: 7%;">16:00</th>
                        <th style="width: 7%;">17:00</th>
                        <th style="width: 7%;">18:00</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let formateur of jour.formateurs; let index_formateur = index">
                        <td style="font-size: 13px; padding: 0px 10px;">
                            <div class="d-flex flex-row align-items-center">
                                <div [ngStyle]="{'background-color': formateur.color_formateur}" class="test border">
                                </div>
                                <span class="ms-3">{{ formateur.nom_formateur }}</span>
                            </div>
                        </td>
                        <ng-container *ngFor="let activite of formateur.activite let index_activite = index">
                            <td *ngIf="activite.libelle_activite != ''" [attr.colspan]="activite.duree"
                                [ngStyle]="{'background-color': activite.color}" class="texte-activite">
                                <div style="position: relative;">
                                    <span>{{activite.libelle_activite}}</span>
                                    <div style="position: absolute; top: 50%; left: -12px; transform: translateY(-50%);"
                                        class="button-activite d-flex flex-column justify-content-center align-items-center px-1"
                                        *ngIf="!is_updating">
                                        <i class="bi bi-plus-circle-fill plus-activite"
                                            *ngIf="access || (is_formateur && id_formateur == formateur.id_formateur)"
                                            (click)="AddHour(formateur, activite.heure, activite.duree, jour.date, activite.libelle_activite)"
                                            disabled></i>
                                        <i class="bi bi-dash-circle-fill delete-activite"
                                            *ngIf="access || (is_formateur && id_formateur == formateur.id_formateur)"
                                            (click)="RemoveHour(formateur, activite.heure, activite.duree, jour.date, activite.libelle_activite)"></i>
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="activite.libelle_activite == ''"
                                [ngStyle]="{'background-color': activite.color}">
                                <select class="form-select bg-white form-select-sm select-small-arrow"
                                    style="padding: 2px;" [(ngModel)]="activite.libelle_activite"
                                    *ngIf="access || (is_formateur && id_formateur == formateur.id_formateur)"
                                    (change)="activite.color = activite.libelle_activite.toLowerCase().includes('congé') ? '#f50000' : getRandomColor(); addOrDeleteActivite(formateur, activite.libelle_activite, activite.heure, jour.date, true)">
                                    <option value="" selected></option>
                                    <option style="font-size: 11px;" *ngFor="let liste of liste_to_select"
                                        [value]="liste.libelle_activite"> {{liste.libelle_activite}}</option>
                                </select>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
            <hr *ngIf="(x+1) != joursSemaine.length">
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="param-activite" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                        <h5>Paramétrage</h5>
                        <ul class="nav nav-tabs ms-3">
                            <li class="nav-item">
                                <a class="nav-link active" style="font-size: 12px;" data-bs-toggle="tab"
                                    href="#formateur">Formateur</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" style="font-size: 12px;" data-bs-toggle="tab"
                                    href="#activite">Activité</a>
                            </li>
                        </ul>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="formateur">
                        <div class="mt-4">
                            <!-- <div class="d-flex flex-row align-items-center justify-content-between">
                                <div class="bg-white border mb-1 rounded" style="width: 85%;">
                                    <input class="form-control border-0" [(ngModel)]="new_formateur"
                                        placeholder="Prénom & nom formateur"
                                        style="font-size: 13px;">
                                </div>
                                <div style="width: 15%;" class="text-center ms-2">
                                    <button class="btn btn-sm btn-outline-success border-0"
                                        [disabled]="new_formateur == ''" (click)="addFormateur(new_formateur)"> <i
                                            class="bi bi-check-circle" style="font-size: 15px;"></i></button>
                                    <button class="btn btn-sm btn-outline-secondary border-0"
                                        (click)="new_formateur = ''"> <i class="bi bi-arrow-clockwise"
                                            style="font-size: 15px;"></i></button>
                                </div>
                            </div>
                            <hr> -->
                            <div cdkDropList (cdkDropListDropped)="drop($event)">
                                <ng-container *ngFor="let formateur of formateurs; let i = index">
                                    <div class="d-flex flex-row align-items-center justify-content-between mb-1"
                                        cdkDrag>
                                        <div style="width: 5%;" class="ms-1">
                                            <span>{{i+1}}</span>
                                        </div>
                                        <div class="example-box px-2 py-1 rounded bg-white border d-flex flex-row align-items-center justify-content-between"
                                            style="width: 60%;">
                                            <input type="text" class="form-control form-control-sm p-0 border-0"
                                                [(ngModel)]="formateur.nom_formateur"
                                                (change)="editFormateur(formateur)" style="font-size: 13px;" readonly>
                                            <div cdkDragHandle> <i class="bi bi-list"></i> </div>
                                        </div>
                                        <div style="width: 10%;" class="ms-2">
                                            <input type="color" class="form-control form-control-sm form-control-color"
                                                id="exampleColorInput" [(ngModel)]="formateur.color"
                                                (change)="setColorFormateur(formateur)" title="Choose your color">
                                        </div>
                                        <div style="width: 18%;" class="ms-2">
                                            <select class="form-select form-select-sm" style="font-size: 12px;"
                                                (change)="setStatutFormateur(formateur)" [(ngModel)]="formateur.statut">
                                                <option value="0">Inactif</option>
                                                <option value="1">Actif</option>
                                            </select>
                                        </div>
                                        <!-- <div style="width: 8%;">
                                            <button class="btn btn-sm btn-outline-danger border-0 ms-2" (click)="deleteFormateur(formateur, i)"> <i class="bi bi-trash" style="font-size: 15px;"></i></button>
                                        </div>  -->
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="activite">
                        <div class="mt-4">
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <div class="bg-white border mb-1 rounded" style="width: 85%;">
                                    <textarea class="form-control border-0" [(ngModel)]="new_activite" rows="0" cols="0"
                                        placeholder="Nouvelle activité"
                                        style="height: 10px; font-size: 13px;"></textarea>
                                </div>
                                <div style="width: 15%;" class="text-center ms-2">
                                    <button class="btn btn-sm btn-outline-success border-0"
                                        [disabled]="new_activite == ''" (click)="setActivite(1, new_activite, '')"> <i
                                            class="bi bi-check-circle" style="font-size: 15px;"></i></button>
                                    <button class="btn btn-sm btn-outline-secondary border-0"
                                        (click)="new_activite = ''"> <i class="bi bi-arrow-clockwise"
                                            style="font-size: 15px;"></i></button>
                                </div>
                            </div>
                            <hr>
                            <ng-container *ngFor="let act of liste_to_select; let i = index">
                                <div class="d-flex flex-row align-items-center justify-content-between"
                                    *ngIf="act.is_formation != 1">
                                    <div style="width: 4%;" class="text-center">
                                        <span>{{i+1}}</span>
                                    </div>
                                    <div class="bg-white mb-1 border rounded ms-2" style="width: 95%;">
                                        <textarea class="form-control border-0"
                                            (change)="setActivite(3, act.libelle_activite, act.id_activite)" rows="0"
                                            cols="0" style="height: 10px; font-size: 13px;"
                                            [(ngModel)]="act.libelle_activite"></textarea>
                                    </div>
                                    <button class="btn btn-sm btn-outline-danger border-0 ms-2"
                                        (click)="setActivite(2, act.libelle_activite, act.id_activite)"> <i
                                            class="bi bi-trash" style="font-size: 15px;"></i></button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>