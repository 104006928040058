import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';

@Component({
  selector: 'app-tableau-formation',
  templateUrl: './tableau-formation.component.html',
  styleUrls: ['./tableau-formation.component.css']
})
export class TableauFormationComponent implements OnInit {

  dataTableau:any

  constructor(
    private calendrierService: CalendrierService
  ) { 
    this.getDataTableau()
  }

  ngOnInit(): void {}

  getDataTableau(){
    this.calendrierService.getDataTableauService().subscribe((data:any) => {
      this.dataTableau = data
      console.log(this.dataTableau)
    })
  }
}
