import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { MenuService } from '../services/menu/menu.service';
import { UsersService } from '../services/users/users.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CryptageService } from '../services/cryptage/cryptage.service';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportationComponent } from '../formation/importation/importation.component';

@Component({
  selector: 'app-body-form',
  templateUrl: './body-form.component.html',
  styleUrls: ['./body-form.component.css']
})
export class BodyFormComponent implements OnInit {

  liste_menu: any
  menuResize: boolean = true
  titleApplication: any = { name_application: "", nameMenuMax: "", nameMenuMin: "" }
  info_user: any
  username: any
  role: any
  titleComponent: any = ""

  constructor(
    private mainComponent: AppComponent,
    private menuService: MenuService,
    private userService: UsersService,
    private router: Router,
    private cryptageService: CryptageService,
    private modalService: NgbModal
  ) {
    sessionStorage.setItem("titleComponent","")
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      const activeRoute = this.router.routerState.root.firstChild?.snapshot.routeConfig?.path;
      this.changeTitle(activeRoute, "")
    });
    this.titleComponent = this.cryptageService.decryptDecode(sessionStorage.getItem("titleComponent"))
    this.getTitreApp()
    this.getUser()
  }

  openModal() {
    this.modalService.open(ImportationComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true
    });
  }

  ngOnInit(): void { }
  logout() { this.mainComponent.logout() }
  changerMenuSize() { this.menuResize = !this.menuResize }

  getTitreApp() {
    this.menuService.getTitre().subscribe((data: any) => {
      this.titleApplication = {
        name_application: data[0].titreLogin,
        nameMenuMax: data[0].titreMenuMax,
        nameMenuMin: data[0].titreMenuMin
      }
    })
  }

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.info_user = data; 
      this.username = this.info_user.nom_user; 
      this.role = this.info_user.role
      this.getMenu(this.role)
    })
  }

  getMenu(role:any){
    var accessibilite = 1
    if (role == 10) accessibilite = 3
    this.menuService.getMenu(accessibilite).subscribe((data: any) => {
      this.liste_menu = data
    })
  }

  changeTitle(route: any, routeSM: any) {
    const currentRoute = route || routeSM;
    this.titleComponent = this.router.config.find(route => route.path == currentRoute)?.data?.titreComponent
    sessionStorage.setItem("titleComponent", this.cryptageService.encryptEncode(this.titleComponent))
  }

}
