import { Component, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { DateTime } from 'luxon';
import { UsersService } from 'src/app/services/users/users.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';

import jspdf from 'jspdf';  
import html2canvas from 'html2canvas'; 
import { lighten } from '@amcharts/amcharts5/.internal/core/util/Utils';


interface Appointment {
  uuid?: string;
  date: Date;
  title: string;
  startTime: string;
  endTime: string;
  color?: string;
}

export enum CalendarView {
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.css']
})
export class CalendrierComponent {

  constructor(
    private modalService: NgbModal,
    private calendrierService: CalendrierService,
    private userService: UsersService,
    private travailleurService: TravailleurService
  ) {
    this.getUser()
    this.generateView(this.currentView, this.viewDate);
    this.generateTimeSlots()
    this.getDataCalendrier()
  }
  user_log: any = []
  access: any = false
  role_access: any = [99, 10]
  is_admin: any = false

  all_travailleurs: any = []
  selected_index_week_to_print:any = ""
  week_to_print:any = []
  is_printing:any = false

  getWeekToPrint(index:any){
    var mois = []
    this.weeks_temp.forEach(week => {mois.push(week.slice(1))})
    mois.forEach(week => week.pop())
    if(index < 6){
      this.week_to_print = [...mois.filter((t,i) => i == index)]
    } else if(index >= 6 && this.formateur_filtered != ''){
      if(index == 6){
        this.week_to_print = [...mois.slice(0, 3)]
      } else if(index == 7){
        this.week_to_print = [...mois.slice(-3)]
      } else if(index == 8){
        this.week_to_print = [...mois]
      }
    }
  }

  getTravailleur() {
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.all_travailleurs = data.filter(d => d.statut_employe == "actif")
      this.all_travailleurs.forEach((worker, index) => {
        worker.unique_search = worker.nom_travailleur_cap + ", " + worker.prenom_travailleur_cap + " (" + worker.matricule + ")"
      })
      this.all_travailleurs.sort((a, b) => a.unique_search.toString().localeCompare(b.unique_search.toString()))
      this.is_loading = false
    })
  }

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.user_log = data
      this.is_admin = this.role_access.includes(this.user_log.role)
    })
  }

  openModal(row: any) {
    const modalRef = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      keyboard: false,
      scrollable: true
    });
    modalRef.componentInstance.rowData = row;
    modalRef.componentInstance.formations = this.formations_dynamique;
    modalRef.componentInstance.is_admin = this.is_admin;
    modalRef.componentInstance.user_log = this.user_log;
    modalRef.componentInstance.travailleurs = this.all_travailleurs;
    modalRef.componentInstance.filteredTravailleurs = this.all_travailleurs;
  }

  viewDate: Date = new Date();
  selectedDate: Date | null = null;
  selectedStartTime: string | undefined;
  weekDays: string[] = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  monthDays: Date[] = [];
  appointments: Appointment[] = [
    {
      uuid: '00000000-0000-0000-0000-000000000001',
      date: new Date("2024-12-17"),
      title: 'Meeting with Bob',
      startTime: '09:00',
      endTime: '10:00',
    }
  ];
  currentView: CalendarView = CalendarView.Month;
  timeSlots: string[] = [];
  weeks: any[][] = [];
  weeks_temp: any[][] = [];
  public formations: any
  public formations_dynamique: any
  liste_formateur: any = []
  formateur_filtered: any = ""
  nombre_formation_mois: any = 0
  formateurs: any = []
  calendrier_formateur: any = []
  filtre_calendrier_formateur: any = []
  is_filtering:any = false
  statuts:any = []

  public CalendarView = CalendarView;

  switchView(view: CalendarView) {
    this.currentView = view;
    this.generateView(this.currentView, this.viewDate);
  }

  generateView(view: CalendarView, date: Date) {
    switch (view) {
      case CalendarView.Month:
        this.generateMonthView(date);
        break;
      case CalendarView.Week:
        this.generateWeekView(date);
        break;
      default:
        this.generateMonthView(date);
    }
  }

  generateMonthView(date: Date) {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.weeks = [];
    this.monthDays = [];
    let week: Date[] = [];

    for (let day = start.getDay(); day > 0; day--) {
      const prevDate = new Date(start);
      prevDate.setDate(start.getDate() - day);
      week.push(prevDate);
      this.monthDays.push(prevDate);
    }

    for (let day = 1; day <= end.getDate(); day++) {
      const currentDate = new Date(date.getFullYear(), date.getMonth(), day);
      this.monthDays.push(currentDate);
      week.push(currentDate);

      if (week.length === 7) {
        this.weeks.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      for (let day = 1; week.length < 7; day++) {
        const nextDate = new Date(end);
        nextDate.setDate(end.getDate() + day);
        week.push(nextDate);
      }
      this.weeks.push(week);
    }

    const updatedArray = this.weeks.map(innerArray => {
      return innerArray.map(date => {
        return {
          date: date,
          day: this.weekDays[date.getDay()]
        };
      });
    });

    this.weeks = updatedArray
    this.weeks_temp = [...updatedArray]
  }


  generateWeekView(date: Date) {
    const startOfWeek = this.startOfWeek(date);
    this.monthDays = [];

    for (let day = 0; day < 7; day++) {
      const weekDate = new Date(startOfWeek);
      weekDate.setDate(startOfWeek.getDate() + day);
      this.monthDays.push(weekDate);
    }

    // console.log(this.monthDays)
  }


  generateTimeSlots() {
    for (let hour = 6; hour <= 16; hour++) {
      const time = hour < 10 ? `0${hour}:00` : `${hour}:00`;
      this.timeSlots.push(time);
    }
  }

  startOfWeek(date: Date): Date {
    const start = new Date(date);
    const day = start.getDay();
    const diff = day === 0 ? 0 : -day;
    return new Date(start.setDate(start.getDate() + diff));
  }


  previous() {
    if (this.currentView === 'month') {
      this.viewDate = new Date(
        this.viewDate.setMonth(this.viewDate.getMonth() - 1)
      );
      this.generateMonthView(this.viewDate);
    } else if (this.currentView === 'week') {
      this.viewDate = new Date(
        this.viewDate.setDate(this.viewDate.getDate() - 7)
      );
      this.generateWeekView(this.viewDate);
    }
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month && DateTime.fromISO(f.date_prevue_text).setZone('UTC').year == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').year).length
    this.selected_index_week_to_print = ""
  }

  next() {
    if (this.currentView === 'month') {
      const dateToAdd = DateTime.fromJSDate(this.viewDate).set({ day: 3 }).toJSDate()
      this.viewDate = new Date(dateToAdd.setMonth(dateToAdd.getMonth() + 1));
      this.generateMonthView(this.viewDate);
    } else if (this.currentView == 'week') {
      this.viewDate = new Date(
        this.viewDate.setDate(this.viewDate.getDate() + 7)
      );
      this.generateWeekView(this.viewDate);
    }
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month && DateTime.fromISO(f.date_prevue_text).setZone('UTC').year == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').year).length
    this.selected_index_week_to_print = ""
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isSelected(date: Date): boolean {
    if (!this.selectedDate) {
      return false;
    }
    return (
      date.getDate() === this.selectedDate.getDate() &&
      date.getMonth() === this.selectedDate.getMonth() &&
      date.getFullYear() === this.selectedDate.getFullYear()
    );
  }

  isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  selectDate(date?: Date, startTime?: string) {
    if (date) {
      this.selectedDate = date;
    } else {
      this.selectedDate = new Date();
    }
    this.selectedStartTime = startTime;
  }

  getAppointmentsForDate(day: Date, timeSlots: string[]) {
    return this.appointments
      .filter((appointment) => {
        return this.isSameDate(appointment.date, day);
      })
      .map((appointment) => {
        const startTimeIndex = timeSlots.indexOf(appointment.startTime);
        const endTimeIndex = timeSlots.indexOf(appointment.endTime);
        return { ...appointment, startTimeIndex, endTimeIndex };
      });
  }

  viewToday(): void {
    this.viewDate = DateTime.now().setZone('UTC').toJSDate();
    if (this.currentView == 'month') {
      this.generateMonthView(this.viewDate);
    } else if (this.currentView == 'week') {
      this.generateWeekView(this.viewDate);
    }
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month && DateTime.fromISO(f.date_prevue_text).setZone('UTC').year == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').year).length
    this.selected_index_week_to_print = ""
  }

  isCurrentMonth(date: Date): boolean {
    return (
      date.getMonth() === this.viewDate.getMonth() &&
      date.getFullYear() === this.viewDate.getFullYear()
    );
  }

  getAppointmentsForDateTime(date: Date, timeSlot: string): Appointment[] {
    const result = this.formations_dynamique.filter((item) =>
      this.isSameDate(item.date_prevue, date) && item.heure_debut <= timeSlot && item.heure_fin > timeSlot
    )
    return result;
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }

  getFormateur() {
    this.calendrierService.getFormateurService().subscribe((data: any) => {
      this.formateurs = data.filter(d => d.statut == 1).sort((a, b) => a.rang - b.rang)
      this.formateurs.forEach(element => {
        element.initial = this.obtenirInitiales(element.nom_formateur)
      });
      this.getListeStatutCalendrierFormateur()
    })
  }

  getListeStatutCalendrierFormateur() {
    this.calendrierService.getListeStatutCalendrierFormateurService().subscribe((data: any) => {
      this.statuts = data
      this.getCalendrierFormateur()
      // console.log(this.statuts)
    })
  }

  getCalendrierFormateur() {
    this.calendrierService.getStatutCalendrierFormateurService().subscribe((data: any) => {
      data.forEach(element => {
        element.date_js = DateTime.fromISO(element.jour_text).setZone("UTC").toJSDate()
        let data_statut = this.statuts.find(s => s.value == element.value)
        element.libelle_statut = data_statut.libelle
        element.color_statut = data_statut.color
      });
      this.calendrier_formateur = data
    })
  }

  is_loading: any = false
  getDataCalendrier() {
    this.is_loading = true
    var dataObj = {}
    this.calendrierService.getDataCalendrierService(dataObj).subscribe((data: any) => {
      data.forEach(item => item.date_prevue = new Date(item.date_prevue))
      this.formations = data
      this.formations.forEach((item) => {
        item.color = item.color_formateur[0];
        item.class = item.statut_demande == 0 ? "close_inscription" : "open_inscription"
        let date_format = DateTime.fromJSDate(item.date_prevue).toFormat("yyyy-MM-dd")
        if (date_format != item.date_prevue_text) {
          item.date_prevue = DateTime.fromJSDate(item.date_prevue).plus({ days: 1 }).toJSDate()
        }
        item.initial_formateur = this.obtenirInitiales(item.formateurs[0])
        item.heure_debut = DateTime.fromFormat(item.heure_debut, "HH:mm:ss").toFormat("HH:mm")
        item.heure_fin = DateTime.fromFormat(item.heure_fin, "HH:mm:ss").toFormat("HH:mm")
        item.passe_date = this.isDate1GreaterThanDate2(DateTime.now().setZone('UTC').toFormat("yyyy-MM-dd"), item.date_prevue_text)
      });
      this.formations.sort((a, b) => {
        const heureDebutA = DateTime.fromFormat(a.heure_debut, "HH:mm");
        const heureDebutB = DateTime.fromFormat(b.heure_debut, "HH:mm");
        return heureDebutA.ts - heureDebutB.ts;
      });
      this.formations_dynamique = [...this.formations]
      // this.liste_formateur = [...new Set(this.formations.flatMap(f => f.formateurs))].map(item => {return {nom:item, initial: this.obtenirInitiales(item)}}).filter(f => f.nom != "")
      this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month && DateTime.fromISO(f.date_prevue_text).setZone('UTC').year == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').year).length
      // console.log(this.formations_dynamique)
      this.getFormateur()
      this.getTravailleur()
    })
  }

  isDate1GreaterThanDate2(date1: string | Date, date2: string | Date): boolean {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
      throw new Error('Les dates fournies ne sont pas valides');
    }

    return parsedDate1 > parsedDate2;
  }

  obtenirInitiales(nomComplet) {
    const mots = nomComplet.split(' ');
    const initiales = mots.map(mot => mot.charAt(0).toUpperCase()).join('.');
    return initiales;
  }

  filteredByFormateur(formateur: any) {
    this.is_filtering = formateur != ""
    this.formations_dynamique = formateur == "" ? [...this.formations] : [...this.formations].filter(f => f.initial_formateur == formateur)
    this.nombre_formation_mois = this.formations_dynamique.filter(f => DateTime.fromISO(f.date_prevue_text).setZone('UTC').month == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').month && DateTime.fromISO(f.date_prevue_text).setZone('UTC').year == DateTime.fromJSDate(new Date(this.viewDate)).setZone('UTC').year).length
    var data_formateur = this.formateurs.find(f => f.initial == formateur)
    this.filtre_calendrier_formateur = formateur != '' ? this.calendrier_formateur.filter(cf => cf.id_formateur == data_formateur.id_formateur) : []
  }

  generatePDF(): void {
    if (this.selected_index_week_to_print != "") {
      this.is_printing = true;
      const data = document.getElementById('screenCalendar');
      
      if (data) {
        html2canvas(data, { scale: 3 }).then(canvas => {
          const imgWidth = 297;
          const pageHeight = 210;
          const imgHeight = canvas.height * imgWidth / canvas.width;
          const heightLeft = imgHeight;
          
          const contentDataURL = canvas.toDataURL('image/png');
          const pdf = new jspdf('l', 'mm', 'a4');
          let position = 0;
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          if (heightLeft > pageHeight) {
            let remainingHeight = heightLeft - pageHeight;
            let currentPosition = -remainingHeight;
            while (remainingHeight > 0) {
              pdf.addPage();
              pdf.addImage(contentDataURL, 'PNG', 0, currentPosition, imgWidth, imgHeight);
              remainingHeight -= pageHeight;
              currentPosition = -remainingHeight;
            }
          }

          var formateur = this.formateur_filtered == '' ? "" : "("+this.formateur_filtered+")"
          var titrePdf = ""
          if(this.selected_index_week_to_print < 6){
            titrePdf = DateTime.fromJSDate(this.week_to_print[0][0].date).toFormat("yyyy-MM-dd") + " au " + DateTime.fromJSDate(this.week_to_print[0][4].date).toFormat("yyyy-MM-dd") + " " + formateur + " Calendrier imprimé.pdf"
          } else {
            var abrev = this.selected_index_week_to_print == 6 ? "3PS" : this.selected_index_week_to_print == 7 ? "3DS" : "TSM"
            titrePdf = DateTime.now().toFormat("yyyy-MM-dd") + " " + formateur + " Calendrier imprimé " + abrev + ".pdf"
          }

          pdf.save(titrePdf);
          this.is_printing = false;
        }).catch(error => {
          console.error('Erreur lors de la génération du PDF:', error);
        });
      } else {
        console.error('L\'élément avec l\'ID "screenCalendar" n\'a pas été trouvé.');
      }
    }
  }


  getFormateurName(initial:any){
    return initial == "" ? "Tous" : this.formateurs.find(f => f.initial == initial).nom_formateur
  }

}

