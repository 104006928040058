import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import {DateTime} from 'luxon';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {

  @Input() rowData: any
  @Input() formations: any
  @Input() is_admin: any
  @Input() user_log: any
  @Input() travailleurs: any
  @Input() filteredTravailleurs: any
  constructor(
    public activeModal: NgbActiveModal,
    private calendrierService: CalendrierService,
    private toast: ToastService
  ) { }

  inscription: any = {
    matricule: "",
    nom_travailleur: "",
    prenom_travailleur: ""
  }
  is_verifing: any = false
  heure_error:any = false
  is_saving:any = false
  travailleur_search:any = ""

  filterWorkers() {
    const searchTerm = this.travailleur_search.toLowerCase();
    if (searchTerm) {
      this.filteredTravailleurs = this.travailleurs.filter(worker =>
        worker.unique_search.toLowerCase().startsWith(searchTerm)
      );
    } else {
      this.filteredTravailleurs = this.travailleurs;
    }
  }

  selectingWorker(){
    if(this.travailleur_search != ""){
      var result = this.travailleurs.find(t => t.unique_search == this.travailleur_search)      
      this.inscription.matricule = result.matricule
      this.inscription.nom_travailleur = result.nom_travailleur_cap
      this.inscription.prenom_travailleur = result.prenom_travailleur_cap
    } else {
      this.resetForm()
    }
  }

  openCloseFormation(data: any, statut: any) {
    var dataObj = {
      num_demande: data.num_demande,
      id_formation: data.id_formation,
      statut: statut
    }
    Swal.fire({
      title: statut == 1 ? "Ouverture" : "Fermeture",
      text: statut == 1 ? "Voulez-vous vraiment ouvrir l'inscription à cette formation ?" : "Voulez-vous vraiment fermer l'inscription à cette formation ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: statut == 1 ? "Oui, ouvrir" : "Oui, fermer",
      cancelButtonText: "Non, annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.openInscriptionService(dataObj).subscribe((data: any) => {
          var message = statut == 1 ? "Inscription ouverte" : "Inscription fermée"
          this.toast.Success(message)
        })
        data.class = statut == 1 ? "open_inscription" : "close_inscription"
        data.color = statut == 1 ? "" : this.getRandomColor()
        data.statut_demande = statut
      }
    });
  }

  supprimerTravailleurFormation(data: any, index: any) {
    var dataObj = { id_travailleur_formation: data, type: 1 }
    Swal.fire({
      title: "Désinscription",
      text: "Voulez-vous vraiment désinscrire ce travailleur de cette formation ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, désinscrire",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.deleteTravailleurFormationService(dataObj).subscribe((data: any) => {
          this.toast.Success("Travailleur désinscrit du formation")
          this.rowData.travailleurs.splice(index, 1)
        })
      }
    });
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }

  resetForm() {
    this.is_verifing = false
    this.inscription = {
      matricule: "",
      nom_travailleur: "",
      prenom_travailleur: ""
    }
    this.travailleur_search = ""
    this.filteredTravailleurs = this.travailleurs;
  }

  saveTravailleur() {
    this.is_verifing = true
    if (this.inscription.matricule == "" || this.inscription.nom_travailleur == "" || this.inscription.prenom_travailleur == "") return
    this.is_saving = true
    var dataObj = {
      id_demande: this.rowData.id_demande,
      matricule_travailleur: this.inscription.matricule,
      nom_travailleur: this.inscription.nom_travailleur,
      prenom_travailleur: this.inscription.prenom_travailleur,
      date_prevue: this.rowData.date_prevue_text,
      formateur: this.rowData.formateurs[0],
      statut_formation: this.rowData.statut_formation,
      heure_debut: this.rowData.heure_debut,
      heure_fin:this.rowData.heure_fin,
      statut_inscription: this.rowData.statut_inscription
    }
    // return
    this.calendrierService.saveTravailleurFormationService(dataObj).subscribe((data: any) => {
      this.rowData.travailleurs.push({ id_travailleur_formation: data[0].id_travailleur_formation, statut_inscription: "autorisée", nom_complet: this.inscription.prenom_travailleur + " " + this.inscription.nom_travailleur })
      this.toast.Success("Inscription effectuée")
      this.resetForm()
      this.is_saving = false
    })
  }

  deleteFormation(data: any) {
    var dataObj = {
      id_demande: data.id_demande,
      date_prevue: data.date_prevue_text,
      type: 0
    }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette formation?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        const index_formation = this.formations.findIndex(f => f.id_demande == data.id_demande && f.num_demande == data.num_demande)
        this.calendrierService.deleteTravailleurFormationService(dataObj).subscribe((data: any) => {
          this.toast.Success("Formation supprimée")
          this.activeModal.dismiss()
          this.formations.splice(index_formation, 1)
        })
      }
    });
  }

  changeDateHeure(){
    const heure_debut = DateTime.fromFormat(this.rowData.heure_debut, "HH:mm")
    const heure_fin = DateTime.fromFormat(this.rowData.heure_fin, "HH:mm")
    if(heure_debut.ts > heure_fin.ts) {
      this.heure_error = true
      return
    } 
    var dataObj = {
      date_prevue : DateTime.fromJSDate(new Date(this.rowData.date_prevue_text)).setZone('UTC').toFormat("yyyy-MM-dd"),
      heure_debut : DateTime.fromFormat(this.rowData.heure_debut, "HH:mm").toFormat("HH:mm:ss"),
      heure_fin : DateTime.fromFormat(this.rowData.heure_fin, "HH:mm").toFormat("HH:mm:ss"),
      ids : this.rowData.travailleurs.map(t => t.id_travailleur_formation).join(',')
    }
    this.calendrierService.updateDateFormationService(dataObj).subscribe(() => { 
      this.heure_error = false
      this.toast.Success("Donnée enregistrée !") 
    })  
  }

}
