import { Component, OnInit, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FileService } from 'src/app/services/file/file.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';

@Component({
  selector: 'app-lms-mf',
  templateUrl: './lms-mf.component.html',
  styleUrls: ['./lms-mf.component.css']
})
export class LmsMfComponent implements OnInit {

  constructor(
    private fileService: FileService,
    private travailleurService: TravailleurService,
    private toast: ToastService
  ) { 
    this.getDataDifference()
  }

  is_uploading_lms:any = false
  is_uploading_mf:any = false
  data_difference:any = []
  is_validating:any = false
  nombre_data_affiche:any = 0
  nombre_data_restante:any = 0
  nombre_data_valide:any = 0
  data_affiche:any = []
  is_loading:any = false

  onFilesDropped(files: FileList, is_lms: boolean = false) {
    const droppedFile = files[0]
    if (droppedFile) {
      var dataObj = { file: droppedFile }
      if(is_lms){
        this.is_uploading_lms = true
        this.fileService.importationDataLMS(dataObj).subscribe((data: any) => {
          this.toast.Success("Données importées")
          this.is_uploading_lms = false
          this.getDataDifference()
        })
      } 
      else {
        this.is_uploading_mf = true
        this.fileService.importationDataMF(dataObj).subscribe((data: any) => {
          this.toast.Success("Données importées")
          this.is_uploading_mf = false
          this.getDataDifference()
        })
      }
    }
  }

  ngOnInit(): void {
  }

  getDataDifference(){
    this.is_loading = true
    this.travailleurService.getDataDifferenceLMSvsMFService().subscribe((data:any) => {
      this.data_difference = data
      this.data_difference.forEach(item => {
        
        item.lms_nom_prenom = this.isNullOrEmpty(item.lms_nom_prenom.trim()) ? "--VIDE--" : item.lms_nom_prenom
        item.mf_nom_prenom = this.isNullOrEmpty(item.mf_nom_prenom.trim()) ? "--VIDE--" : item.mf_nom_prenom
        
        item.lms_matricule = this.isNullOrEmpty(item.lms_matricule) ? "--VIDE--" : item.lms_matricule
        item.mf_matricule = this.isNullOrEmpty(item.mf_matricule) ? "--VIDE--" : item.mf_matricule
        
        item.lms_id_personne = this.isNullOrEmpty(item.lms_id_personne) ? "--VIDE--" : item.lms_id_personne
        item.mf_id_personne = this.isNullOrEmpty(item.mf_id_personne) ? "--VIDE--" : item.mf_id_personne
        
        item.ld_sexe = this.isNullOrEmpty(item.ld_sexe) ? "--VIDE--" : item.ld_sexe
        item.mf_sexe = this.isNullOrEmpty(item.mf_sexe) ? "--VIDE--" : item.mf_sexe

        item.lms_date_naissance = this.isNullOrEmpty(item.lms_date_naissance) ? "--VIDE--" : item.lms_date_naissance
        item.mf_date_naissance = this.isNullOrEmpty(item.mf_date_naissance) ? "--VIDE--" : item.mf_date_naissance

        item.lms_statut = this.isNullOrEmpty(item.lms_statut) ? "--VIDE--" : item.lms_statut
        item.mf_statut = this.isNullOrEmpty(item.mf_statut) ? "--VIDE--" : item.mf_statut

        item.lms_departement = this.isNullOrEmpty(item.lms_departement) ? "--VIDE--" : item.lms_departement
        item.mf_departement = this.isNullOrEmpty(item.mf_departement) ? "--VIDE--" : item.mf_departement
 
        item.lms_employeur = this.isNullOrEmpty(item.lms_employeur) ? "--VIDE--" : item.lms_employeur
        item.mf_employeur = this.isNullOrEmpty(item.mf_employeur) ? "--VIDE--" : item.mf_employeur

        item.lms_date_debut = this.isNullOrEmpty(item.lms_date_debut) ? "--VIDE--" : item.lms_date_debut
        item.mf_date_debut = this.isNullOrEmpty(item.mf_date_debut) ? "--VIDE--" : item.mf_date_debut

        item.lms_date_fin = this.isNullOrEmpty(item.lms_date_fin) ? "--VIDE--" : item.lms_date_fin
        item.mf_date_fin = this.isNullOrEmpty(item.mf_date_fin) ? "--VIDE--" : item.mf_date_fin

        item.ld_nom_utilisateur = this.isNullOrEmpty(item.ld_nom_utilisateur) ? "--VIDE--" : item.ld_nom_utilisateur
        item.mf_lms_username = this.isNullOrEmpty(item.mf_lms_username) ? "--VIDE--" : item.mf_lms_username

        item.ld_mail_pro = this.isNullOrEmpty(item.ld_mail_pro) ? "--VIDE--" : item.ld_mail_pro
        item.mf_courriel_pro = this.isNullOrEmpty(item.mf_courriel_pro) ? "--VIDE--" : item.mf_courriel_pro

        item.ld_mail_perso = this.isNullOrEmpty(item.ld_mail_perso) ? "--VIDE--" : item.ld_mail_perso
        item.mf_courriel_perso = this.isNullOrEmpty(item.mf_courriel_perso) ? "--VIDE--" : item.mf_courriel_perso
      
      });
      this.nombre_data_restante = this.data_difference.filter(d => d.lms_checked == 0 && d.mf_checked == 0).length
      this.nombre_data_valide = this.data_difference.filter(d => d.lms_checked == 1 && d.mf_checked == 1).length
      this.data_affiche = this.data_difference.filter(d => d.lms_checked == 0 && d.mf_checked == 0).slice(0, 10)
      this.nombre_data_affiche = this.data_affiche.length
      this.is_loading = false
    })
  }

  isNullOrEmpty(value){
    return value == "" || value == null || value == undefined
  }

  validationData(id_lms, id_mf, index){
    this.is_validating = true
    var dataObj = {id_lms: id_lms, id_mf: id_mf}
    this.travailleurService.validationDifferenceService(dataObj).subscribe(() => {
      this.data_difference.find(d => d.id_lms_data == id_lms && d.id_mf_data == id_mf).lms_checked = 1
      this.data_difference.find(d => d.id_lms_data == id_lms && d.id_mf_data == id_mf).mf_checked = 1
      this.nombre_data_restante = this.data_difference.filter(d => d.lms_checked == 0 && d.mf_checked == 0).length
      this.nombre_data_valide = this.data_difference.filter(d => d.lms_checked == 1 && d.mf_checked == 1).length
      this.data_affiche.splice(index, 1)
      this.nombre_data_affiche = this.data_affiche.length
      this.toast.Success("Donnée validée")
      this.is_validating = false
    })
  }

  refreshTable(){
    if(this.nombre_data_affiche < 10){
      this.is_loading = true
      this.data_affiche = this.data_difference.filter(d => d.lms_checked == 0 && d.mf_checked == 0).slice(0, 10)
      this.nombre_data_affiche = this.data_affiche.length
      this.is_loading = false
    }
  }

}
