<div class="fixed-top d-flex flex-column justify-content-between h-100"
    [ngClass]="{'menu':menuResize, 'menu2':!menuResize}">
    <div>
        <div id="donnee_admin" class="w-100" style="margin-top: 18px; cursor: pointer;" routerLink="/" (click)="changeTitle('', '')">
            <span class="h4 fw-lighter">
                <a id="text1">{{menuResize ? titleApplication.nameMenuMax :
                    titleApplication.nameMenuMin}}</a>
                <a id="text2">{{titleApplication.nameMenuMin}}</a>
            </span>
        </div>
        <div style="margin-top: 18px; overflow:auto; max-height: 91vh;">
            <div *ngFor="let itemMenu of liste_menu; let i = index">
                <button *ngIf="itemMenu.route_menu != ''" class="w-100" [ngClass]="{'btn_menu':menuResize, 'btn_menu2':!menuResize}" routerLink="/{{itemMenu.route_menu}}" (click)="changeTitle(itemMenu.route_menu, '')" routerLinkActive="is-active">
                    <div>
                        <i class="{{itemMenu.icon_menu}}" [ngClass]="{'icon_menu':menuResize, 'icon_menu2':!menuResize}"></i>
                        <span [ngClass]="{'text_menu':menuResize, 'text_menu2':!menuResize}">{{itemMenu.nom_menu}}</span>
                    </div>
                </button>
                <button *ngIf="itemMenu.route_menu == ''" class="btn w-100 mt-1" [ngClass]="{'btn_menu':menuResize, 'btn_menu2':!menuResize}" (click)="changeTitle(itemMenu.route_menu, '')">
                    <div>
                        <span [ngClass]="{'text_menu_mere':menuResize, 'text_menu2':!menuResize}">{{itemMenu.nom_menu}}</span>
                    </div>
                </button>
                <div class="collapse show rounded mb-2" [attr.id]="'collapseSM'+(i+1)">
                    <div *ngFor="let itemSM of itemMenu.sous_menu"
                        [ngClass]="{'divSM':menuResize, 'divSM2':!menuResize}">
                        <button class="btn btn-sm w-100" *ngIf="itemSM.nom_sous_menu == 'Importation'" [ngClass]="{'btn_menu':menuResize, 'btn_menu2':!menuResize}" (click)="openModal()">
                            <div>
                                <i class="{{itemSM.icon_sous_menu}}" [ngClass]="{'icon_menu':menuResize, 'icon_menu2':!menuResize}"></i>
                                <span [ngClass]="{'text_menu':menuResize, 'text_menu2':!menuResize}">{{itemSM.nom_sous_menu}}</span>
                            </div>
                        </button>
                        <button class="btn btn-sm w-100" *ngIf="itemSM.nom_sous_menu != 'Importation'" [ngClass]="{'btn_menu':menuResize, 'btn_menu2':!menuResize}" routerLink="/{{itemSM.route_sous_menu}}" routerLinkActive="is-active_SM" (click)="changeTitle(itemMenu.route_menu, itemSM.route_sous_menu)">
                            <div>
                                <i class="{{itemSM.icon_sous_menu}}" [ngClass]="{'icon_menu_calendar':menuResize && (itemSM.route_sous_menu == 'calendrier-formation' || itemSM.route_sous_menu == 'tableau-display'), 'icon_menu':menuResize && (itemSM.route_sous_menu != 'calendrier-formation' || itemSM.route_sous_menu != 'tableau-display')}"></i>
                                <span [ngClass]="{'text_menu_calendar':menuResize && (itemSM.route_sous_menu == 'calendrier-formation' || itemSM.route_sous_menu == 'tableau-display'), 'text_menu':menuResize && (itemSM.route_sous_menu != 'calendrier-formation' || itemSM.route_sous_menu != 'tableau-display')}">{{itemSM.nom_sous_menu}}</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="fixed-top" [ngClass]="{'topbar':menuResize, 'topbar2':!menuResize}">
    <div class="rounded d-flex flex-row justify-content-between align-items-center" id="hidden">
        <div style="color: rgb(75, 75, 75);">
            <i class="bi bi-list me-3"></i>
            <!-- <i class="bi bi-list me-3" (click)="changerMenuSize()"></i> -->
            <span style="font-size: 14px;">{{titleComponent == '' ? 'Formation Management':titleComponent}}</span>
        </div>
        <div style="color: rgb(75, 75, 75);">
            <span> <i class="bi bi-dot me-2 text-success"></i> </span>
            <span style="font-size: 14px;" class="me-3">{{username}}</span>
            <button class="me-2" id="icon-topBar" *ngIf="role == 99 || role == 10" routerLink="/users" (click)="changeTitle('users', '')"> <i class="bi bi-people-fill" routerLinkActive="is-active-topbar"></i> </button>
            <button class="me-2" id="icon-topBar" *ngIf="role == 99" routerLink="/gestion-menu" (click)="changeTitle('gestion-menu', '')"> <i class="bi bi-list-ul" routerLinkActive="is-active-topbar"></i> </button>
            <button class="btn btn-sm btn-outline-danger border-0 px-1" style="padding: 0; margin-top: -3px;" (click)="logout()" ngbTooltip="Déconnexion" placement="bottom"> <i class="bi bi-door-open" style="font-size: 15px;"></i> </button>
            <img src="assets/images/icons8-dashboard-100 (1).png" routerLink="/" (click)="changeTitle('', '')"
                class="ms-1" style="margin-bottom: 4px;" alt="LOGO" height="20" width="20">
        </div>
    </div>
</div>

<div [ngClass]="{'content':menuResize, 'content2':!menuResize}">
    <router-outlet></router-outlet>
</div>