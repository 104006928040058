<div class="d-flex flex-row align-items-center">
    <div class="p-2 rounded shadow-sm text-center" appDragdrop style="background-color: white; width: 15%;" (dropped)="onFilesDropped($event)" *ngIf="!is_uploading">
        <span class="text-secondary text-center" style="font-size: 12px;"> <i class="bi bi-download me-1"></i> Déposer ici le fichier à importer</span>
    </div>
    <div class="text-center text-secondary p-2 rounded shadow-sm" style="background-color: white; width: 15%;" *ngIf="is_uploading">
        <div class="spinner-grow spinner-grow-sm text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <button class="btn-special me-2 ms-2" (click)="resetFilter()">Effacer les filtres</button>
    <button class="btn-special me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Colonnes</button>
    <div class="spinner-border me-3" style="color: #707fdd;" role="status" *ngIf="is_loading">
        <span class="visually-hidden">Loading...</span>
    </div>
    <button class="btn btn-sm btn-outline-success me-2" (click)="exporterTableau()">
        <span class="spinner-border spinner-border-sm me-1" aria-hidden="true" *ngIf="is_downloading"></span>
        <span *ngIf="!is_downloading"> <i class="bi bi-file-earmark-excel me-1" style="font-size: 12px;"></i> </span> Exporter</button>
</div>
<div style="height: 87vh; overflow: auto;" class="mt-2">
    <div #tableContainer></div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des colonnes</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  *ngIf="!is_loading"></button>
                    <div class="spinner-border spinner-border-sm me-3" style="color: #707fdd;" role="status" *ngIf="is_loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="mt-2">
                    <table class="table table-sm table-bordered table-hover" style="vertical-align: middle;">
                        <thead>
                            <tr>
                                <th style="width: 8%;">N°</th>
                                <th>Nom colonne</th>
                                <th style="width: 12%;">Afficher</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let colonne of headers; let i = index">
                                <ng-container *ngIf="i != 0">
                                    <td>{{i}}</td>
                                    <td>
                                        <input type="text" class="form-control form-control-sm text-center border-0" [(ngModel)]="colonne.libelle_colonne" (change)="changeColonneName(colonne)">
                                    </td>
                                    <td style="text-align: center;">
                                        <div class="form-check" style="margin: auto;">
                                            <input class="form-check-input" type="checkbox" value=""
                                                (change)="hideShowColumn(i)" [(ngModel)]="colonne.afficher"
                                                id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault"></label>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>