import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';

@Component({
  selector: 'app-calendrier-travailleur',
  templateUrl: './calendrier-travailleur.component.html',
  styleUrls: ['./calendrier-travailleur.component.css']
})
export class CalendrierTravailleurComponent {

  current_year: any = DateTime.now().year
  annee_liste: any = [{ annee: 0 }];
  is_loading: any = false
  liste_departement: any
  departement: any = "20"
  equipe: any
  all_travailleurs:any
  travailleurs: any
  travailleurs_dynamique: any
  travailleurs_temp: any
  travailleurs_statique: any
  liste_equipe: any
  total_nb: any = 0
  actuel_nb: any = 0
  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    nom_travailleur: false,
    horaire: false,
    nom_equipe: false
  }
  matricule_search: any = ""
  nom_prenom_search: any = ""
  is_filter: any = false

  constructor(
    private travailleurService: TravailleurService
  ) {
    this.getDepartement()
    this.getData(this.departement)
  }

  makeData() {
    this.annee_liste[0].annee = this.current_year
    this.annee_liste.forEach(y => {
      y.days = this.getDaysInYear(y.annee);
      y.months = this.construireMois(y.annee);
    });
  }

  getDepartement() {
    this.travailleurService.getDataDropDownService().subscribe((data: any) => {
      this.liste_departement = data[2]
    })
  }

  getData(departement: any) {
    this.is_loading = true
    this.makeData();
    const date01Fev = DateTime.fromObject({ year: this.current_year + 1, month: 2, day: 1 });
    var dataObj = { filtre: { fin: date01Fev.setZone('UTC').toFormat("dd/MM/yyyy") }, code: departement }
    this.travailleurService.getCalendrierTravailleurService(dataObj).subscribe((data: any) => {
      this.all_travailleurs = data
      this.total_nb = data.length
      var jours_to_affcher = this.annee_liste.flatMap(a => a.months.flatMap(m => m.jours))
      this.all_travailleurs.forEach((worker, index) => {
        for (let index_day = worker.days.length - 1; index_day >= 0; index_day--) {
          var day = worker.days[index_day];
          var day_year = parseInt(day.date.split('-')[0]);
          if (day_year < this.current_year) {
            worker.days.splice(index_day, 1);
          }
        }
        jours_to_affcher.forEach(j => {
          const exists = worker.days.some(day => day.date == j.date)
          if (!exists) {
            worker.days.push({ date: j.date, valeur: '' })
          }
        })
        worker.days = worker.days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      })
      this.travailleurs = this.all_travailleurs.filter(d => d.code_organisation == departement)
      this.travailleurs_dynamique = [...this.travailleurs]
      this.liste_equipe = [...new Set(this.travailleurs.map(t => t.nom_equipe))].sort((a, b) => a.toString().localeCompare(b.toString()))
      this.equipe = this.liste_equipe[0]
      this.changeEquipe(this.equipe)
      this.is_loading = false
    })
  }


  getDaysInYear(year: number): number {
    const startOfYear = DateTime.fromObject({ year: year, month: 1, day: 1 });
    const endOfYear = startOfYear.endOf('year');
    const daysInYear = endOfYear.diff(startOfYear, 'days').days;
    return Math.round(daysInYear);
  }

  getDaysInMonth(year: number, month: number): number {
    const startOfMonth = DateTime.fromObject({ year: year, month: month, day: 1 });
    const endOfMonth = startOfMonth.endOf('month');
    const daysInMonth = endOfMonth.day;
    return daysInMonth;
  }

  construireMois(year: any) {
    let mois_liste = [
      { annee: year, mois: "Janvier", nombre_jour: this.getDaysInMonth(year, 1), jours: this.getJoursMois(year, 1), color: this.getRandomColor() },
      { annee: year, mois: "Février", nombre_jour: this.getDaysInMonth(year, 2), jours: this.getJoursMois(year, 2), color: this.getRandomColor() },
      { annee: year, mois: "Mars", nombre_jour: this.getDaysInMonth(year, 3), jours: this.getJoursMois(year, 3), color: this.getRandomColor() },
      { annee: year, mois: "Avril", nombre_jour: this.getDaysInMonth(year, 4), jours: this.getJoursMois(year, 4), color: this.getRandomColor() },
      { annee: year, mois: "Mai", nombre_jour: this.getDaysInMonth(year, 5), jours: this.getJoursMois(year, 5), color: this.getRandomColor() },
      { annee: year, mois: "Juin", nombre_jour: this.getDaysInMonth(year, 6), jours: this.getJoursMois(year, 6), color: this.getRandomColor() },
      { annee: year, mois: "Juillet", nombre_jour: this.getDaysInMonth(year, 7), jours: this.getJoursMois(year, 7), color: this.getRandomColor() },
      { annee: year, mois: "Aôut", nombre_jour: this.getDaysInMonth(year, 8), jours: this.getJoursMois(year, 8), color: this.getRandomColor() },
      { annee: year, mois: "Septembre", nombre_jour: this.getDaysInMonth(year, 9), jours: this.getJoursMois(year, 9), color: this.getRandomColor() },
      { annee: year, mois: "Octobre", nombre_jour: this.getDaysInMonth(year, 10), jours: this.getJoursMois(year, 10), color: this.getRandomColor() },
      { annee: year, mois: "Novembre", nombre_jour: this.getDaysInMonth(year, 11), jours: this.getJoursMois(year, 11), color: this.getRandomColor() },
      { annee: year, mois: "Décembre", nombre_jour: this.getDaysInMonth(year, 12), jours: this.getJoursMois(year, 12), color: this.getRandomColor() },
      { annee: year + 1, mois: "Janvier", nombre_jour: this.getDaysInMonth(year + 1, 1), jours: this.getJoursMois(year + 1, 1), color: this.getRandomColor() },
    ];
    return mois_liste;
  }

  getJoursMois(year: number, month: number) {
    const joursSemaine = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    let jours = [];
    const numJours = this.getDaysInMonth(year, month);
    for (let i = 1; i <= numJours; i++) {
      const jour = DateTime.fromObject({ year: year, month: month, day: i });
      const libelle = joursSemaine[jour.weekday - 1];
      const dateIso = jour.toISODate();
      jours.push({ num: i, libelle: libelle, date: dateIso });
    }
    return jours;
  }

  getDataFromTravailleur(date: any, index: any) {
    var result = this.travailleurs[index].days.filter(i => i.date == date)
    var valeur = ""
    if (result.length != 0) {
      valeur = result[0].valeur
    }
    return valeur
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }


  changeEquipe(equipe: any) {
    this.travailleurs_dynamique = this.travailleurs.filter(t => t.nom_equipe == equipe)
    this.travailleurs_temp = [...this.travailleurs_dynamique]
    this.travailleurs_statique = [...this.travailleurs_dynamique]
    this.actuel_nb = this.travailleurs_dynamique.length
    this.matricule_search = ""
    this.nom_prenom_search = ""
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.travailleurs_dynamique = this.travailleurs_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.travailleurs_dynamique = this.travailleurs_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.travailleurs_dynamique = [...this.travailleurs_temp]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }


  prev_year() {
    this.current_year -= 1
    this.getData(this.departement)
  }

  next_year() {
    this.current_year += 1
    this.getData(this.departement)
  }

  searchPersonne() {
    var champ = "nom_prenom"
    var text = this.nom_prenom_search
    this.travailleurs_dynamique = this.all_travailleurs.filter(t => t[champ].toLowerCase().includes(text.toLowerCase()))
    this.travailleurs_temp = [...this.travailleurs_dynamique]
    this.actuel_nb = this.travailleurs_dynamique.length
    this.is_filter = true
    // console.log(this.travailleurs_dynamique)
  }

  reset_recherche() {
    this.travailleurs_dynamique = [...this.travailleurs_statique]
    this.travailleurs_temp = [...this.travailleurs_statique]
    this.matricule_search = ""
    this.nom_prenom_search = ""
    this.is_filter = false
    this.actuel_nb = this.travailleurs_dynamique.length
  }
}
