import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sauveteur',
  templateUrl: './sauveteur.component.html',
  styleUrls: ['./sauveteur.component.css']
})
export class SauveteurComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
