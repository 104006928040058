import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { FormationService } from 'src/app/services/formation/formation.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon'
import { saveAs } from 'file-saver';
import Handsontable from "handsontable";
import 'handsontable/languages/fr-FR';
import { CookieService } from 'ngx-cookie-service';
import { CryptageService } from 'src/app/services/cryptage/cryptage.service';

@Component({
  selector: 'app-liste',
  templateUrl: './liste.component.html',
  styleUrls: ['./liste.component.css']
})
export class ListeComponent {

  liste_formation: any = []
  liste_formation_temp: any = []
  liste_formation_dynamique: any = []
  is_loading: any = false

  constructor(
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private fonction: FormationService,
    private cryptage: CryptageService
  ) {
    this.getDataSelect()
    this.getListeFormateur()
  }

  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    num_formation: false,
    nom_formation: false,
    categorie: false,
    interne: false,
    resultat: false,
    nb_heure_theorie: false,
    nb_heure_pratique: false,
    examen: false,
    annee_refresh: false,
    inclus_matrice: false,
    suivi_requisition: false,
    accessible_demande_superviseur: false
  }
  is_editing: any = false
  is_verifing: any = false

  formFormation: any = {
    nom_formation: "",
    num_formation: "",
    categorie: "",
    interne_externe: "",
    theorie: 0,
    pratique: 0,
    refresh: 0,
    examen: "",
    resultat: "",
    matrice: "",
    suivi: "",
    superviseur: "",
    formateurs: []
  }

  liste_resultat: any = []
  liste_categorie: any = []
  liste_formateur: any = []
  liste_formateur_dynamique: any = []
  formateur_added: any = ""

  is_downloading: any = false

  @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;
  hot: any
  headers: any = [
    { nom_colonne: "", afficher: false },
    { nom_colonne: "N° Formation", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>N° Formation</label>` },
    { nom_colonne: "Nom formation", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Nom formation</label>` },
    { nom_colonne: "Catégorie", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Catégorie</label>` },
    { nom_colonne: "Interne / Externe", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Int. / Ext.</label>` },
    { nom_colonne: "Résultat", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Résultat</label>` },
    { nom_colonne: "Heure Théorie", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>H. Théorie</label>` },
    { nom_colonne: "Heure Pratique", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>H. Pratique</label>` },
    { nom_colonne: "Examen", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Examen</label>` },
    { nom_colonne: "Refresh", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Refresh</label>` },
    { nom_colonne: "Inclus Matrice", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Inclus Matrice</label>` },
    { nom_colonne: "Suivi réquisition", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Suivi réquisition</label>` },
    { nom_colonne: "Accès demande superviseur", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Accès demande superviseur</label>` },
    { nom_colonne: "Formateurs", afficher: true, to_ui: `<label style='font-size:13px; font-weight:bold;'>Formateurs</label>` },
    { nom_colonne: "", afficher: true, to_ui:"" },
    { nom_colonne: "", afficher: true, to_ui:"" },
  ]
  is_updating: any = false

  exporterTableau() {
    this.is_downloading = true
    const current_date = DateTime.fromJSDate(new Date()).setZone('UTC').toFormat("yyyy-MM-dd")
    this.calendrierService.exportListeFormationService().subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${current_date} Liste des formations`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }

  getListeFormateur() {
    this.is_loading = true
    this.calendrierService.getFormateurService().subscribe((data: any) => {
      this.liste_formateur = data
      this.liste_formateur_dynamique = [...this.liste_formateur]
      this.getListeFormation()
    }, err => {}, () => {
      var data = sessionStorage.getItem('data_column_formation')
      if (data == null || data == '' || data == undefined) sessionStorage.setItem('data_column_formation', this.cryptage.encryptEncode(JSON.stringify(this.headers)))
    })
  }

  getListeFormation() {
    this.is_loading = true
    this.calendrierService.getListeFormationService().subscribe((data: any) => {
      this.liste_formation = data
      this.liste_formation.forEach(element => {
        element.examen = element.examen ? "Oui" : "Non"
        element.resultat = this.initCap(element.resultat),
          element.inclus_matrice = element.inclus_matrice ? "Oui" : "Non"
        element.suivi_requisition = element.suivi_requisition ? "Oui" : "Non",
          element.accessible_demande_superviseur = element.accessible_demande_superviseur ? "Oui" : "Non",
          element.interne = element.interne ? "Interne" : "Externe",
          element.nom_formation = this.initCap(element.nom_formation)
        element.libelle_formateurs = ""
        if (element.formateurs.length > 0 && element.formateurs[0] != null) {
          element.libelle_formateurs = "<ul>"
          element.formateurs.forEach((f, i) => {
            element.libelle_formateurs += `<li style="font-size:13px;">${this.liste_formateur.find(lf => lf.id_formateur == f).nom_formateur}</li>`
          });
          element.libelle_formateurs += "<ul>"
        }
      });
      this.liste_formation_dynamique = [...this.liste_formation]
      this.liste_formation_temp = [...this.liste_formation]
      const savedFilters = sessionStorage.getItem('handsontable_filters_formation');
      const savedSortConfig = sessionStorage.getItem('handsontable_sortingColumnConfig_formation')
      const savedHiddenColumn = sessionStorage.getItem('data_column_formation')
      var condition = []
      var sortConfig = []
      var columnsHidden = []
      if (savedFilters) condition = JSON.parse(this.cryptage.decryptDecode(savedFilters));
      if (savedSortConfig) sortConfig = JSON.parse(this.cryptage.decryptDecode(savedSortConfig))
      if (savedHiddenColumn) {
        var columns = JSON.parse(this.cryptage.decryptDecode(savedHiddenColumn))
        this.headers = [...columns]
        columns.forEach((c, i) => {
          if (c.afficher == false) columnsHidden.push(i)
        })
      }
      if (this.hot) {
        this.hot.loadData(data);
        this.hot.render();
        this.applyFilter(condition)
        this.is_loading = false
        return;
      }
      this.Maketable(this.liste_formation_dynamique, this.headers.map(h => h.to_ui), condition, sortConfig, columnsHidden)
    })
  }

  initCap(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  Maketable(data: any, headers: any = null, condition: any, sortConfig: any, columnsHidden: any) {

    let dropDown_categorie = [];
    this.liste_categorie.forEach(element => { dropDown_categorie.push(this.initCap(element.libelle)) });

    let dropDown_resultat = [];
    this.liste_resultat.forEach(element => { dropDown_resultat.push(this.initCap(element.libelle_type)) });

    const btnEdit = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.dom.empty(td);

      const icon = document.createElement('i');
      icon.className = 'bi bi-pen-fill';
      icon.style.fontSize = '12px';

      const button = document.createElement('button');
      button.className = 'btn btn-outline-warning btn-sm border-0';
      button.setAttribute('data-bs-toggle', 'modal');
      button.setAttribute('data-bs-target', '#formationModal');

      button.appendChild(icon);

      button.addEventListener('click', () => {
        const id_formation = this.hot.getDataAtCell(row, 0);
        var data = this.liste_formation.find(lf => lf.id_liste_formation === id_formation)
        this.toEditFormation(data)
      });

      td.appendChild(button);
      td.style.verticalAlign = 'middle'
      td.style.textAlign = 'center';
      td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';

      return td;
    };

    const btnSuppr = (instance, td, row, col, prop, value, cellProperties) => {
      Handsontable.dom.empty(td);

      const icon = document.createElement('i');
      icon.className = 'bi bi-trash';
      icon.style.fontSize = '12px';

      const button = document.createElement('button');
      button.className = 'btn btn-outline-danger btn-sm border-0';

      button.appendChild(icon);

      button.addEventListener('click', () => {
        const id_formation = this.hot.getDataAtCell(row, 0);
        const index_formation = this.liste_formation.findIndex(lf => lf.id_liste_formation === id_formation)
        this.deleteFormation(id_formation, index_formation, condition)
      });

      td.appendChild(button);
      td.style.verticalAlign = 'middle'
      td.style.textAlign = 'center';
      td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';

      return td;
    };

    this.hot = new Handsontable(this.tableContainer.nativeElement, {
      language: 'fr-FR',
      data: data,
      width: '100%',
      colHeaders: headers,      
      rowHeaders: true,
      cells: function (row, col, prop) {
        const cellProperties: any = {};
        cellProperties.renderer = (instance, td, row, col, prop, value) => {
          td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
          td.innerHTML = value;
          td.style.verticalAlign = 'middle'
          td.style.textAlign = 'center';
          td.style.fontSize = '13px';
        }
        switch (col) {
          case 2:
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value;
              td.style.verticalAlign = 'middle'
              td.style.textAlign = 'center';
              td.style.width = '400px'
              td.className = 'px-3 py-2'
              td.style.fontSize = '13px';
            }
            break
          case 3: // Catégorie
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_categorie;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_categorie.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
              td.style.verticalAlign = 'middle'
              td.className = 'px-3'
              td.style.fontSize = '13px';
            }
            break
          case 5: // Résultat
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_resultat;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_resultat.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
              td.style.verticalAlign = 'middle'
              td.className = 'px-3'
              td.style.fontSize = '13px';
            }
            break
          case 4: // Interne ou Externe
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Interne', 'Externe'];
            cellProperties.strict = true;
            break
          case 8: // Examen
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 10: // Inclus Matrice
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 11: // Suivi
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 12: // Accès demande superviseur
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 13:
            cellProperties.readOnly = true
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value;
              td.style.textAlign = 'start';
              td.className = 'pe-3 pt-3'
            }
            break
          case 14:
            cellProperties.renderer = btnEdit;
            cellProperties.readOnly = true
            break
          case 15:
            cellProperties.renderer = btnSuppr;
            cellProperties.readOnly = true
            break
        }
        return cellProperties;
      },
      columns: [
        { data: "id_liste_formation" },
        { data: "num_formation" },
        { data: "nom_formation" },
        { data: "categorie" },
        { data: "interne" },
        { data: "resultat" },
        { data: "nb_heure_theorie" },
        { data: "nb_heure_pratique" },
        { data: "examen" },
        { data: "annee_refresh" },
        { data: "inclus_matrice" },
        { data: "suivi_requisition" },
        { data: "accessible_demande_superviseur" },
        { data: "libelle_formateurs" },
        { data: "" },
        { data: "" },
      ],
      hiddenColumns: {
        columns: columnsHidden,
        indicators: true
      },
      contextMenu: false,
      filters: true,
      // dropdownMenu: true,
      dropdownMenu: ['filter_by_condition', '---------', 'filter_operators', 'filter_by_value', 'filter_action_bar'],
      manualRowMove: false,
      manualColumnMove: false,
      columnSorting: {
        initialConfig: sortConfig[0]
      },
      stretchH: 'all',
      licenseKey: 'non-commercial-and-evaluation',
      autoColumnSize: {
        useHeaders: false
      },
      // autoColumnSize: true,
      manualColumnResize: true,
      afterFilter: (conditions) => {
        var stringCondition = this.cryptage.encryptEncode(JSON.stringify(conditions))
        sessionStorage.setItem('handsontable_filters_formation', stringCondition)
      },
      afterColumnSort(currentSortConfig, destinationSortConfigs) {
        var stringSortConfig = this.cryptage.encryptEncode(JSON.stringify(destinationSortConfigs))
        sessionStorage.setItem('handsontable_sortingColumnConfig_formation', stringSortConfig)
      },
      afterChange: (changes, source) => {
        if (!changes || this.is_updating) return;
        let dataObj = { updates: [], ids: [] };
        let nombre_data = changes.length
        if (nombre_data > 1 || nombre_data == 1) {
          if (changes[0][1] == changes[nombre_data - 1][1]) {
            var row = changes[0][0]
            var col = changes[0][1]
            var oldValue = changes[0][2]
            var newValue = changes[0][3]

            this.is_updating = true
            switch (col) {

              case "examen":
              case "inclus_matrice":
              case "suivi_requisition":
              case "accessible_demande_superviseur":
                var value = newValue == "Oui" ? true : false
                dataObj.updates.push({ [col]: value })
                break

              case "interne":
                var value = newValue == "Interne" ? true : false
                dataObj.updates.push({ [col]: value })
                break

              case "categorie":
                var data = this.liste_categorie.filter(i => i.libelle.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_cat_formation
                dataObj.updates.push({ id_cat_formation: id })
                break

              case "resultat":
                var data = this.liste_resultat.filter(i => i.libelle_type.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_type_formation
                dataObj.updates.push({ id_type_formation: id })
                break

              default:
                dataObj.updates.push({ [col]: newValue })
                break
            }

            changes.forEach(change => {
              const updatedData = this.hot.getData()[change[0]];
              const id_travailleur = updatedData[0]
              dataObj.ids.push(id_travailleur)
            });

            this.is_updating = false
          }
        }
        this.calendrierService.updateListeFormationService(dataObj).subscribe((data: any) => { })
      }
    })

    this.applyFilter(condition)

    const thisHot: Handsontable = this.hot;
    Handsontable.hooks.add('modifyColWidth', function (width) {
      const x: any = thisHot.getPlugin('dropdownMenu').menu;
      if (this === x.hotMenu) {
        return 300;
      }
      return width;
    });

    this.is_loading = false
  }

  applyFilter(condition: any) {
    const filtersPlugin = this.hot.getPlugin('filters');
    condition.forEach((cond, index) => {
      var column = cond.column
      var operation = cond.operation
      var data_condition = cond.conditions
      var name = data_condition[0].name
      var args = data_condition[0].args
      filtersPlugin.addCondition(column, name, args, operation);
    })
    filtersPlugin.filter();
  }

  resetFilter() {
    const filtersPlugin = this.hot.getPlugin('filters');
    filtersPlugin.clearConditions()
    filtersPlugin.filter()
  }

  hideShowColumn(index: any) {
    const hiddenColumnsPlugin = this.hot.getPlugin('hiddenColumns')
    if (hiddenColumnsPlugin.isHidden(index)) {
      hiddenColumnsPlugin.showColumn(index)
    } else {
      hiddenColumnsPlugin.hideColumn(index)
    }
    sessionStorage.setItem('data_column_formation', this.cryptage.encryptEncode(JSON.stringify(this.headers)))
    this.hot.render()
  }

  getDataSelect() {
    this.calendrierService.getDataSelectFormationService().subscribe((data: any) => {
      this.liste_resultat = data[1]
      this.liste_categorie = data[0]
    })
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.liste_formation_dynamique = this.liste_formation_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.liste_formation_dynamique = this.liste_formation_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.liste_formation_dynamique = [...this.liste_formation]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  deleteFormation(id: any, index: any, condition:any) {
    var dataObj = { id_liste_formation: id, traitement: 0 }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette formation?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.traitementListeFormationService(dataObj).subscribe(() => {
          this.toast.Success("Formation supprimée")
          this.getListeFormation()
        })
      }
    });
  }

  resetFormFormation() {
    this.is_verifing = false
    this.is_editing = false
    this.formFormation = {
      nom_formation: "",
      num_formation: "",
      categorie: "",
      interne_externe: "",
      theorie: 0,
      pratique: 0,
      refresh: 0,
      examen: "",
      resultat: "",
      matrice: "",
      suivi: "",
      superviseur: "",
      formateurs: []
    }
  }

  saveFormation() {
    this.is_verifing = true
    if (this.formFormation.nom_formation == "" || this.formFormation.num_formation == "" || this.formFormation.categorie == "") return
    if (this.formFormation.interne_externe == "" || this.formFormation.theorie == null || this.formFormation.pratique == null) return
    if (this.formFormation.refresh == null || this.formFormation.examen == "" || this.formFormation.resultat == "") return
    if (this.formFormation.matrice == "" || this.formFormation.suivi == "" || this.formFormation.superviseur == "") return
    this.formFormation.traitement = this.is_editing ? 2 : 1
    this.calendrierService.traitementListeFormationService(this.formFormation).subscribe((data: any) => {
      if (!this.is_editing) {
        this.resetFormFormation()
        this.toast.Success("Formation ajoutée")
      } else {
        this.toast.Success("Formation modifiée")
        this.formFormation.save_formateurs = this.formFormation.formateurs
      }
      this.getListeFormation()
    })
  }

  toEditFormation(rows: any) {
    this.is_editing = true
    this.formFormation.nom_formation = this.fonction.mettrePremierMotEnInitCap(rows.nom_formation)
    this.formFormation.num_formation = rows.num_formation
    this.formFormation.categorie = rows.id_cat_formation
    this.formFormation.interne_externe = rows.interne ? "1" : "0"
    this.formFormation.theorie = parseInt(rows.nb_heure_theorie)
    this.formFormation.pratique = parseInt(rows.nb_heure_pratique)
    this.formFormation.refresh = parseInt(rows.annee_refresh)
    this.formFormation.examen = rows.examen ? "1" : "0"
    this.formFormation.resultat = rows.id_type_formation
    this.formFormation.matrice = rows.inclus_matrice ? "1" : "0"
    this.formFormation.suivi = rows.suivi_requisition ? "1" : "0"
    this.formFormation.superviseur = rows.accessible_demande_superviseur ? "1" : "0"
    this.formFormation.id_liste_formation = rows.id_liste_formation
    this.formFormation.save_formateurs = rows.formateurs
    this.formFormation.formateurs = [...this.formFormation.save_formateurs][0] == null ? [] : [...this.formFormation.save_formateurs]
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !this.formFormation.formateurs.some(f => f == l.id_formateur))]
  }

  formateurName(id: any) {
    if (id != null && id != undefined && id != "") {
      return this.liste_formateur.find(l => l.id_formateur == id).nom_formateur
    }
    return ""
  }

  addFormateurToFormation(id: any, row: any) {
    row.formateurs.push(id)
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !row.formateurs.some(f => f == l.id_formateur))]
    this.formateur_added = ""
  }

  dropFormateurFormation(row: any, index: any) {
    row.formateurs.splice(index, 1)
    this.liste_formateur_dynamique = [...this.liste_formateur.filter(l => !row.formateurs.some(f => f == l.id_formateur))]
  }
}
