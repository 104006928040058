import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Handsontable from "handsontable";
import 'handsontable/languages/fr-FR';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { DateTime } from 'luxon'
import { ToastService } from 'src/app/services/toast/toast.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { CookieService } from 'ngx-cookie-service';
import { CryptageService } from 'src/app/services/cryptage/cryptage.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-personnel',
  templateUrl: './personnel.component.html',
  styleUrls: ['./personnel.component.css']
})
export class PersonnelComponent {

  constructor(
    private travailleurService: TravailleurService,
    private toastService: ToastService,
    private cookies: CookieService,
    private cryptage: CryptageService,
    private userService: UsersService
  ) {
    this.getUser()
    var data = this.cookies.get('data_column')
    if (data == '') this.cookies.set('data_column', JSON.stringify(this.headers), 100)
    this.getDataDropDown()
  }

  is_loading: any = false
  sous_departement_liste: any
  statut_employe_liste: any
  departement_liste: any
  fonction_liste: any
  sexe_liste: any
  entrepreneur_liste: any
  type_emploi_liste: any
  cat_emploi_liste: any
  equipe_liste: any
  emploi_liste: any
  horaire_liste: any
  hot: any
  all_data_travailleur: any
  is_updating: any = false
  total_ligne: any = 0
  actif_ligne: any = 0
  inactif_ligne: any = 0
  current_ligne: any = 0
  is_downloading: any = false

  form_new_worker: any = {
    nom: "",
    prenom: "",
    sexe: "",
    date_naissance: "",
    statut: "",
    id_employe: "",
    id_personne: "",
    horaire: "",
    date_emploi: "",
    date_fin_emploi: "",
    type_emploi: "",
    categorie_emploi: "",
    nom_entrepreneur: "",
    equipe: "",
    departement: "",
    sous_departement: "",
    titre_poste: "",
    titre_emploi: "",
    sauveteur: "",
    pompier: "",
    realite: "",
    niveau_superieur: "",
    matricule_superieur: "",
    nom_superieur: "",
    prenom_superieur: "",
    id_sup_immediat: ""
  }
  is_verifing: any = false
  headers: any = [
    { nom_colonne: "", afficher: false },
    { nom_colonne: "Statut", afficher: true },
    { nom_colonne: "ID employé", afficher: true },
    { nom_colonne: "Sexe", afficher: true },
    { nom_colonne: "ID personne", afficher: true },
    { nom_colonne: "Nom & Prénom", afficher: true },
    { nom_colonne: "Date naissance", afficher: true },
    { nom_colonne: "Date d'emploi", afficher: true },
    { nom_colonne: "Date de fin d'emploi", afficher: true },
    { nom_colonne: "Type d'emploi", afficher: true },
    { nom_colonne: "Catégorie", afficher: true },
    { nom_colonne: "Nom entrepreneurs", afficher: true },
    { nom_colonne: "Horaire", afficher: true },
    { nom_colonne: "Equipe", afficher: true },
    { nom_colonne: "Code", afficher: true },
    { nom_colonne: "Département", afficher: true },
    { nom_colonne: "Sous département", afficher: true },
    { nom_colonne: "ID Poste", afficher: true },
    { nom_colonne: "Titre du poste", afficher: true },
    { nom_colonne: "ID Emploi", afficher: false },
    { nom_colonne: "Titre d'emploi", afficher: false },
    { nom_colonne: "Sauveteur", afficher: true },
    { nom_colonne: "Pompier", afficher: true },
    { nom_colonne: "Réalité", afficher: true },
    { nom_colonne: "Niveau supérieur", afficher: true },
    { nom_colonne: "Sup. immédiat", afficher: true },
    { nom_colonne: "Matricule Sup.Imm", afficher: true },
    { nom_colonne: "Superviseur", afficher: true },
    { nom_colonne: "Matricule Superviseur", afficher: true }
  ]
  user_log: any = []
  access: any = false
  role_access: any = [99, 10, 7]
  date_donnee: any = DateTime.now().setZone("UTC").toFormat("yyyy-MM-dd")
  current_date: any = DateTime.now().setZone("UTC").toFormat("yyyy-MM-dd")
  ancienne_data: any = false

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.user_log = data
      this.access = this.role_access.includes(this.user_log.role)
    })
  }

  resetForm() {
    this.is_verifing = false
    this.form_new_worker = {
      nom: "",
      prenom: "",
      sexe: "",
      date_naissance: "",
      statut: "",
      id_employe: "",
      id_personne: "",
      horaire: "",
      date_emploi: "",
      date_fin_emploi: "",
      type_emploi: "",
      categorie_emploi: "",
      nom_entrepreneur: "",
      equipe: "",
      departement: "",
      sous_departement: "",
      titre_poste: "",
      titre_emploi: "",
      sauveteur: "",
      pompier: "",
      realite: "",
      niveau_superieur: "",
      matricule_superieur: "",
      nom_superieur: "",
      prenom_superieur: "",
      id_sup_immediat: ""
    }
  }

  saveWorker() {
    this.is_verifing = true
    if (this.form_new_worker.nom == "" || this.form_new_worker.prenom == "" || this.form_new_worker.sexe == "") return
    if (this.form_new_worker.statut == "" || this.form_new_worker.id_employe == "") return
    if (this.form_new_worker.type_emploi == "" || this.form_new_worker.categorie_emploi == "") return
    if (this.form_new_worker.nom_entrepreneur == "" || this.form_new_worker.equipe == "") return
    if (this.form_new_worker.departement == "") return
    if (this.form_new_worker.titre_emploi == "" || this.form_new_worker.titre_poste == "") return
    if (this.form_new_worker.sauveteur == "" || this.form_new_worker.pompier == "" || this.form_new_worker.realite == "") return
    if (this.form_new_worker.matricule_superieur != "" && (this.form_new_worker.nom_superieur == "" || this.form_new_worker.prenom_superieur == "")) return
    this.travailleurService.addTravailleurService(this.form_new_worker).subscribe((data: any) => {
      this.toastService.Success("Travailleur enregistré !")
      this.resetForm()
      this.getDataTravailleur()
    })
  }

  getFullNameSuperieur(form: any) {
    var result = this.all_data_travailleur.filter(t => t.matricule == form.matricule_superieur)
    if (result.length > 0) {
      form.nom_superieur = result[0].nom_travailleur_cap
      form.prenom_superieur = result[0].prenom_travailleur_cap
      form.id_sup_immediat = result[0].id_travailleur
    }
  }

  @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;

  getDataDropDown() {
    var dataObj = { table: "sous_departement" }
    this.travailleurService.getDataDropDownService(dataObj).subscribe((data: any) => {
      this.sous_departement_liste = data[0]
      this.statut_employe_liste = data[1]
      this.departement_liste = data[2]
      this.fonction_liste = data[3]
      this.sexe_liste = data[4]
      this.entrepreneur_liste = data[5]
      this.type_emploi_liste = data[6]
      this.cat_emploi_liste = data[7]
      this.equipe_liste = data[8]
      this.emploi_liste = data[9]
      this.horaire_liste = data[10]
      this.getDataTravailleur()
    })
  }

  getDataTravailleur() {
    this.is_loading = true
    var dataObj = { filtre: { date_journal: this.date_donnee == null || this.date_donnee == undefined || this.date_donnee == "" ? this.current_date : this.date_donnee } }
    this.ancienne_data = this.current_date != dataObj.filtre.date_journal
    this.travailleurService.getTravailleurService(dataObj).subscribe((data: any) => {
      data.forEach(row => {
        row.statut_employe = this.initCap(row.statut_employe)
        row.libelle_sexe = this.initCap(row.libelle_sexe)
        row.nom_prenom = `${row.nom_travailleur_cap}, ${row.prenom_travailleur_cap}`
        row.date_naissance = DateTime.fromJSDate(new Date(row.date_naissance)).toFormat("yyyy-MM-dd")
        row.date_emploi = row.date_emploi ? DateTime.fromJSDate(new Date(row.date_emploi)).toFormat("yyyy-MM-dd") : ''
        row.date_fin_emploi = row.date_fin_emploi ? DateTime.fromJSDate(new Date(row.date_fin_emploi)).toFormat("yyyy-MM-dd") : ''
        row.type_emploi = this.initCap(row.type_emploi)
        row.cat_emploi = this.initCap(row.cat_emploi)
        row.equipe = row.equipes && row.equipes[0] ? row.equipes[0].nom_equipe : ''
        row.nom_sous_departement = this.initCap(row.nom_sous_departement)
        row.nom_fonction = this.initCap(row.nom_fonction)
        row.nom_emploi = this.initCap(row.nom_emploi)
        row.sauveteur = row.sauveteur == false ? "Non" : "Oui"
        row.pompier = row.pompier == false ? "Non" : "Oui"
        row.realite = row.realite == false ? "Non" : "Oui"
        row.nom_prenom_sup = row.nom_sup_immediat == "" ? "" : `${row.nom_sup_immediat}, ${row.prenom_sup_immediat}`
        row.nom_prenom_superviseur = row.nom_superviseur == "" ? "" : `${row.nom_superviseur}, ${row.prenom_superviseur}`
        row.equipe = this.initCap(row.equipe)
        row.nom_departement = this.initCap(row.nom_departement)
        row.nom_entrepreneur = this.initCap(row.nom_entrepreneur)
      });
      this.all_data_travailleur = data
      this.total_ligne = this.all_data_travailleur.length
      this.current_ligne = this.all_data_travailleur.length
      this.actif_ligne = this.all_data_travailleur.filter(t => t.statut_employe == "Actif").length
      this.inactif_ligne = this.all_data_travailleur.filter(t => t.statut_employe == "Inactif").length
      const savedFilters = this.cookies.get('handsontable_filters');
      const savedSortConfig = sessionStorage.getItem('handsontable_sortingColumnConfig')
      const savedHiddenColumn = this.cookies.get('data_column')
      var condition = []
      var sortConfig = []
      var columnsHidden = []
      if (savedFilters) condition = JSON.parse(savedFilters);
      if (savedSortConfig) sortConfig = JSON.parse(savedSortConfig)
      if (savedHiddenColumn) {
        var columns = JSON.parse(savedHiddenColumn)
        this.headers = [...columns]
        columns.forEach((c, i) => {
          if (c.afficher == false) columnsHidden.push(i)
        })
      }

      if (this.hot) {
        this.hot.loadData(data);
        this.hot.render();
        this.applyFilter(condition)
        this.is_loading = false
        return;
      }

      this.Maketable(data, this.headers.map(h => h.nom_colonne), condition, sortConfig, columnsHidden)
    })
  }

  initCap(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  Maketable(data: any, headers: any = null, condition: any, sortConfig: any, columnsHidden: any) {

    let dropDown_sous_departement = [];
    this.sous_departement_liste.forEach(element => { dropDown_sous_departement.push(this.initCap(element.nom_sous_departement)) });

    let dropDown_statut_employe = []
    this.statut_employe_liste.forEach(element => { dropDown_statut_employe.push(this.initCap(element.libelle_statut)) });

    let dropDown_code_departement = []
    this.departement_liste.forEach(element => { dropDown_code_departement.push(element.code_organisation) });

    let dropDown_departement = []
    this.departement_liste.forEach(element => { dropDown_departement.push(this.initCap(element.nom_departement)) });

    let dropDown_id_fonction = []
    this.fonction_liste.forEach(element => { dropDown_id_fonction.push(element.id_poste) });

    let dropDown_fonction = []
    this.fonction_liste.forEach(element => { dropDown_fonction.push(this.initCap(element.nom_fonction)) });

    let dropDown_sexe = []
    this.sexe_liste.forEach(element => { dropDown_sexe.push(this.initCap(element.libelle_sexe)) });

    let dropDown_entrepreneur = []
    this.entrepreneur_liste.forEach(element => { dropDown_entrepreneur.push(this.initCap(element.nom_entrepreneur)) });

    let dropDown_type_emploi = []
    this.type_emploi_liste.forEach(element => { dropDown_type_emploi.push(this.initCap(element.libelle_type_emploi)) });

    let dropDown_cat_emploi = []
    this.cat_emploi_liste.forEach(element => { dropDown_cat_emploi.push(this.initCap(element.libelle_cat_emploi)) });

    let dropDown_equipe_liste = []
    this.equipe_liste.forEach(element => { dropDown_equipe_liste.push(this.initCap(element.nom_equipe)) });

    let dropDown_emploi_liste = []
    this.emploi_liste.forEach(element => { dropDown_emploi_liste.push(this.initCap(element.nom_emploi)) });

    let dropDown_id_emploi_liste = []
    this.emploi_liste.forEach(element => { dropDown_id_emploi_liste.push(element.id_emp) });

    let dropDown_horaire = []
    this.horaire_liste.forEach(element => { dropDown_horaire.push(element.libelle_horaire) });

    this.hot = new Handsontable(this.tableContainer.nativeElement, {
      language: 'fr-FR',
      data: data,
      width: '100%',
      colHeaders: headers,
      rowHeaders: true,
      readOnly: !this.access,
      cells: function (row, col, prop) {
        const cellProperties: any = {};
        cellProperties.renderer = (instance, td, row, col, prop, value) => {
          td.style.backgroundColor = row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
          td.innerHTML = value;
        }
        switch (col) {
          case 1: // Statut
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_statut_employe;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_statut_employe.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 3: // Sexe
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_sexe;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_sexe.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 6: // Date naissance
          case 7: // Date d'emploi
          case 8: // Date fin d'emploi
            cellProperties.type = 'date';
            cellProperties.dateFormat = 'YYYY-MM-DD';
            break;
          case 9: // Type d'emploi
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_type_emploi;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_type_emploi.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 10: // Catégorie d'emploi
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_cat_emploi;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_cat_emploi.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 11: // Entrepreneur
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_entrepreneur;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_entrepreneur.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 12: // Horaire
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_horaire;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_horaire.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 13: // Equipe
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_equipe_liste;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_equipe_liste.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 14: // Code organisation (Département)
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_code_departement;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_code_departement.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 15: // Département
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_departement;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_departement.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 16: // Sous Département
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_sous_departement;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_sous_departement.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 17: // ID Poste
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_id_fonction;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_id_fonction.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 18: // Titre Poste
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_fonction;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_fonction.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 19: // ID Emploi
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_id_emploi_liste;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_id_emploi_liste.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 20: // Titre d'emploi
            cellProperties.type = 'dropdown';
            cellProperties.source = dropDown_emploi_liste;
            cellProperties.strict = true;
            cellProperties.renderer = (instance, td, row, col, prop, value) => {
              td.style.backgroundColor = !dropDown_emploi_liste.includes(value) ? '#e31e3a20' : row % 2 === 0 ? '#bfbaeb45' : '#f8f8f8';
              td.innerHTML = value
            }
            break
          case 21: // Sauveteur
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 22: // Pompier
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
          case 23: // Réalité
            cellProperties.type = 'dropdown';
            cellProperties.source = ['Oui', 'Non'];
            cellProperties.strict = true;
            break
        }
        return cellProperties;
      },
      columns: [
        { data: "id_travailleur" },
        { data: "statut_employe" },
        { data: "matricule" },
        { data: "libelle_sexe" },
        { data: "id_personne" },
        { data: "nom_prenom" },
        { data: "date_naissance" },
        { data: "date_emploi" },
        { data: "date_fin_emploi" },
        { data: "type_emploi" },
        { data: "cat_emploi" },
        { data: "nom_entrepreneur" },
        { data: "horaire" },
        { data: "equipe" },
        { data: "code_organisation" },
        { data: "nom_departement" },
        { data: "nom_sous_departement" },
        { data: "id_poste" },
        { data: "nom_fonction" },
        { data: "id_emp" },
        { data: "nom_emploi" },
        { data: "sauveteur" },
        { data: "pompier" },
        { data: "realite" },
        { data: "sup_niveau" },
        { data: "nom_prenom_sup" },
        { data: "matricule_sup_immediat" },
        { data: "nom_prenom_superviseur" },
        { data: "matricule_superviseur" }
      ],
      hiddenColumns: {
        columns: columnsHidden,
        indicators: true
      },
      contextMenu: false,
      filters: true,
      // dropdownMenu: true,
      dropdownMenu: ['filter_by_condition', '---------', 'filter_operators', 'filter_by_value', 'filter_action_bar'],
      manualRowMove: false,
      manualColumnMove: false,
      columnSorting: {
        initialConfig: sortConfig[0]
      },
      stretchH: 'all',
      licenseKey: 'non-commercial-and-evaluation',
      // autoColumnSize: {
      //   useHeaders: false
      // },
      autoColumnSize: true,
      manualColumnResize: true,
      afterChange: (changes, source) => {
        if (!changes || this.is_updating) return;
        // if (!changes) return; 
        let columns_name_travailleur = ['nom_travailleur', 'prenom_travailleur']
        let dataObj = { updates: [], ids: [] };
        let nombre_data = changes.length
        if (nombre_data > 1 || nombre_data == 1) {
          if (changes[0][1] == changes[nombre_data - 1][1]) {
            var row = changes[0][0]
            var col = changes[0][1]
            var oldValue = changes[0][2]
            var newValue = changes[0][3]

            this.is_updating = true

            switch (col) {
              case "nom_prenom":
                if (!newValue.includes(",")) return
                let value_splited = newValue.split(',')
                value_splited.forEach((element, index) => {
                  dataObj.updates.push({ [columns_name_travailleur[index]]: element.trim() })
                });
                break

              case "statut_employe":
                var data = this.statut_employe_liste.filter(i => i.libelle_statut.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_statut_employe
                dataObj.updates.push({ id_statut_employe: id })
                break

              case "libelle_sexe":
                var data = this.sexe_liste.filter(i => i.libelle_sexe.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_sexe
                dataObj.updates.push({ id_sexe: id })
                break

              case "nom_entrepreneur":
                var data = this.entrepreneur_liste.filter(i => i.nom_entrepreneur.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_entrepreneur
                dataObj.updates.push({ id_entrepreneur: id })
                break

              case "equipe":
                var data = this.equipe_liste.filter(i => i.nom_equipe.toLowerCase() == newValue.toLowerCase())
                if (data.length == 0) return
                var id = data[0].id_equipe
                dataObj.updates.push({ id_equipe: id })
                break

              case "nom_prenom_superviseur":
                if (newValue == "") {
                  if (this.hot.getDataAtCell(row, 28) !== "") {
                    this.hot.setDataAtCell(row, 28, "");
                  }
                  dataObj.updates.push({ id_superviseur: 0 })
                } else {
                  if (!newValue.includes(",")) return
                  let value_splited_sup = newValue.split(',')
                  var data_sup = this.all_data_travailleur.filter(t => t.nom_travailleur.toLowerCase() == value_splited_sup[0].toLowerCase() && t.prenom_travailleur.toLowerCase() == value_splited_sup[1].toLowerCase().trim())
                  if (data_sup.length == 0) return
                  var id_sup_travailleur = data_sup[0].id_travailleur
                  var matricule = data_sup[0].matricule
                  if (this.hot.getDataAtCell(row, 28) !== matricule) {
                    this.hot.setDataAtCell(row, 28, matricule);
                  }
                  dataObj.updates.push({ id_superviseur: id_sup_travailleur })
                }
                break

              case "matricule_superviseur":
                if (newValue == "") {
                  if (this.hot.getDataAtCell(row, 27) !== "") {
                    this.hot.setDataAtCell(row, 27, "");
                  }
                  dataObj.updates.push({ id_superviseur: 0 })
                } else {
                  var data_sup = this.all_data_travailleur.filter(t => t.matricule == newValue)
                  if (data_sup.length == 0) return
                  var id_sup_travailleur = data_sup[0].id_travailleur
                  var nom_prenom_sup = `${this.initCap(data_sup[0].nom_travailleur)}, ${this.initCap(data_sup[0].prenom_travailleur)}`
                  if (this.hot.getDataAtCell(row, 27) !== nom_prenom_sup) {
                    this.hot.setDataAtCell(row, 27, nom_prenom_sup);
                  }
                  dataObj.updates.push({ id_superviseur: id_sup_travailleur })
                }
                break

              case "nom_prenom_sup":
                if (newValue == "") {
                  if (this.hot.getDataAtCell(row, 26) !== "") {
                    this.hot.setDataAtCell(row, 26, "");
                  }
                  dataObj.updates.push({ id_sup_immediat: 0 })
                } else {
                  if (!newValue.includes(",")) return
                  let value_splited_sup = newValue.split(',')
                  var data_sup = this.all_data_travailleur.filter(t => t.nom_travailleur.toLowerCase() == value_splited_sup[0].toLowerCase() && t.prenom_travailleur.toLowerCase() == value_splited_sup[1].toLowerCase().trim())
                  if (data_sup.length == 0) return
                  var id_sup_travailleur = data_sup[0].id_travailleur
                  var matricule = data_sup[0].matricule
                  if (this.hot.getDataAtCell(row, 26) !== matricule) {
                    this.hot.setDataAtCell(row, 26, matricule);
                  }
                  dataObj.updates.push({ id_sup_immediat: id_sup_travailleur })
                }
                break

              case "matricule_sup_immediat":
                if (newValue == "") {
                  if (this.hot.getDataAtCell(row, 25) !== "") {
                    this.hot.setDataAtCell(row, 25, "");
                  }
                  dataObj.updates.push({ id_sup_immediat: 0 })
                } else {
                  var data_sup = this.all_data_travailleur.filter(t => t.matricule == newValue)
                  if (data_sup.length == 0) return
                  var id_sup_travailleur = data_sup[0].id_travailleur
                  var nom_prenom_sup = `${this.initCap(data_sup[0].nom_travailleur)}, ${this.initCap(data_sup[0].prenom_travailleur)}`
                  if (this.hot.getDataAtCell(row, 25) !== nom_prenom_sup) {
                    this.hot.setDataAtCell(row, 25, nom_prenom_sup);
                  }
                  dataObj.updates.push({ id_sup_immediat: id_sup_travailleur })
                }
                break

              case "sauveteur":
              case "pompier":
              case "realite":
                var value_realite = newValue == "Oui" ? true : false
                dataObj.updates.push({ [col]: value_realite })
                break

              case "nom_departement":
                var value_data_departement = this.departement_liste.filter(t => t.nom_departement.toLowerCase() == newValue.toLowerCase())
                if (value_data_departement.length == 0) return
                var id_departement = value_data_departement[0].id_departement
                var code = value_data_departement[0].code_organisation
                if (this.hot.getDataAtCell(row, 14) !== code) {
                  this.hot.setDataAtCell(row, 14, code);
                }
                dataObj.updates.push({ id_departement: id_departement })
                break

              case "code_organisation":
                var value_data_departement = this.departement_liste.filter(t => t.code_organisation == newValue)
                if (value_data_departement.length == 0) return
                var nom_departement = this.initCap(value_data_departement[0].nom_departement)
                var id_departement = value_data_departement[0].id_departement
                if (this.hot.getDataAtCell(row, 15) !== nom_departement) {
                  this.hot.setDataAtCell(row, 15, nom_departement);
                }
                dataObj.updates.push({ id_departement: id_departement })
                break

              case "nom_fonction":
                var value_data_fonction = this.fonction_liste.filter(t => t.nom_fonction.toLowerCase() == newValue.toLowerCase())
                if (value_data_fonction.length == 0) return
                var code_poste = value_data_fonction[0].id_poste
                var id_fonction = value_data_fonction[0].id_fonction
                if (this.hot.getDataAtCell(row, 17) !== code_poste) {
                  this.hot.setDataAtCell(row, 17, code_poste);
                }
                dataObj.updates.push({ id_fonction: id_fonction })
                break

              case "id_poste":
                var value_data_fonction = this.fonction_liste.filter(t => t.id_poste == newValue)
                if (value_data_fonction.length == 0) return
                var nom_fonction = this.initCap(value_data_fonction[0].nom_fonction)
                var id_fonction = value_data_fonction[0].id_fonction
                if (this.hot.getDataAtCell(row, 18) !== nom_fonction) {
                  this.hot.setDataAtCell(row, 18, nom_fonction);
                }
                dataObj.updates.push({ id_fonction: id_fonction })
                break

              case "nom_emploi":
                var value_data_fonction = this.emploi_liste.filter(t => t.nom_emploi.toLowerCase() == newValue.toLowerCase())
                if (value_data_fonction.length == 0) return
                var id_emp = value_data_fonction[0].id_emp
                var id_emploi = value_data_fonction[0].id_emploi
                if (this.hot.getDataAtCell(row, 19) !== id_emp) {
                  this.hot.setDataAtCell(row, 19, id_emp);
                }
                dataObj.updates.push({ id_emploi: id_emploi })
                break

              case "id_emp":
                var value_data_fonction = this.emploi_liste.filter(t => t.id_emp == newValue)
                if (value_data_fonction.length == 0) return
                var nom_emploi = this.initCap(value_data_fonction[0].nom_emploi)
                var id_emploi = value_data_fonction[0].id_emploi
                if (this.hot.getDataAtCell(row, 20) !== nom_emploi) {
                  this.hot.setDataAtCell(row, 20, nom_emploi);
                }
                dataObj.updates.push({ id_emploi: id_emploi })
                break

              case "nom_sous_departement":
                var value_data_sous_departement = this.sous_departement_liste.filter(t => t.nom_sous_departement.toLowerCase() == newValue.toLowerCase())
                if (value_data_sous_departement.length == 0) return
                var id_sous_departement = value_data_sous_departement[0].id_sous_departement
                dataObj.updates.push({ id_sous_departement: id_sous_departement })
                break

              case "type_emploi":
                var value_data_type_emploi = this.type_emploi_liste.filter(t => t.libelle_type_emploi.toLowerCase() == newValue.toLowerCase())
                if (value_data_type_emploi.length == 0) return
                var id_type_emploi = value_data_type_emploi[0].id_type_emploi
                dataObj.updates.push({ id_type_emploi: id_type_emploi })
                break

              case "cat_emploi":
                var value_data_cat_emploi = this.cat_emploi_liste.filter(t => t.libelle_cat_emploi.toLowerCase() == newValue.toLowerCase())
                if (value_data_cat_emploi.length == 0) return
                var id_cat_emploi = value_data_cat_emploi[0].id_cat_emploi
                dataObj.updates.push({ id_cat_emploi: id_cat_emploi })
                break

              case "horaire":
                var value_horaire = this.horaire_liste.filter(t => t.libelle_horaire == newValue)
                if (value_horaire.length == 0) return
                dataObj.updates.push({ [col]: newValue })
                break

              default:
                if ((col == "date_naissance" || col == "date_emploi" || col == "date_fin_emploi") && !this.isValidDate(newValue)) return
                dataObj.updates.push({ [col]: newValue })
                break
            }

            changes.forEach(change => {
              const updatedData = this.hot.getData()[change[0]];
              const id_travailleur = updatedData[0]
              dataObj.ids.push(id_travailleur)
            });

            this.is_updating = false
          }
        }
        this.travailleurService.updateTravailleurService(dataObj).subscribe((data: any) => { })

      },
      afterFilter: (conditions) => {
        var stringCondition = JSON.stringify(conditions)
        this.cookies.set('handsontable_filters', stringCondition, 100)
        this.current_ligne = this.hot.getData().length
      },
      afterColumnSort(currentSortConfig, destinationSortConfigs) {
        var stringSortConfig = JSON.stringify(destinationSortConfigs)
        sessionStorage.setItem('handsontable_sortingColumnConfig', stringSortConfig)
      },
    });

    this.applyFilter(condition)

    const thisHot: Handsontable = this.hot;
    Handsontable.hooks.add('modifyColWidth', function (width) {
      const x: any = thisHot.getPlugin('dropdownMenu').menu;
      if (this === x.hotMenu) {
        return 300;
      }
      return width;
    });

    this.current_ligne = this.hot.getData().length
    this.is_loading = false
  }

  applyFilter(condition:any) {
    const filtersPlugin = this.hot.getPlugin('filters');
    condition.forEach((cond, index) => {
      var column = cond.column
      var operation = cond.operation
      var data_condition = cond.conditions
      var name = data_condition[0].name
      var args = data_condition[0].args
      filtersPlugin.addCondition(column, name, args, operation);
    })
    filtersPlugin.filter();
  }

  resetFilter() {
    const filtersPlugin = this.hot.getPlugin('filters');
    filtersPlugin.clearConditions()
    filtersPlugin.filter()
  }

  hideShowColumn(index: any) {
    const hiddenColumnsPlugin = this.hot.getPlugin('hiddenColumns')
    if (hiddenColumnsPlugin.isHidden(index)) {
      hiddenColumnsPlugin.showColumn(index)
    } else {
      hiddenColumnsPlugin.hideColumn(index)
    }
    this.cookies.set('data_column', JSON.stringify(this.headers), 100)
    this.hot.render()
  }

  isValidDate(date) {
    if (date != "") {
      const d = new Date(date);
      return !isNaN(d.getTime());
    } else {
      return true
    }
  }

  exportData() {
    if (this.date_donnee == "" || this.date_donnee == null || this.date_donnee == undefined) {
      this.toastService.Warning("Veuillez sélectionner une date")
      return
    }
    var dataObj = { date_extraction: this.date_donnee }
    this.is_downloading = true
    this.travailleurService.exportTravailleurService(dataObj).subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${this.date_donnee} Tableau global`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }
}

