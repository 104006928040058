import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormationService {

  constructor() { }

  mettreEnInitCap(phrase: string): string {
    return phrase
      .split(' ')
      .map(mot => mot.charAt(0).toUpperCase() + mot.slice(1).toLowerCase())
      .join(' ');
  }

  mettrePremierMotEnInitCap(phrase: string): string {
    const mots = phrase.split(' ');
    if (mots.length > 0) {
      mots[0] = mots[0].charAt(0).toUpperCase() + mots[0].slice(1).toLowerCase();
    }
    return mots.join(' ');
  }
}
