<div class="px-3 py-2">
    <div class="mb-2 d-flex flex-row justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
            <!-- <span class="h3">Mécanique Mobile et Fixe</span> -->
            <div class="input-group input-group-sm">
                <label class="input-group-text"  for="inputGroupSelect01">Département</label>
                <select class="form-select" id="inputGroupSelect01" [(ngModel)]="departement" (change)="getOrganigrammeData(departement)" style="padding-top: 0; padding-bottom: 0;">
                    <option style="font-size: 11px;" selected value="1">TOUS</option>
                    <option style="font-size: 11px;" *ngFor="let departement of departement_liste" value={{departement.code_organisation}}>{{departement.nom_departement}}</option>
                </select>
            </div>
            <div>
                <div class="spinner-border ms-3" role="status" *ngIf="is_loading">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        <div>
            <!-- <input type="text" [(ngModel)]="searchText" (input)="searchNode()" placeholder="Rechercher par nom" /> -->
            <button class="btn btn-sm btn-secondary" (click)="zoomIn()"> <i class="bi bi-plus-lg"
                    style="font-size: 12px;"></i> </button>
            <button class="btn btn-sm btn-secondary ms-1" (click)="zoomOut()"> <i class="bi bi-dash-lg"
                    style="font-size: 12px;"></i> </button>
            <button class="ms-2 btn-special" (click)="zoomToFit()">Ajuster le chart</button>
        </div>
    </div>
    <div id='myDiagramDiv' class="rounded border shadow-sm mt-2"></div>
</div>