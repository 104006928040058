import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from './services/toast/toast.service';
import { UsersService } from './services/users/users.service';
import { CryptageService } from './services/cryptage/cryptage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  onLog: boolean = false

  constructor(
    private cookies: CookieService,
    private userService: UsersService,
    private toast: ToastService,
    private cryptage: CryptageService,
    private router:Router
  ) {
    const cookieExists = this.cookies.check("auth")
    this.onLog = cookieExists
  }

  matricule: any
  password: any
  // user:any

  ngOnInit() { }

  logout() {
    this.userService.logoutService().subscribe(() => {
      this.cookies.delete("auth")
      this.cookies.delete("data_user")
      this.onLog = false
      this.password = ""
      this.router.navigate(['/'])
    })
    // this.cookies.delete("data_perso")
  }

  isNullOrUndefined(variable: any) {
    return variable == undefined || variable == null || variable == "" ? true : false
  }

  resetLogin() {
    this.matricule = ""
    this.password = ""
  }

  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      var dataCrypted = this.cryptage.encryptEncode(JSON.stringify(data))
      this.cookies.set('data_user', dataCrypted)
    })
  }

  login() {
    if (this.isNullOrUndefined(this.matricule) || this.isNullOrUndefined(this.password)) {
      this.toast.Warning("Veuillez remplir les champs")
    } else {
      this.userService.login({
        user: this.matricule,
        password: this.password
      }).subscribe({
        next: (data: { auth?: string }) => {
          if (data.auth) {
            const expiryDate = new Date();
            expiryDate.setHours(expiryDate.getHours() + 5);
            this.cookies.set('auth', data.auth, {expires:expiryDate})
            this.onLog = true
            this.getUser()
          }
          else {
            this.toast.Error("Utilisateur ou mot de passe incorrect")
          }
        },
        error: () => {
          this.toast.Error("Utilisateur ou mot de passe incorrect")
        }
      })
    }
  }

}


