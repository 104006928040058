<div class="modal-header" [ngStyle]="{'background-color':rowData.color}" *ngIf="rowData.statut_demande == 0">
    <h6 class="modal-title" id="exampleModalLabel">Détails de la formation </h6>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-header" *ngIf="rowData.statut_demande != 0" style="background-color: rgba(38, 150, 62, 0.676);">
    <h6 class="modal-title text-white" id="exampleModalLabel"> <i class="bi bi-pencil-square text-white me-1"></i>
        Formation ouverte</h6>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="text-center">
        <span style="font-size: 18px;">{{rowData.nom_formation}}</span> <br>
        <span class="text-secondary" style="font-size: 14px;">{{rowData.num_formation}} demandée par
            {{rowData.demandeur}} le {{rowData.date_demande | date:"dd/MM/yyyy"}}</span>
        <div *ngIf="rowData.statut_inscription != 'complétée' && is_admin">
            <div *ngIf="rowData.statut_demande == 0">
                <button class="btn btn-sm btn-outline-success mt-2" style="font-size: 12px;"
                    (click)="openCloseFormation(rowData, 1)"> <i class="bi bi-pencil-square me-1"
                        style="font-size: 11px;"></i> Ouvrir l'inscription</button>
                <button class="btn btn-sm btn-outline-danger mt-2 ms-2" style="font-size: 12px;"
                    (click)="deleteFormation(rowData)"> <i class="bi bi-trash me-1" style="font-size: 11px;"></i>
                    Supprimer la formation</button>
                <button class="btn btn-sm btn-outline-warning mt-2 ms-2" style="font-size: 12px;"
                    (click)="changeDateHeure()"> <i class="bi bi-pen-fill me-1" style="font-size: 11px;"></i>
                    Enregistrer les modifications</button>
            </div>
            <div *ngIf="rowData.statut_demande != 0">
                <button class="btn btn-sm btn-outline-danger mt-2" style="font-size: 12px;"
                    (click)="openCloseFormation(rowData, 0)">Fermer l'inscription</button>
            </div>
        </div>
        <div *ngIf="rowData.statut_inscription == 'complétée'" class="mt-3">
            <span class="text-success">Formation complétée</span>
        </div>
        <div class="row mt-3">
            <div class="col-6">
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text text-secondary" id="basic-addon1">N° demande</span>
                    <input type="text" class="form-control" [value]="rowData.num_demande"
                        placeholder="Numero de la demande" aria-label="Numero de formation"
                        aria-describedby="basic-addon1" readonly>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group input-group-sm mb-2">
                    <span class="input-group-text text-secondary" id="basic-addon1"> Date formation </span>
                    <input type="date" class="form-control" [(ngModel)]="rowData.date_prevue_text"
                        placeholder="Date de la formation" aria-label="Date de la formation"
                        aria-describedby="basic-addon1"
                        [readOnly]="rowData.statut_inscription == 'complétée' || !is_admin">
                </div>
            </div>
            <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text text-secondary" id="basic-addon1"> Heure début </span>
                    <input type="time" class="form-control" placeholder="Heure début"
                        [ngClass]="{'is-invalid':heure_error}" [(ngModel)]="rowData.heure_debut"
                        aria-label="Date de la formation" aria-describedby="basic-addon1"
                        [readOnly]="rowData.statut_inscription == 'complétée' || !is_admin">
                </div>
            </div>
            <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text text-secondary" id="basic-addon1"> Heure fin </span>
                    <input type="time" class="form-control" placeholder="Heure début"
                        [ngClass]="{'is-invalid':heure_error}" [(ngModel)]="rowData.heure_fin"
                        aria-label="Date de la formation" aria-describedby="basic-addon1"
                        [readOnly]="rowData.statut_inscription == 'complétée' || !is_admin">
                </div>
            </div>
        </div>
        <div class="rounded border p-3 mb-3" *ngIf="rowData.statut_demande != 0 && user_log.role != 4"
            style="background-color: #f6f6f6; box-shadow: 0 .5rem 1rem rgba(12, 90, 24, 0.286);">
            <div class="mb-2 d-flex flex-roz justify-content-between align-items-center">
                <div>
                    <span class="text-success"> <i class="bi bi-pencil-square"> </i> </span>
                    <span style="color: rgb(44, 44, 44);" class="ms-2">Inscription</span>
                </div>
                <div>
                    <button class="btn btn-sm" style="font-size: 10px; background-color: rgba(74, 127, 191, 0.359);" (click)="saveTravailleur()">
                        <span class="spinner-border spinner-border-sm" style="font-size: 12px;" *ngIf="is_saving" aria-hidden="true"></span>
                        <span *ngIf="!is_saving" style="font-size: 12px;">Valider</span>
                    </button>
                    <button class="btn btn-sm ms-1"
                        style="font-size: 10px; background-color: rgba(128, 128, 128, 0.356);" (click)="resetForm()"> <i
                            class="bi bi-x-lg" style="font-size: 10px;"></i> </button>
                </div>
            </div>
            <div class="mb-2 mt-3">
                <div class="input-group input-group-sm" style="width: 100%">
                    <span class="input-group-text" id="basic-addon1">Sélection de travailleur</span>
                    <input type="text" class="form-control" list="nom_prenom_matricule" [(ngModel)]="travailleur_search" (input)="filterWorkers()" (change)="selectingWorker()">
                </div>
                <datalist id="nom_prenom_matricule">
                    <option *ngFor="let worker of filteredTravailleurs" [value]="worker.unique_search">{{worker.id_personne}}</option>
                </datalist>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="form-floating p-0">
                    <input type="text" class="form-control" [(ngModel)]="inscription.matricule"
                        [ngClass]="{'is-invalid':inscription.matricule == '' && is_verifing}" id="matricule"
                        placeholder="name@example.com" style="font-size: 13px;" readonly>
                    <label for="matricule" style="font-size: 13px;">Matricule</label>
                </div>
                <div class="form-floating ms-1">
                    <input type="text" class="form-control" [(ngModel)]="inscription.nom_travailleur"
                        [ngClass]="{'is-invalid':inscription.nom_travailleur == '' && is_verifing}" id="nom"
                        placeholder="name@example.com" style="font-size: 13px;" readonly>
                    <label for="nom" style="font-size: 13px;">Nom</label>
                </div>
                <div class="form-floating ms-1">
                    <input type="text" class="form-control" [(ngModel)]="inscription.prenom_travailleur"
                        [ngClass]="{'is-invalid':inscription.prenom_travailleur == '' && is_verifing}" id="prenom"
                        placeholder="name@example.com" style="font-size: 13px;" readonly>
                    <label for="prenom" style="font-size: 13px;">Prénom</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="card p-2">
                    <div class="card-header fs-6 py-1">
                        Formateur(s)
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item py-1 fs-6" *ngFor="let formateur of rowData.formateurs">
                            {{formateur}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-6">
                <div class="card p-2">
                    <div class="card-header fs-6 py-1">
                        Participant(s)
                    </div>
                    <ul class="list-group list-group-flush" *ngIf="rowData.statut_demande == 1">
                        <ng-container *ngFor="let travailleur of rowData.travailleurs; let i = index">
                            <li
                                class="list-group-item py-1 fs-6 d-flex flex-row justify-content-between align-items-center">
                                <span>{{travailleur.nom_complet}}</span>
                                <i class="bi bi-trash text-danger"
                                    (click)="supprimerTravailleurFormation(travailleur.id_travailleur_formation, i)"
                                    style="font-size: 12px;" *ngIf="is_admin"></i>
                            </li>
                        </ng-container>
                    </ul>
                    <ul class="list-group list-group-flush" *ngIf="rowData.statut_demande == 0">
                        <ng-container *ngFor="let travailleur of rowData.travailleurs">
                            <li class="list-group-item py-1 fs-6">
                                <span>{{travailleur.nom_complet}}</span>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>