<div class="modal-header">
    <h6 class="modal-title" id="exampleModalLabel">Détails de la formation</h6>
    <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <div class="text-center">
        <!-- <span class="h3">Formation {{rowData.num_formation}}</span> <br> -->
        <span style="font-size: 18px;">{{rowData.nom_formation}}</span> <br>
        <span class="text-secondary" style="font-size: 14px;">{{rowData.num_formation}} demandée par
            {{rowData.demandeur}} le {{rowData.date_demande | date:"dd/MM/yyyy"}}</span>
        <div class="row mt-3">
            <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text text-secondary" id="basic-addon1">N° demande</span>
                    <input type="text" class="form-control" [value]="rowData.num_demande"
                        placeholder="Numero de la demande" aria-label="Numero de formation"
                        aria-describedby="basic-addon1" readonly>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text text-secondary" id="basic-addon1"> Date formation </span>
                    <!-- <span class="input-group-text" id="basic-addon1"> <i class="bi bi-calendar-event" style="font-size: 12px;"></i> </span> -->
                    <input type="text" class="form-control" [value]="rowData.date_prevue.toLocaleDateString()"
                        placeholder="Date de la formation" aria-label="Date de la formation"
                        aria-describedby="basic-addon1" readonly>
                </div>
            </div>
            <div class="col-6">
                <div class="card p-2">
                    <div class="card-header fs-6 py-1">
                        Formateur(s)
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item py-1 fs-6" *ngFor="let formateur of rowData.formateurs">
                            {{formateur}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-6">
                <div class="card p-2">
                    <div class="card-header fs-6 py-1">
                        Participant(s)
                    </div>
                    <ul class="list-group list-group-flush">
                        <ng-container *ngFor="let travailleur of rowData.travailleurs">
                            <li class="list-group-item py-1 fs-6"> {{travailleur.nom_complet}} </li>
                        </ng-container>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>