import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as go from 'gojs';
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

@Component({
  selector: 'app-ressource',
  templateUrl: './ressource.component.html',
  styleUrls: ['./ressource.component.css']
})

export class RessourceComponent implements OnInit {


  // public selectedNode = null;

  // public model: go.TreeModel = new go.TreeModel(
  // [
  //   { 'key': 1, 'name': 'Stella Payne Diaz', 'title': 'CEO' },
  //   { 'key': 2, 'name': 'Luke Warm', 'title': 'VP Marketing/Sales', 'parent': 1 },
  //   { 'key': 3, 'name': 'Meg Meehan Hoffa', 'title': 'Sales', 'parent': 2 },
  //   { 'key': 4, 'name': 'Peggy Flaming', 'title': 'VP Engineering', 'parent': 1 },
  //   { 'key': 5, 'name': 'Saul Wellingood', 'title': 'Manufacturing', 'parent': 4 },
  //   { 'key': 6, 'name': 'Al Ligori', 'title': 'Marketing', 'parent': 2 },
  //   { 'key': 7, 'name': 'Dot Stubadd', 'title': 'Sales Rep', 'parent': 3 },
  //   { 'key': 8, 'name': 'Les Ismore', 'title': 'Project Mgr', 'parent': 5 },
  //   { 'key': 9, 'name': 'April Lynn Parris', 'title': 'Events Mgr', 'parent': 6 },
  //   { 'key': 10, 'name': 'Xavier Breath', 'title': 'Engineering', 'parent': 4 },
  //   { 'key': 11, 'name': 'Anita Hammer', 'title': 'Process', 'parent': 5 },
  //   { 'key': 12, 'name': 'Billy Aiken', 'title': 'Software', 'parent': 10 },
  //   { 'key': 13, 'name': 'Stan Wellback', 'title': 'Testing', 'parent': 10 },
  //   { 'key': 14, 'name': 'Marge Innovera', 'title': 'Hardware', 'parent': 10 },
  //   { 'key': 15, 'name': 'Evan Elpus', 'title': 'Quality', 'parent': 5 },
  //   { 'key': 16, 'name': 'Lotta B. Essen', 'title': 'Sales Rep', 'parent': 3 }
  // ]
  // );

  // public setSelectedNode(node) {
  //   this.selectedNode = node;
  // }

  private rawData = [
    { 'key': 1, 'name': 'Stella Payne Diaz', 'title': 'CEO' },
    { 'key': 2, 'name': 'Luke Warm', 'title': 'VP Marketing/Sales', 'parent': 1 },
    { 'key': 3, 'name': 'Meg Meehan Hoffa', 'title': 'Sales', 'parent': 2 },
    { 'key': 4, 'name': 'Peggy Flaming', 'title': 'VP Engineering', 'parent': 1 },
    { 'key': 5, 'name': 'Saul Wellingood', 'title': 'Manufacturing', 'parent': 4 },
    { 'key': 6, 'name': 'Al Ligori', 'title': 'Marketing', 'parent': 2 },
    { 'key': 7, 'name': 'Dot Stubadd', 'title': 'Sales Rep', 'parent': 3 },
    { 'key': 8, 'name': 'Les Ismore', 'title': 'Project Mgr', 'parent': 5 },
    { 'key': 9, 'name': 'April Lynn Parris', 'title': 'Events Mgr', 'parent': 6 },
    { 'key': 10, 'name': 'Xavier Breath', 'title': 'Engineering', 'parent': 4 },
    { 'key': 11, 'name': 'Anita Hammer', 'title': 'Process', 'parent': 5 },
    { 'key': 12, 'name': 'Billy Aiken', 'title': 'Software', 'parent': 10 },
    { 'key': 13, 'name': 'Stan Wellback', 'title': 'Testing', 'parent': 10 },
    { 'key': 14, 'name': 'Marge Innovera', 'title': 'Hardware', 'parent': 10 },
    { 'key': 15, 'name': 'Evan Elpus', 'title': 'Quality', 'parent': 5 },
    { 'key': 16, 'name': 'Lotta B. Essen', 'title': 'Sales Rep', 'parent': 3 }
  ];

  private root: am5.Root | undefined;
  constructor(private elementRef: ElementRef) { }
  ngOnInit(): void {
    this.root = am5.Root.new(this.elementRef.nativeElement.querySelector("#chartdiv"));
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    let zoomableContainer = this.root.container.children.push(
      am5.ZoomableContainer.new(this.root, {
        width: am5.percent(100),
        height: am5.percent(100),
        wheelable: true,
        pinchZoom: true
      })
    );

    let zoomTools = zoomableContainer.children.push(
      am5.ZoomTools.new(this.root, { target: zoomableContainer })
    );

    // let series = zoomableContainer.contents.children.push(am5hierarchy.Tree.new(this.root, {
    //   singleBranchOnly: false,
    //   downDepth: 1,
    //   initialDepth: 10,
    //   valueField: "value",
    //   categoryField: "name",
    //   childDataField: "children"
    // }));
    // series.labels.template.set("minScale", 0);

    let series = zoomableContainer.contents.children.push(am5hierarchy.Tree.new(this.root, {
      singleBranchOnly: false,
      downDepth: 1,
      initialDepth: 10,
      valueField: "value",
      categoryField: "name",
      childDataField: "children"
    }));
    
    // Increase text size
    series.labels.template.set("fontSize", 20); // Adjust as needed (e.g., 18, 22)
    
    // Increase circle size (outer and inner)
    series.circles.template.setAll({
      width: 20, // Adjust as needed (e.g., 18, 22)
      height: 20, // Adjust as needed (e.g., 18, 22)
    });
    series.outerCircles.template.setAll({
      width: 24, // Adjust as needed (e.g., 22, 26)
      height: 24, // Adjust as needed (e.g., 22, 26)
    });
    // let data = {
    //   name: "Mr Lalaina", children: [
    //     { name: "Ny Vahatra", children: [] },
    //     {
    //       name: "Tsiory", children: [
    //         { name: "Lanja" }
    //       ]
    //     },
    //     { name: "Safidy", children: [] },
    //   ]
    // };
    // this.generateLevel(data, "", 0);

    // series.data.setAll([data]);
    let hierarchicalData = this.buildHierarchy(this.rawData);
    series.data.setAll([hierarchicalData]);
    series.set("selectedDataItem", series.dataItems[0]);

    series.appear(1000, 100);
  }

  buildHierarchy(data: any[]) {
    let map: { [key: number]: any } = {};
    let root: any = null;

    data.forEach(item => {
      map[item.key] = { ...item, children: [] };
    });

    data.forEach(item => {
      if (item.parent != null) {
        map[item.parent].children.push(map[item.key]);
      } else {
        root = map[item.key];
      }
    });

    return root;
  }

  generateLevel(data: any, name: string, level: number) {
    const maxLevels = 3;
    const maxNodes = 3;
    const maxValue = 100;

    for (let i = 0; i < Math.ceil(maxNodes * Math.random()) + 1; i++) {
      let nodeName = name + "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[i];
      let child;
      if (level < maxLevels) {
        child = { name: nodeName + level };

        if (level > 0 && Math.random() < 0.5) {
          child.value = Math.round(Math.random() * maxValue);
        } else {
          child.children = [];
          this.generateLevel(child, nodeName + i, level + 1);
        }
      } else {
        child = { name: name + i, value: Math.round(Math.random() * maxValue) };
      }
      data.children.push(child);
    }
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }
}
