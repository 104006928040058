<div class="block-1"></div>
<div class="block-2"></div>
<div class="block-3"></div>
<div class="py-4 px-5" style="margin-top:30px; overflow: auto; max-height: 86vh;">
    <span #typewriterSpan class="typewriter"></span>
    <div style="color: rgb(80, 80, 80);">
        <span class="display-6">Bienvenue dans Formation Management</span>
        <div class="mt-3">
           <span>Optimisez la gestion et le suivi des formations ainsi que l’organisation des équipes dans le secteur minier grâce à notre application tout-en-un !</span>
           <div class="mt-3">
               <span> <i class="bi bi-person-video3 me-1" style="color: #707fdd;"></i> Gestion des formations</span>
               <ul>
                    <li id="nom-complet">
                        <span> Planifiez facilement vos formations pour une meilleure organisation </span>
                        <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/planification-formation" style="font-size: 12px; color: #707fdd;"></i>
                    </li>
                    <li id="nom-complet">
                        <span>Consultez le calendrier des formations planifiées et organisez votre emploi du temps</span>
                        <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/calendrier-formation" style="font-size: 12px; color: red;"></i>
                    </li>
                    <li id="nom-complet">
                        <span>Visualisez le tableau des formations pour une gestion claire et rapide</span>
                        <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/tableau-formation" style="font-size: 12px; color: #707fdd;"></i>
                    </li>
                    <li id="nom-complet">
                        <span>Accédez à la liste complète des formations disponibles</span>
                        <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/liste-formation" style="font-size: 12px; color: #707fdd;"></i>
                    </li>
                    <li id="nom-complet">
                        <span>Découvrez les formateurs et leurs domaines d’expertise</span>
                        <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/portrait-formateur" style="font-size: 12px; color: #707fdd;"></i>
                    </li>
               </ul>
           </div>
           <div class="mt-3">
            <span> <i class="bi bi-people-fill me-1" style="color: #707fdd;"></i> Gestion des travailleurs</span>
            <ul>
                 <li id="nom-complet">
                     <span> Consultez la liste des travailleurs et leurs informations détaillées </span>
                     <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/people" style="font-size: 12px; color: #707fdd;"></i>
                 </li>
                 <li id="nom-complet">
                     <span>Visualisez l’organigramme de l’entreprise pour mieux comprendre la hiérarchie et les responsabilités</span>
                     <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/ressource" style="font-size: 12px; color: #707fdd;"></i>
                 </li>
                 <li id="nom-complet">
                     <span>Accédez au calendrier de rotation de chaque travailleur pour une gestion optimisée des équipes</span>
                     <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/calendrier-ressource" style="font-size: 12px; color: #707fdd;"></i>
                 </li>
                 <li id="nom-complet">
                     <span>Consultez les compétences et certifications de chaque employé pour mieux répartir les tâches</span>
                     <i class="bi bi-box-arrow-up-right" id="icon-edit" routerLink="/competence-travailleur" style="font-size: 12px; color: #707fdd;"></i>
                 </li>
            </ul>
        </div>
           <span>Que vous soyez mineur, technicien, superviseur ou gestionnaire, notre plateforme vous permet de gérer efficacement les formations, <br> les compétences et l’organisation du personnel pour garantir sécurité et performance sur le terrain.</span>
        </div>
    </div>
    <div class="mt-4 d-flex flex-row align-items-center image-slider">
        <div class="p-1 shadow rounded" style="width: 200px; height: 150px; background-color: #707fdd44;">
            <img src="assets/images/ACCUIEL/1727393150568.jpg" alt="IMAGE" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
        <div class="p-1 shadow rounded ms-2" style="width: 200px; height: 150px; background-color: #707fdd44;">
            <img src="assets/images/ACCUIEL/Hecla-CasaBerardi-14-DSC_2795.jpg" alt="IMAGE" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
        <div class="p-1 shadow rounded ms-2" style="width: 200px; height: 150px; background-color: #707fdd44;">
            <img src="assets/images/ACCUIEL/hecla_2.jpg" alt="IMAGE" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
        <div class="p-1 shadow rounded ms-2" style="width: 200px; height: 150px; background-color: #707fdd44;">
            <img src="assets/images/ACCUIEL/Hecla-Careers-Positive-work-environment.jpg" alt="IMAGE" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
        <div class="p-1 shadow rounded ms-2" style="width: 200px; height: 150px; background-color: #707fdd44;">
            <img src="assets/images/ACCUIEL/DSCF0298-scaled-1-aspect-ratio-320-320.jpg" alt="IMAGE" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
    </div>
</div>