import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastService } from '../services/toast/toast.service';
import { MenuService } from '../services/menu/menu.service';
import { CryptageService } from '../services/cryptage/cryptage.service';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  
  @ViewChild('typewriterSpan') typewriterSpan!: ElementRef;
  
  private text:any = "";
  private index = 0;

  dataUser:any = {}

  constructor(
    private cookies:CookieService,
    private cryptage:CryptageService
  ) { 
    
  }

  ngOnInit(): void {
    
  }
  

  ngAfterViewInit() {
    const checkDataUser = setInterval(() => {
      const data = this.cookies.get('data_user');
      if (data) {
        try {
          this.dataUser = JSON.parse(this.cryptage.decryptDecode(data));
          this.text = "Bonjour " + this.dataUser.prenom_user + " " + this.dataUser.nom_user;
          this.typeWriter();
          clearInterval(checkDataUser);
        } catch (error) {
          console.error("Erreur de décryptage/parsing des cookies :", error);
        }
      }
    }, 500);
  }

  typeWriter() {
    if (this.index < this.text.length) {
      this.typewriterSpan.nativeElement.innerHTML += this.text.charAt(this.index);
      this.index++;
      setTimeout(() => this.typeWriter(), 50);
    }
  }

}
