import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import { UsersService } from 'src/app/services/users/users.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';

@Component({
  selector: 'app-tableau-formation',
  templateUrl: './tableau-formation.component.html',
  styleUrls: ['./tableau-formation.component.css']
})
export class TableauFormationComponent implements OnInit {

  dataTableauGlobal: any
  dataTableauGlobal_dynamique: any
  dataTableauGlobal_temp: any
  filterStatus: any = "0"

  dataTableau: any
  dataTableau_dynamique: any
  dataTableau_temp: any
  formation_search: any = ""
  data_calendrier: any
  is_loading: any = false
  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    num_demande: false,
    demandeur: false,
    date_prevue: false,
    statut_inscription: false,
    num_formation: false,
    nom_formation: false,
    nom_formateur: false,
    travailleur: false,
  }
  is_downloading: any = false

  access: any = false
  role_access: any = [99, 10]
  is_admin: any = false
  user_log: any = []

  allTravailleurs: any = []
  dataCalendrier: any = []

  liste_formateur_global:any = []
  liste_formateur_detaille:any = []
  search_formateur:any = ""
  search_formateur_detaille:any = "tous"

  currentStatus: string = "0";
  currentFormateur: string = "";

  currentFormationSearch: string = '';
  currentFormateurFilter: string = 'tous';

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.user_log = data
      this.is_admin = this.role_access.includes(this.user_log.role)
    })
  }

  constructor(
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private userService: UsersService,
    private travailleurService: TravailleurService
  ) {
    this.getUser()
    this.getAllTravailleurs()
    this.getDataCalendrier()
  }

  ngOnInit(): void { }


  searchFormation(formation: string) {
    this.currentFormationSearch = formation.toLowerCase();
    this.applyFiltersDetaille();
  }

  filterByFormateurDetaille(formateur: string) {
    this.currentFormateurFilter = formateur;
    this.applyFiltersDetaille();
  }

  applyFiltersDetaille() {
    this.dataTableau_dynamique = this.dataTableau.filter(dt => {
      const matchesFormation = dt.nom_formation.toLowerCase().includes(this.currentFormationSearch);
      const matchesFormateur = this.currentFormateurFilter === 'tous' || dt.nom_formateur === this.currentFormateurFilter;
      return matchesFormation && matchesFormateur;
    });
  }

  resetSearch() {
    this.dataTableau_dynamique = [...this.dataTableau];
    this.currentFormationSearch = '';
    this.currentFormateurFilter = 'tous';
  }

  trierParTravailleurs(data) {
    return data.sort((a, b) => b.travailleurs.length - a.travailleurs.length);
  }

  getAllTravailleurs() {
    this.is_loading = true
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.allTravailleurs = data
    })
  }

  getDataCalendrier() {
    var dataObj = { filtre: { fin: DateTime.now().plus({ days: 1 }).setZone('UTC').toFormat("dd/MM/yyyy") } }
    this.travailleurService.getCalendrierTravailleurService(dataObj).subscribe((data: any) => {
      this.dataCalendrier = data
      var currentDate = DateTime.now().setZone('UTC').toFormat("yyyy-MM-dd")
      this.dataCalendrier.forEach(element => {
        var date_day = element.days.find(d => d.date == currentDate)
        element.present = date_day != undefined
        element.shift = date_day != undefined ? date_day.valeur == "J" ? "JOUR" : "NUIT" : ""
      })
      this.getDataTableauGlobal()
    })
  }

  getDataTableauGlobal() {
    this.calendrierService.getTableauGlobalService().subscribe((data: any) => {
      this.dataTableauGlobal = this.trierParTravailleurs(data[0])
      this.dataTableau = data[1]
      this.dataTableau_dynamique = [...this.dataTableau]
      this.dataTableau_temp = [...this.dataTableau]
      this.dataTableauGlobal.forEach(element => {
        if (element.travailleurs) {
          element.color = this.getRandomColor()
          element.travailleurs.sort((a, b) => new Date(b.date_prevue).getTime() - new Date(a.date_prevue).getTime())
          element.travailleurs.forEach(travailleur => {
            const currentDate = new Date();
            const datePrevue = new Date(travailleur.date_prevue);
            currentDate.setHours(0, 0, 0, 0);
            datePrevue.setHours(0, 0, 0, 0);
            travailleur.show = datePrevue >= currentDate;
          });
          element.travailleurs.forEach(worker => {
            worker.dataOrganigramme = worker.matricule_sup == null ? [] : this.findAllSupers(worker.matricule_sup, this.allTravailleurs)
            worker.nom_prenom_sup_1 = worker.dataOrganigramme.length > 0 ? worker.dataOrganigramme[0].nom_travailleur_cap + ", " + worker.dataOrganigramme[0].prenom_travailleur_cap : "--VIDE--"
            worker.nom_prenom_sup_2 = worker.dataOrganigramme.length > 3 ? worker.dataOrganigramme[1].nom_travailleur_cap + ", " + worker.dataOrganigramme[1].prenom_travailleur_cap : "--VIDE--"
            var dataPublic = this.dataCalendrier.find(d => d.id_travailleur == worker.id_travailleur_public)
            worker.present = dataPublic != undefined && dataPublic != null ? dataPublic.present : ""
            worker.shift = dataPublic != undefined && dataPublic != null ? dataPublic.shift : ""
            worker.passe_date = this.isDate1GreaterThanDate2(DateTime.now().setZone('UTC').toFormat("yyyy-MM-dd"), worker.date_prevue)
          });
        }
      });
      this.dataTableauGlobal_dynamique = JSON.parse(JSON.stringify(this.dataTableauGlobal))
      this.dataTableauGlobal_temp = JSON.parse(JSON.stringify(this.dataTableauGlobal))
      this.liste_formateur_global = [...new Set(this.dataTableauGlobal.flatMap(d => d.travailleurs.flatMap(t => t.nom_formateur)))].filter(t => t != "")
      this.liste_formateur_detaille = [...new Set(this.dataTableau.flatMap(d => d.nom_formateur))]
      this.is_loading = false
    })
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.2;
    return `rgba(${r},${g},${b},${a})`;
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.dataTableau_dynamique = key.includes("date") ? this.dataTableau_temp.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()) : this.dataTableau_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.dataTableau_dynamique = key.includes("date") ? this.dataTableau_temp.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()) : this.dataTableau_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.dataTableau_dynamique = [...this.dataTableau]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  deleteFormation(data: any, index: any, global: any = false) {
    var dataObj = { id_travailleur_formation: data[index].id_travailleur_formation, type: 1 }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer ce travailleur de cette formation ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.deleteTravailleurFormationService(dataObj).subscribe(() => {
          this.toast.Success("Travailleur supprimé du formation")
          if (global == true) {
            data.splice(index, 1)
          } else {
            this.dataTableau_dynamique.splice(index, 1)
          }
        })
      }
    });
  }

  exporterTableau() {
    this.is_downloading = true
    const current_date = DateTime.fromJSDate(new Date()).setZone('UTC').toFormat("yyyy-MM-dd")
    this.calendrierService.exportFormationService().subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${current_date} Tableau détaillé des formations`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }

  findAllSupers(matricule, allTravailleurs, result = [], visited = new Set()) {
    if (visited.has(matricule)) {
      return result;
    }
    visited.add(matricule);
    const travailleursSup = allTravailleurs.filter(t => t.matricule == matricule);
    if (travailleursSup.length > 0) {
      travailleursSup.forEach(travailleur => {
        result.push(travailleur);
        if (travailleur.matricule_sup_immediat != null && travailleur.matricule_sup_immediat != "") {
          this.findAllSupers(travailleur.matricule_sup_immediat, allTravailleurs, result, visited);
        }
      });
    }
    return result;
  }

  isDate1GreaterThanDate2(date1: string | Date, date2: string | Date): boolean {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);

    if (isNaN(parsedDate1.getTime()) || isNaN(parsedDate2.getTime())) {
      throw new Error('Les dates fournies ne sont pas valides');
    }

    return parsedDate1 > parsedDate2;
  }

  applyFilters() {
    this.dataTableauGlobal_temp = JSON.parse(JSON.stringify(this.dataTableauGlobal));
    if (this.currentStatus !== "0") {
      this.dataTableauGlobal_temp.forEach(element => {
        switch (this.currentStatus) {
          case "1":
            element.travailleurs = element.travailleurs.filter(t => t.present == false);
            break;
          case "2":
            element.travailleurs = element.travailleurs.filter(t => t.present == true && t.shift == "JOUR");
            break;
          case "3":
            element.travailleurs = element.travailleurs.filter(t => t.present == true && t.shift == "NUIT");
            break;
        }
      });
    }
    if (this.currentFormateur !== "") {
      this.dataTableauGlobal_temp.forEach(element => {
        element.travailleurs = element.travailleurs.filter(t => t.nom_formateur == this.currentFormateur);
      });
    }
    this.dataTableauGlobal_dynamique = JSON.parse(JSON.stringify(this.dataTableauGlobal_temp));
  }

  filtreByStatus(etat: string) {
    this.currentStatus = etat;
    this.applyFilters();
  }

  filterByFormateur(formateur: string) {
    this.currentFormateur = formateur;
    this.applyFilters();
  }
}
