<div class="p-2">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#journal-login">Connexion</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#journal-tableau">Tableau global des travailleurs</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#calendrier-formation">Calendrier des formations</a>
        </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane fade show active" id="journal-login">
            <div class="mt-3" style="overflow: auto; max-height: 85vh;">
                <app-load-page *ngIf="is_loading_loginLog"></app-load-page>
                <div *ngIf="!is_loading_loginLog">
                    <table class="table table-sm table-bordered table-striped">
                        <thead style="position: sticky; top:-1px;">
                            <tr>
                                <th>Operation</th>
                                <th>Date de connexion</th>
                                <th>Nom & Prénom</th>
                                <th>Rôle</th>
                                <th>Nom d'utilisateur</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let log of historique_login_dynamique; let i = index">
                                <td>{{log.operation}}</td>
                                <td>{{log.timestamp | date: "yyyy-MM-dd HH:mm:ss"}}</td>
                                <td>{{log.nom_user}}, {{log.prenom_user}}</td>
                                <td>{{log.libelle_role}}</td>
                                <td>{{log.user}}</td>
                                <td>{{log.email}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="journal-tableau">
            <div class="mt-4">
                <app-load-page *ngIf="is_loading_tableauGlobal"></app-load-page>
                <div *ngIf="!is_loading_tableauGlobal">
                    <div class="d-flex flex-row align-items-center">
                        <div>
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text" id="basic-addon1">Date début</span>
                                <input type="date" [(ngModel)]="date_debut" class="form-control" placeholder="Username"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="ms-2">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text" id="basic-addon1">Date fin</span>
                                <input type="date" [(ngModel)]="date_fin" [min]="date_debut"
                                    (change)="getHistoriqueTravailleur()" class="form-control" placeholder="Username"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="spinner-border ms-3 mb-3" role="status" *ngIf="is_loading_onChange">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="mb-3 ms-3" *ngIf="!is_loading_onChange">
                            <span class="text-secondary" style="font-size: 11px;">Sélection d'une plage de date (par défaut: {{current_year}})</span>
                        </div>
                    </div>
                    <div style="overflow: auto; max-height: 79vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead style="position: sticky; top:-1px;">
                                <tr>
                                    <th (click)="trieParKey('operation_type')">Opération <i [class]="icon_trie" *ngIf="affiche_icon.operation_type"></i> </th>
                                    <th (click)="trieParKey('timestamp')">Date opération <i [class]="icon_trie" *ngIf="affiche_icon.timestamp"></i> </th>
                                    <th (click)="trieParKey('modificateur')">Opérateur <i [class]="icon_trie" *ngIf="affiche_icon.modificateur"></i> </th>
                                    <th (click)="trieParKey('libelle_role')">Rôle <i [class]="icon_trie" *ngIf="affiche_icon.libelle_role"></i> </th>
                                    <th style="background-color: #f4eccd;" (click)="trieParKey('matricule')">Matricule <i [class]="icon_trie" *ngIf="affiche_icon.matricule"></i> </th>
                                    <th style="background-color: #f4eccd;" (click)="trieParKey('nom_prenom')">Travailleur <i [class]="icon_trie" *ngIf="affiche_icon.nom_prenom"></i> </th>
                                    <th style="background-color: #f4eccd;" (click)="trieParKey('colonne_modifie')">Colonne modifiée <i [class]="icon_trie" *ngIf="affiche_icon.colonne_modifie"></i> </th>
                                    <th style="background-color: #f4eccd;">Old Value</th>
                                    <th style="background-color: #f4eccd;">New Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of historique_tableau_global_dynamique; let i = index">
                                    <td>{{log.operation_type_fr}}</td>
                                    <td>{{log.timestamp | date: "yyyy-MM-dd HH:mm:ss"}}</td>
                                    <td>{{log.modificateur}}</td>
                                    <td>{{log.libelle_role}}</td>
                                    <td>{{log.matricule}}</td>
                                    <td>{{log.nom_prenom}}</td>
                                    <td style="background-color: #eff5f7;">{{log.colonne_modifie.toUpperCase()}}</td>
                                    <td>
                                        <span style="font-size: 13px;">{{getLibelleFromId(log.colonne_modifie,
                                            log.old_value)}}</span>
                                    </td>
                                    <td>
                                        <span style="font-size: 13px;">{{getLibelleFromId(log.colonne_modifie,
                                            log.new_value)}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="calendrier-formation">
            <div class="mt-4">
                <app-load-page *ngIf="is_loading_calendrier"></app-load-page>
                <div *ngIf="!is_loading_calendrier">
                    <div class="d-flex flex-row align-items-center">
                        <div>
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text" id="basic-addon1">Date début</span>
                                <input type="date" [(ngModel)]="date_debut_calendrier" class="form-control" placeholder="Username"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="ms-2">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text" id="basic-addon1">Date fin</span>
                                <input type="date" [(ngModel)]="date_fin_calendrier" [min]="date_debut_calendrier"
                                    (change)="getHistoriqueCalendrier()" class="form-control" placeholder="Username"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="spinner-border ms-3 mb-3" role="status" *ngIf="is_loading_onChange">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="mb-3 ms-3" *ngIf="!is_loading_onChange">
                            <span class="text-secondary" style="font-size: 11px;">Sélection d'une plage de date (par défaut: {{current_year}})</span>
                        </div>
                    </div>
                    <div style="overflow: auto; max-height: 79vh;">
                        <table class="table table-sm table-bordered table-striped">
                            <thead style="position: sticky; top: -1px;">
                                <tr>
                                    <th>Opération</th>
                                    <th>Date opération</th>
                                    <th>Opérateur</th>
                                    <th>Rôle</th>
                                    <th style="background-color: #f4eccd;">Matricule</th>
                                    <th style="background-color: #f4eccd;">Travailleur</th>
                                    <th style="background-color: #f4eccd;">Formation</th>
                                    <th style="background-color: #f4eccd;">Formateur</th>
                                    <th style="background-color: #f4eccd;">Date formation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let log of historique_calendrier_dynamique; let i = index">
                                    <td>{{log.operation}}</td>
                                    <td>{{log.timestamp | date:"yyyy-MM-dd HH:mm:ss"}}</td>
                                    <td>{{log.modificateur}}</td>
                                    <td>{{log.libelle_role}}</td>
                                    <td>{{log.matricule}}</td>
                                    <td>{{log.nom_prenom}}</td>
                                    <td>{{log.nom_formation}}</td>
                                    <td>{{log.nom_formateur}}</td>
                                    <td>{{log.date_prevue | date:"yyyy-MM-dd"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>