import { Component, OnInit } from '@angular/core';
import { TravailleurService } from '../services/travailleur/travailleur.service';
import { DateTime } from 'luxon';
import * as go from 'gojs';
import { ToastService } from '../services/toast/toast.service';
const $ = go.GraphObject.make;

@Component({
  selector: 'app-planification',
  templateUrl: './planification.component.html',
  styleUrls: ['./planification.component.css']
})
export class PlanificationComponent implements OnInit {

  constructor(
    private travailleurService: TravailleurService,
    private toast: ToastService
  ) {
    this.makeData()
    this.getTravailleurs()
  }

  all_travailleur: any = []
  is_verifing: any = false
  profil_travailleur: any = {}
  dataOrganigramme: any = []

  public selectedNode = null;
  public model: go.TreeModel
  public setSelectedNode(node) {
    this.selectedNode = node;
  }
  public diagram: go.Diagram = null;
  mois: any = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aôut", "Septembre", "Octobre", "Novembre", "Décembre"]
  dateData: any = [{}]
  dateEnd: any
  travailleur_calendrier: any = []
  current_year: any = DateTime.now().year
  current_month: any = DateTime.now().month
  loading_calendrier: any = false
  loading_data: any = false
  loading_organigramme: any = false
  loading_data_travailleur: any = false

  travailleur_search: any = ""
  filteredTravailleurs: any = [];

  ngOnInit(): void {
  }

  getTravailleurs() {
    this.loading_data_travailleur = true
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.all_travailleur = data
      this.all_travailleur.forEach((worker, index) => {
        worker.unique_search = worker.nom_travailleur_cap + ", " + worker.prenom_travailleur_cap + " (" + worker.matricule + ")"
      })
      this.all_travailleur.sort((a,b) => a.unique_search.toString().localeCompare(b.unique_search.toString()))
      this.filteredTravailleurs = this.all_travailleur
      this.loading_data_travailleur = false
    })
  }

  filterWorkers() {
    const searchTerm = this.travailleur_search.toLowerCase();
    if (searchTerm) {
      this.filteredTravailleurs = this.all_travailleur.filter(worker =>
        worker.unique_search.toLowerCase().startsWith(searchTerm)
      );
    } else {
      this.filteredTravailleurs = this.all_travailleur;
    }
  }

  initCap(value: string): string {
    if (!value) return value;
    return value
      .split(' ')
      .map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  }

  resetFormSearch() {
    this.is_verifing = false
    this.travailleur_search = ""
    this.profil_travailleur = {}
    this.dataOrganigramme = []
    this.disposeDiagram()
    this.travailleur_calendrier = []
    this.filteredTravailleurs = this.all_travailleur;
  }

  afficheData() {
    // this.resetFormSearch()
    this.is_verifing = true
    if (this.travailleur_search == "") return
    var data_travailleur = this.all_travailleur.find(t => t.unique_search == this.travailleur_search)
    // console.log(data_travailleur)
    // return
    if (data_travailleur == undefined) {
      this.toast.Warning("Travailleur introuvable")
      return
    }
    this.loading_data = true
    this.loading_organigramme = true
    this.loading_calendrier = true
    this.profil_travailleur = {
      nom_prenom: data_travailleur.nom_travailleur_cap + ", " + data_travailleur.prenom_travailleur_cap,
      sexe: data_travailleur.libelle_sexe,
      date_naissance: DateTime.fromISO(data_travailleur.date_naissance).setZone('UTC').toFormat("yyyy-MM-dd"),
      matricule: data_travailleur.matricule,
      id_personne: data_travailleur.id_personne,
      statut: data_travailleur.statut_employe,
      date_debut: DateTime.fromISO(data_travailleur.date_emploi).setZone('UTC').toFormat("yyyy-MM-dd"),
      date_fin: DateTime.fromISO(data_travailleur.date_fin_emploi).setZone('UTC').toFormat("yyyy-MM-dd"),
      type_emploi: data_travailleur.type_emploi,
      categorie: data_travailleur.cat_emploi,
      poste: data_travailleur.id_poste + " - " + data_travailleur.nom_fonction,
      emploi: data_travailleur.id_emp + " - " + data_travailleur.nom_emploi,
      horaire: data_travailleur.horaire,
      equipe: data_travailleur.equipes[0].nom_equipe,
      departement: data_travailleur.code_organisation + " - " + data_travailleur.nom_departement,
      sous_departement: data_travailleur.nom_sous_departement,
      sup_immediat: data_travailleur.matricule_sup_immediat + " - " + data_travailleur.prenom_sup_immediat + " " + data_travailleur.nom_sup_immediat,
      superviseur: data_travailleur.matricule_superviseur + " - " + data_travailleur.prenom_superviseur + " " + data_travailleur.nom_superviseur,
      entrepreneur: data_travailleur.nom_entrepreneur,
      sauveteur: data_travailleur.sauveteur ? "OUI" : "NON",
      pompier: data_travailleur.pompier ? "OUI" : "NON",
      realite: data_travailleur.realite ? "OUI" : "NON"
    }
    this.loading_data = false
    this.dataOrganigramme = this.findAllSupers(data_travailleur.matricule_sup_immediat, this.all_travailleur);
    let directReports = this.all_travailleur.filter(t => t.matricule_sup_immediat == data_travailleur.matricule_sup_immediat);
    this.dataOrganigramme = [...this.dataOrganigramme, ...directReports];
    this.dataOrganigramme.forEach(worker => {
      worker.key = worker.id_travailleur;
      if (worker.id_sup_immediat != null) {
        worker.parent = worker.id_sup_immediat;
      }
      worker.sup_niveau = worker.sup_niveau == null ? "0" : worker.sup_niveau
      worker.nom_sous_departement = this.initCap(worker.nom_sous_departement)
      worker.nom_emploi = this.initCap(worker.nom_emploi)
    });
    this.model = new go.TreeModel(this.dataOrganigramme)
    this.disposeDiagram()
    this.setOrganigramme(this.model)
    this.getDataCalendrier(data_travailleur)
  }

  getDataCalendrier(info: any) {
    var dataObj = { filtre: { fin: DateTime.fromISO(this.dateEnd).setZone('UTC').toFormat("dd/MM/yyyy") } }
    this.travailleurService.getCalendrierTravailleurService(dataObj).subscribe((data: any) => {
      this.travailleur_calendrier = data.filter(d => d.id_travailleur == info.id_travailleur)
      var jours_to_affcher = this.dateData.flatMap(a => a.months.flatMap(m => m.jours))
      this.travailleur_calendrier.forEach((worker, index) => {
        for (let index_day = worker.days.length - 1; index_day >= 0; index_day--) {
          var day = worker.days[index_day];
          var day_date = DateTime.fromISO(day.date);
          var day_year = day_date.year;
          var day_month = day_date.month;      
          if (day_year < this.current_year || (day_year === this.current_year && day_month < this.current_month)) {
            worker.days.splice(index_day, 1);
          }
        }
        jours_to_affcher.forEach(j => {
          const exists = worker.days.some(day => day.date == j.date)
          if (!exists) {
            worker.days.push({ date: j.date, valeur: '' })
          }
        })
        worker.days = worker.days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      })
      this.loading_calendrier = false
    })
  }

  findAllSupers(matricule, allTravailleurs, result = [], visited = new Set()) {
    if (visited.has(matricule)) {
      return result;
    }
    visited.add(matricule);
    const travailleursSup = allTravailleurs.filter(t => t.matricule == matricule);
    if (travailleursSup.length > 0) {
      travailleursSup.forEach(travailleur => {
        result.push(travailleur);
        if (travailleur.matricule_sup_immediat != null && travailleur.matricule_sup_immediat != "") {
          this.findAllSupers(travailleur.matricule_sup_immediat, allTravailleurs, result, visited);
        }
      });
    }
    return result;
  }

  setOrganigramme(model: any) {
    this.diagram = $(go.Diagram, 'myDiagramDiv', {
      layout: $(go.TreeLayout, {
        isOngoing: true,
        treeStyle: go.TreeLayout.StyleLastParents,
        arrangement: go.TreeLayout.ArrangementHorizontal,
        // arrangement: go.TreeLayout.ArrangementVertical,
        angle: 90,
        // angle: 0,
        layerSpacing: 50,
        nodeSpacing: 100,
        alternateAngle: 90,
        // alternateAngle: 0,
        alternateLayerSpacing: 35,
        alternateAlignment: go.TreeLayout.AlignmentBusBranching,
        alternateNodeSpacing: 20
      }),
      'undoManager.isEnabled': true,

      allowZoom: true,
      'toolManager.mouseWheelBehavior': go.ToolManager.WheelZoom,
      'maxScale': 3.5,
      'minScale': 0.4,
    });

    this.diagram.linkTemplate =
      new go.Link({ routing: go.Routing.Orthogonal, corner: 5 })
        .add(new go.Shape({ strokeWidth: 3, stroke: "#555" }));

    this.diagram.nodeTemplate =
      $(go.Node, 'Auto',
        new go.Binding('text', 'nom_travailleur'),
        new go.Binding('layerName', 'isSelected', function (sel) { return sel ? 'Foreground' : ''; }).ofObject(),
        $(go.Shape, 'RoundedRectangle', {
          name: 'SHAPE', fill: 'lightblue', stroke: null,
          portId: '', fromLinkable: true, toLinkable: true, cursor: 'pointer'
        },
          new go.Binding('fill', '', function (node) {
            const levelColors = ['#AC193D', '#2672EC', '#8C0095', '#5133AB', '#008299', '#8a8363', '#787878', '#094AB2'];
            let color = node.findObject('SHAPE').fill;
            const dia: go.Diagram = node.diagram;
            if (dia && dia.layout.network) {
              dia.layout.network.vertexes.each(function (v: go.TreeVertex) {
                if (v.node && v.node.key === node.data.key) {
                  const level: number = v.level % (levelColors.length);
                  color = levelColors[level];
                }
              });
            }
            return color;
          }).ofObject()
        ),
        $(go.Panel, 'Horizontal',
          $(go.Panel, 'Table', {
            maxSize: new go.Size(300, 999),
            margin: new go.Margin(5, 5, 5, 5),
            defaultAlignment: go.Spot.Left
          },

            // NOM PRENOM
            $(go.RowColumnDefinition, { column: 2, width: 4 }),
            $(go.TextBlock, { stroke: 'white' }, {
              row: 0, column: 0, columnSpan: 5,
              font: '11pt Poppins,sans-serif',
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 16),
              alignment: go.Spot.Center
            }, new go.Binding('text', '', function (data) {
              return data.prenom_travailleur_cap + ' ' + data.nom_travailleur_cap;
            }).makeTwoWay()),

            // SOUS DEPARTEMENT
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 1, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'nom_sous_departement', function (sous_departement) {
              return sous_departement && sous_departement.trim() != "" ? sous_departement : "-- VIDE --"
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) >= 1 && parseInt(niveau) <= 3;
              })
            ),

            // NOM EMPLOI
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 2, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'nom_emploi', function (cat_emploi) {
              return cat_emploi && cat_emploi.trim() != '' ? cat_emploi : '-- VIDE --';
            }).makeTwoWay()),

            // HORAIRE
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 3, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'horaire', function (horaire) {
              return horaire && horaire.trim() != '' ? horaire : '-- VIDE --'
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) < 3;
              })
            ),

            // EQUIPE
            $(go.TextBlock, { font: '8pt Poppins,sans-serif', stroke: 'white' }, {
              row: 4, column: 1, columnSpan: 4,
              editable: true, isMultiline: false,
              minSize: new go.Size(10, 14),
              margin: new go.Margin(0, 0, 0, 0),
              alignment: go.Spot.Center
            }, new go.Binding('text', 'nom_equipe', function (nom_equipe) {
              return nom_equipe && nom_equipe.trim() != '' ? nom_equipe : '-- VIDE --';
            }).makeTwoWay(),
              new go.Binding('visible', 'sup_niveau', function (niveau) {
                return parseInt(niveau) < 3;
              })
            ),
          )
        )
      );
    this.diagram.model = model;
    this.loading_organigramme = false
  }

  clearDiagram() {
    if (this.diagram) {
      this.diagram.clear();
    }
  }

  disposeDiagram() {
    if (this.diagram) {
      this.diagram.div = null;
      this.diagram = null;
      this.clearDiagram()
    }
  }

  zoomToFit() {
    if (this.diagram) {
      this.diagram.commandHandler.zoomToFit();
    }
  }

  zoomIn() {
    const currentScale = this.diagram.scale;
    this.diagram.scale = currentScale * 1.2;
  }

  zoomOut() {
    const currentScale = this.diagram.scale;
    this.diagram.scale = currentScale / 1.2;
  }

  makeData() {
    var currentDate = DateTime.now().setZone('UTC')
    var mois_liste = []
    var totalNumberDays = 0
    for (var i = 0; i < 4; i++) {
      var moisIndex = (currentDate.month + i) % 12;
      var annee = currentDate.year + Math.floor((currentDate.month + i) / 13);
      moisIndex = moisIndex === 0 ? 12 : moisIndex;
      var mois = this.mois[moisIndex - 1];
      mois_liste.push({ annee: annee, mois: mois, nombre_jour: this.getDaysInMonth(annee, moisIndex), jours: this.getJoursMois(annee, moisIndex), color: this.getRandomColor() })
      totalNumberDays += this.getDaysInMonth(annee, moisIndex)
    }
    this.dateData.forEach(d => {
      d.days = totalNumberDays
      d.months = mois_liste
    })
    this.dateEnd = this.dateData.flatMap(d => d.months.flatMap(m => m.jours))[this.dateData[0].days - 1].date
  }

  getDaysInMonth(year: number, month: number): number {
    const startOfMonth = DateTime.fromObject({ year: year, month: month, day: 1 });
    const endOfMonth = startOfMonth.endOf('month');
    const daysInMonth = endOfMonth.day;
    return daysInMonth;
  }

  getJoursMois(year: number, month: number) {
    const joursSemaine = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    let jours = [];
    const numJours = this.getDaysInMonth(year, month);
    for (let i = 1; i <= numJours; i++) {
      const jour = DateTime.fromObject({ year: year, month: month, day: i });
      const libelle = joursSemaine[jour.weekday - 1];
      const dateIso = jour.toISODate();
      jours.push({ num: i, libelle: libelle, date: dateIso });
    }
    return jours;
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }

  getDataFromTravailleur(date: any, index: any) {
    var result = this.travailleur_calendrier[index].days.filter(i => i.date == date)
    var valeur = ""
    if (result.length != 0) {
      valeur = result[0].valeur
    }
    return valeur
  }

}
