import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { CryptageService } from '../cryptage/cryptage.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private user:any
  
  constructor(private cookies:CookieService, private cryptage:CryptageService) {
    this.getUser()
  }

  getUser(){
    this.user = JSON.parse(this.cryptage.decryptDecode(this.cookies.get("data_user")))
  }

  isAuthenticated(): boolean {
    return !!this.user;
  }

  public getUserRole(): string {
    return this.user?.role;
  }

  hasRole(role: string): boolean {
    return this.getUserRole() === role;
  }
  
}
