import { Component, OnInit } from '@angular/core';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { UsersService } from 'src/app/services/users/users.service';
import {DateTime} from 'luxon'
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';

@Component({
  selector: 'app-historique-journal',
  templateUrl: './historique-journal.component.html',
  styleUrls: ['./historique-journal.component.css']
})
export class HistoriqueJournalComponent implements OnInit {

  constructor(
    private travailleurService: TravailleurService,
    private userService: UsersService,
    private calendrierService: CalendrierService
  ) {
    this.getLoginLog()
    this.getDataDropDown()
    this.getHistoriqueCalendrier()
  }

  current_year:any = DateTime.now().year
  date_debut:any = this.current_year + "-01-01"
  date_fin:any = this.current_year + "-12-31"

  date_debut_calendrier:any = this.current_year + "-01-01"
  date_fin_calendrier:any = this.current_year + "-12-31"

  historique_tableau_global: any = []
  historique_tableau_global_dynamique: any = []
  historique_tableau_global_temp: any = []

  historique_login: any = []
  historique_login_dynamique: any = []
  historique_login_temp: any = []

  historique_calendrier: any = []
  historique_calendrier_dynamique: any = []
  historique_calendrier_temp: any = []

  sous_departement_liste: any
  statut_employe_liste: any
  departement_liste: any
  fonction_liste: any
  sexe_liste: any
  entrepreneur_liste: any
  type_emploi_liste: any
  cat_emploi_liste: any
  equipe_liste: any
  emploi_liste: any
  horaire_liste: any
  travailleur_liste: any

  is_loading_loginLog:any = false
  is_loading_tableauGlobal:any = false
  is_loading_onChange:any = false
  is_loading_calendrier:any = false

  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    operation_type: false,
    timestamp: false,
    modificateur: false,
    libelle_role: false,
    matricule: false,
    nom_prenom: false,
    colonne_modifie:false
  }

  ngOnInit(): void {
  }

  initCap(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  getHistoriqueTravailleur() {
    this.is_loading_onChange = true
    var dataObj = { from: this.date_debut + " 00:00:00", to: this.date_fin + " 23:59:59"}
    this.travailleurService.getHistoriqueService(dataObj).subscribe((data: any) => {
      this.historique_tableau_global = data
      this.historique_tableau_global.forEach((element, index) => {
        element.libelle_role = this.getLibelleRole(element.role)
        element.nom_prenom = element.nom_travailleur_cap + ", " + element.prenom_travailleur_cap
        element.keys = element.modification != null ? Object.keys(element.modification) : []
        element.colonne_modifie = element.keys.length > 0 ? element.keys[0] : ""
        element.old_value = element.keys.length > 0 ? element.modification[element.keys[0]][0] : ""
        element.new_value = element.keys.length > 0 ? element.modification[element.keys[0]][1] : ""
        element.operation_type_fr = element.operation_type == "INSERT" ? "INSERTION" : "MODIFICATION"
      })
      // console.log(this.historique_tableau_global)
      this.historique_tableau_global_dynamique = [...this.historique_tableau_global]
      this.historique_tableau_global_temp = [...this.historique_tableau_global]
      this.is_loading_tableauGlobal = false
      this.is_loading_onChange = false
    })
  }

  getLoginLog() {
    this.is_loading_loginLog = true
    this.userService.getLoginLogService().subscribe((data: any) => {
      this.historique_login = data
      this.historique_login.forEach(element => {
        element.libelle_role = this.getLibelleRole(element.role)
        element.operation = element.operation.toUpperCase()
      });
      this.historique_login_dynamique = [...this.historique_login]
      this.historique_login_temp = [...this.historique_login]
      this.is_loading_loginLog = false
    })
  }

  getTravailleur() {
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.travailleur_liste = data
      this.getHistoriqueTravailleur()
    })
  }

  getDataDropDown() {
    this.is_loading_tableauGlobal = true
    var dataObj = {}
    this.travailleurService.getDataDropDownService(dataObj).subscribe((data: any) => {
      this.sous_departement_liste = data[0]
      this.statut_employe_liste = data[1]
      this.departement_liste = data[2]
      this.fonction_liste = data[3]
      this.sexe_liste = data[4]
      this.entrepreneur_liste = data[5]
      this.type_emploi_liste = data[6]
      this.cat_emploi_liste = data[7]
      this.equipe_liste = data[8]
      this.emploi_liste = data[9]
      this.horaire_liste = data[10]
      this.getTravailleur()
    })
  }

  getLibelleFromId(table: any, id: any) {
    let libelle = ""
    if (id != null) {
      switch (table) {
        case "id_sous_departement":
          libelle = this.initCap(this.sous_departement_liste.find(s => s[table] == id).nom_sous_departement)
          break
        case "id_statut_employe":
          libelle = this.initCap(this.statut_employe_liste.find(s => s[table] == id).libelle_statut)
          break
        case "id_departement":
          libelle = this.departement_liste.find(s => s[table] == id).code_organisation + " - " + this.initCap(this.departement_liste.find(s => s[table] == id).nom_departement)
          break
        case "id_fonction":
          libelle = this.fonction_liste.find(s => s[table] == id).id_poste + " - " + this.initCap(this.fonction_liste.find(s => s[table] == id).nom_fonction)
          break
        case "id_sexe":
          libelle = this.initCap(this.sexe_liste.find(s => s[table] == id).libelle_sexe)
          break
        case "id_entrepreneur":
          libelle = this.entrepreneur_liste.find(s => s[table] == id).nom_entrepreneur
          break
        case "id_type_emploi":
          libelle = this.type_emploi_liste.find(s => s[table] == id).libelle_type_emploi
          break
        case "id_cat_emploi":
          libelle = this.cat_emploi_liste.find(s => s[table] == id).libelle_cat_emploi
          break
        case "id_equipe":
          libelle = this.equipe_liste.find(s => s[table] == id).nom_equipe
          break
        case "id_emploi":
          libelle = this.emploi_liste.find(s => s[table] == id).id_emp + " - " + this.initCap(this.emploi_liste.find(s => s[table] == id).nom_emploi)
          break
        case "id_sup_immediat":
          libelle = this.travailleur_liste.find(s => s.id_travailleur == id).nom_travailleur_cap + ", " + this.travailleur_liste.find(s => s.id_travailleur == id).prenom_travailleur_cap
          break
        case "id_superviseur":
          libelle = this.travailleur_liste.find(s => s.id_travailleur == id).nom_travailleur_cap + ", " + this.travailleur_liste.find(s => s.id_travailleur == id).prenom_travailleur_cap
          break
        case "id_horaire":
          libelle = this.horaire_liste.find(s => s[table] == id).libelle_horaire
          break
        case "pompier":
        case "sauveteur":
        case "realie":
          libelle = id ? "OUI" : "NON"
          break
        default:
          libelle = this.initCap(id)
          break
      }
    }
    return libelle
  }

  getLibelleRole(role: any) {
    let libelle = ""
    switch (role) {
      case 4:
        libelle = "Viewer"
        break
      case 6:
        libelle = "Superviseur"
        break
      case 7:
        libelle = "Agent de bureau"
        break
      case 8:
        libelle = "Preventioniste"
        break
      case 9:
        libelle = "Formateur"
        break
      case 10:
      case 99:
        libelle = "Administrateur"
        break
    }
    return libelle
  }

  trieParKey(key: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    if (this.type_switch == 1) {
      this.historique_tableau_global_dynamique = key.includes("timestamp") ? this.historique_tableau_global_temp.sort((a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()) : this.historique_tableau_global_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.historique_tableau_global_dynamique = key.includes("timestamp") ? this.historique_tableau_global_temp.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime()) : this.historique_tableau_global_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.historique_tableau_global_dynamique = [...this.historique_tableau_global]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  getHistoriqueCalendrier(){
    var dataObj = {from:this.date_debut_calendrier + " 00:00:00", to:this.date_fin_calendrier + " 23:59:59"}
    this.is_loading_calendrier = true
    this.is_loading_onChange = true
    this.historique_calendrier = []
    this.calendrierService.getHistoriqueCalendrierService(dataObj).subscribe((data:any) => {
      const date_unique = [...new Set(data.map(d => d.timestamp_text))]
      date_unique.forEach(d => {
        this.historique_calendrier.push(data.find(dt => dt.timestamp_text == d))
      })
      this.historique_calendrier.forEach(hc => {
        hc.libelle_role = this.getLibelleRole(hc.role)
        hc.nom_prenom = hc.nom_travailleur + ", " + hc.prenom_travailleur
      })
      this.historique_calendrier_dynamique = [...this.historique_calendrier.filter(h => h.operation != "MODIFICATION")]
      this.historique_calendrier_temp = [...this.historique_calendrier.filter(h => h.operation != "MODIFICATION")]
      this.is_loading_calendrier = false
      this.is_loading_onChange = false
    })
  }
}
