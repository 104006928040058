<div class="px-3 py-2">
    <table class="table table-hover table-bordered table-sm" style="vertical-align: middle;">
        <thead>
            <tr>
                <th style="width: 7%;" class="text-center">N° Formation</th>
                <th style="width: 8%;" class="text-center">Date prévue</th>
                <th style="width: 22%;">Formations</th>
                <th style="width: 13%;">Formateurs</th>
                <th style="width: 50%;">Travailleurs</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataTableau; let i = index">
                <td class="px-2 text-center"> {{item.num_formation}} </td>
                <td class="px-2 text-center"> 
                    <span> {{item.date_prevue | date: "yyyy-MM-dd"}} </span>
                    <div *ngIf="item.statut_formation.includes('progression')" class="rounded primary">{{item.statut_formation | capitalizeFirstLetter}}</div>
                    <div *ngIf="item.statut_formation.includes('autorisé')" class="rounded success">{{item.statut_formation | capitalizeFirstLetter}}</div>
                    <div *ngIf="item.statut_formation.includes('attente')" class="rounded secondary">{{'en attente'| capitalizeFirstLetter}}</div>
                    <div *ngIf="item.statut_formation.includes('refu')" class="text-white rounded danger">{{item.statut_formation | capitalizeFirstLetter}}</div>
                </td>
                <td class="px-2"> {{item.nom_formation}} </td>
                <td class="px-2">
                    <ng-container>
                        <ul>
                            <li *ngFor="let formateur of item.formateurs; let ifor = index">
                                <span class="text-primary" *ngIf="ifor != 0">•</span>
                                {{formateur}}
                            </li>
                        </ul>
                    </ng-container>
                </td>
                <td class="px-2">
                    <ng-container>
                        <ul>
                            <li *ngFor="let worker of item.travailleurs; let iw = index">
                                <span class="text-primary" *ngIf="iw != 0">•</span>
                                {{worker.nom_complet}}
                            </li>
                        </ul>
                    </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>