<app-load-page *ngIf="is_loading"></app-load-page>
<div style="overflow: auto; max-height: 91vh;" *ngIf="!is_loading" class="p-2">
    <div class="row d-flex" style="flex-wrap: nowrap; width: 100%;">
        <div class="col-2" *ngFor="let formateur of liste_formateur; let i = index"
            style="margin-right: -30px;">
            <div style="position:sticky; top: -8px;">
                <div class="text-center border rounded px-3 py-2" style="height: 85px; width: 90%;" [ngStyle]="{'background-color':formateur.color}">
                    <span style="font-size: 10px;">Nombre de formations assignées : {{formateur.formations[0] != null ? formateur.formations.length : 0}}</span> <br>
                    <span style="font-size: 13px;">{{ formateur.nom_formateur }}</span> <br>
                    <span style="font-size: 13px;" class="text-secondary">{{ formateur.statut == 1 ? "ACTIF" : "INACTIF" }}</span>
                </div>
            </div>
            <div *ngIf="formateur.formations.length > 0 && formateur.formations[0] != null">
                <ng-container *ngFor="let formation of formateur.formations; let i = index">
                    <div class="text-center mt-2 rounded p-1" id="nom-complet" style="width: 90%; background-color: #fff;">
                        <span style="font-size: 11px;"> <span style="font-weight: bold; font-size: 11px;">{{formationName(formation).num_formation}}</span> - {{formationName(formation).nom_formation}}</span> 
                        <i class="bi bi-trash text-danger" id="icon-trash" (click)="dropFormationFromFormateur(formateur.formations, i, formation, formateur.id_formateur)"></i>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>