<div class="p-1">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <div class="d-flex flex-row align-items-center">
            <h4>Liste des formations</h4>
        </div>
        <div class="d-flex flex-row align-items-center">
            <div class="spinner-border me-3" style="color: #707fdd;" role="status" *ngIf="is_loading">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button class="btn-special me-2" (click)="resetFilter()">Effacer les filtres</button>
            <button class="btn-special me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i
                class="bi bi-gear me-1" style="font-size: 13px;"></i> Colonnes</button>
            <button class="btn-special me-2" data-bs-toggle="modal" data-bs-target="#formationModal" (click)="resetFormFormation()"> <i class="bi bi-plus-lg me-1" style="font-size: 12px;"></i> Ajouter une formation</button>
            <button class="btn-special" (click)="exporterTableau()">
                <span class="spinner-border spinner-border-sm me-1" style="font-size: 12px;" aria-hidden="true" *ngIf="is_downloading"></span>
                <span *ngIf="!is_downloading"> <i class="bi bi-file-earmark-excel me-1" style="font-size: 12px;"></i></span>
                Exporter</button>
        </div>
    </div>
    <div style="height: 87vh; overflow: auto;" class="mt-2">
        <div #tableContainer></div>
    </div>
</div>

<!-- Modal Formulaire Formation -->
<div class="modal fade" id="formationModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h4>
                        <span *ngIf="!is_editing">Ajout d'une formation</span>
                        <span *ngIf="is_editing">Modification de formation</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-2">
                    <div class="row">
                        <div class="col-sm-12 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.nom_formation" [ngClass]="{'is-invalid':formFormation.nom_formation == '' && is_verifing}" type="text" class="form-control" id="nom_formation" placeholder="Password">
                                <label for="nom_formation">Nom formation *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.num_formation" [ngClass]="{'is-invalid':formFormation.num_formation == '' && is_verifing}" type="text" class="form-control" id="num_formation" placeholder="Password">
                                <label for="num_formation">N° Formation *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.categorie" [ngClass]="{'is-invalid':formFormation.categorie == '' && is_verifing}" class="form-select" id="categorie" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let categorie of liste_categorie" [value]="categorie.id_cat_formation">{{categorie.libelle | capitalizeFirstLetter}}</option>
                                </select>
                                <label for="categorie">Catégorie *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.interne_externe" [ngClass]="{'is-invalid':formFormation.interne_externe == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Interne</option>
                                    <option value="0">Externe</option>
                                </select>
                                <label for="resultat">Interne / Externe *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.theorie" type="number" [ngClass]="{'is-invalid':formFormation.theorie == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Heure théorie *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.pratique" type="number" [ngClass]="{'is-invalid':formFormation.pratique == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Heure pratique *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.refresh" type="number" [ngClass]="{'is-invalid':formFormation.refresh == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Refresh *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.examen" [ngClass]="{'is-invalid':formFormation.examen == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Examen requis *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.resultat" [ngClass]="{'is-invalid':formFormation.resultat == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let resultat of liste_resultat" [value]="resultat.id_type_formation">{{resultat.libelle_type | capitalizeFirstLetter}}</option>
                                </select>
                                <label for="resultat">Résultat *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.matrice" [ngClass]="{'is-invalid':formFormation.matrice == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Inclure matrice *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.suivi" [ngClass]="{'is-invalid':formFormation.suivi == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Suivi réquisition *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.superviseur" [ngClass]="{'is-invalid':formFormation.superviseur == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Accès superviseur *</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mt-2">
                                <span class="h6">Formateurs <span class="text-secondary" style="font-size: 12px;">({{formFormation.formateurs.length}})</span> </span>
                                <div class="row mt-2">
                                    <ng-container *ngFor="let formateur of formFormation.formateurs; let i = index">
                                        <div class="col-4 mb-2 d-flex flex-row align-items-center" id="nom-complet">
                                            <input type="text" class="form-control text-center" [value]="formateurName(formateur)" style="font-size: 13px;" placeholder="+ Nouveau" readonly>
                                            <i class="bi bi-trash text-danger" id="icon-trash" style="font-size: 12px;" (click)="dropFormateurFormation(formFormation, i)"></i>
                                        </div>
                                    </ng-container>
                                    <div class="col-4 mb-2">
                                        <input type="text" list="listeFormateur" [(ngModel)]="formateur_added" [value]="formateurName(formateur_added)" (change)="addFormateurToFormation(formateur_added, formFormation)" class="form-control text-center" style="font-size: 13px;" placeholder="+ Nouveau">
                                    </div>
                                    <datalist id="listeFormateur">
                                        <option *ngFor="let formateur of liste_formateur_dynamique" [value]="formateur.id_formateur">{{formateur.nom_formateur}}</option>
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <button class="btn-special-reverse" (click)="saveFormation()"> <i class="bi bi-save me-1" style="font-size: 12px;"></i> Enregistrer</button>
                        <button class="btn btn-sm btn-secondary ms-1" (click)="resetFormFormation()" *ngIf="!is_editing"> <i class="bi bi-arrow-clockwise me-1" style="font-size: 12px;"></i> Réinitialiser</button>
                        <button class="btn btn-sm btn-secondary ms-1" *ngIf="is_editing" data-bs-dismiss="modal"> <i class="bi bi-x-lg me-1" style="font-size: 12px;"></i> Annuler</button>
                        <span class="ms-3 text-secondary" style="font-size: 11px;">(*) Champs obligatoire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des colonnes</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-2">
                    <table class="table table-sm table-bordered table-hover" style="vertical-align: middle;">
                        <thead>
                            <tr>
                                <th style="width: 8%;">N°</th>
                                <th>Nom colonne</th>
                                <th style="width: 12%;">Afficher</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let colonne of headers; let i = index">
                                <ng-container *ngIf="i < 13 && i != 0">
                                    <td>{{i}}</td>
                                    <td>{{colonne.nom_colonne}}</td>
                                    <td style="text-align: center;">
                                        <div class="form-check" style="margin: auto;">
                                            <input class="form-check-input" type="checkbox" value=""
                                                (change)="hideShowColumn(i)" [(ngModel)]="colonne.afficher"
                                                id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault"></label>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>