import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  constructor(private cookie: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // const token = localStorage.getItem('token')
    // console.log('intercepted: ', token)
    if (this.cookie.check('auth')) {
      const token = this.cookie.get('auth')
      const clone = req.clone({
        setHeaders: { 'auth': token }
      })
      return next.handle(clone)
    }
    else {
      return next.handle(req)
    }
  }
}
