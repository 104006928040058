import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { AccueilComponent } from './accueil/accueil.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { GestionMenuComponent } from './gestion-menu/gestion-menu.component';
import { AppComponent } from './app.component';
import { CalendrierComponent } from './formation/calendrier/calendrier.component';
import { AgendaComponent } from './formation/agenda/agenda.component';
import { TableauFormationComponent } from './formation/tableau-formation/tableau-formation.component';
import { RessourceComponent } from './ressource/ressource.component';



const routes: Routes = [
  { path: '', component: CalendrierComponent , data: {titreComponent : 'Hecla Quebec Application'}},
  { path: 'accueil', component: AccueilComponent, data: {titreComponent : 'Accueil'}},
  { path: 'users', component: UtilisateurComponent, data: {titreComponent : 'Gestion d\'utilisateur'}},
  { path: 'gestion-menu', component: GestionMenuComponent, data: {titreComponent : 'Gestion de menu'}},
  { path: 'calendrier-formation', component: CalendrierComponent, data: {titreComponent : 'Calendrier de formation'}},
  { path: 'agenda-formation', component: AgendaComponent, data: {titreComponent : 'Agenda de formation'}},
  { path: 'tableau-formation', component: TableauFormationComponent, data: {titreComponent : 'Tableau des formations'}},
  { path: 'ressource', component: RessourceComponent, data: {titreComponent : 'Ressources'}},
  { path: '**', pathMatch: 'full', component: Page404Component, title: '404 Page'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


