import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalApiUrlService } from '../global-api-url.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TravailleurService {

  constructor(private http: HttpClient, private url: GlobalApiUrlService) { }

  getTravailleurService(data: any = null) {
    var API_URL = this.url.REST_API + '/get-travailleurs';
    return this.http.post(API_URL, data, httpOptions)
  }

  getOrganigrammeService(data:any = null){
    var API_URL = this.url.REST_API + '/get-organigramme';
    return this.http.post(API_URL, data, httpOptions)
  }

  getDataDropDownService(data:any = null){
    var API_URL = this.url.REST_API + '/get-data-dropdown';
    return this.http.post(API_URL, data, httpOptions)
  }

  updateTravailleurService(data:any = null){
    var API_URL = this.url.REST_API + '/update-travailleurs';
    return this.http.post(API_URL, data, httpOptions)
  }

  addTravailleurService(data:any = null){
    var API_URL = this.url.REST_API + '/add-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  exportTravailleurService(data:any = null){
    var API_URL = this.url.REST_API + '/export-travailleurs';
    return this.http.post(API_URL, data, {responseType: 'blob'})
  }

  getCalendrierTravailleurService(data:any = null){
    var API_URL = this.url.REST_API + '/calendrier-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  getStatutCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/get-statut-competence';
    return this.http.post(API_URL, data, httpOptions)
  }
  
  getFormationCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/get-formation-competence';
    return this.http.post(API_URL, data, httpOptions)
  }  

  traitementTypeFormationCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/traitement-type-formation-competence';
    return this.http.post(API_URL, data, httpOptions)
  }

  insertFormationCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/insert-formation-competence';
    return this.http.post(API_URL, data, httpOptions)
  }

  getTravailleurCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/get-data-formation-competence';
    return this.http.post(API_URL, data, httpOptions)
  }

  setCompetenceTravailleurService(data:any = null){
    var API_URL = this.url.REST_API + '/set-competence-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  insertTravailleurCompetence(data:any = null){
    var API_URL = this.url.REST_API + '/insert-competence-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  deleteTravailleurCompetence(data:any = null){
    var API_URL = this.url.REST_API + '/delete-competence-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  updateTravailleurCompetence(data:any = null){
    var API_URL = this.url.REST_API + '/update-competence-travailleur';
    return this.http.post(API_URL, data, httpOptions)
  }

  getOngletCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/get-onglet-competence';
    return this.http.post(API_URL, data, httpOptions)
  }

  traitementOngletCompetenceService(data:any = null){
    var API_URL = this.url.REST_API + '/traitement-onglet-competence';
    return this.http.post(API_URL, data, httpOptions)
  }

  uploadFileReferenceService(data:any = null){
    if(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      formData.append('id_departement', data.id_departement)
      var API_URL = this.url.REST_API + '/upload';
      return this.http.post(API_URL, formData)
    }
  }

  getFichierDepartementService(data:any = null){
    var API_URL = this.url.REST_API + '/get-fichier-departement';
    return this.http.post(API_URL, data, httpOptions)
  }

  deleteFichierDepartementService(data:any = null){
    var API_URL = this.url.REST_API + '/delete-fichier-departement';
    return this.http.post(API_URL, data, httpOptions)
  }

  downloadFileService(data:any = null){
    var API_URL = this.url.REST_API + '/download';
    return this.http.post(API_URL, data, {responseType: 'blob'})
  }
  
  getHistoriqueService(data:any = null){
    var API_URL = this.url.REST_API + '/get-historique';
    return this.http.post(API_URL, data, httpOptions)
  }  

  getFormationProcedure(data:any = null){
    var API_URL = this.url.REST_API + '/get-formation-procedure';
    return this.http.post(API_URL, data, httpOptions)
  }

  getOngletFormationProcedureService(data:any = null){
    var API_URL = this.url.REST_API + '/get-onglet-formation-procedure';
    return this.http.post(API_URL, data, httpOptions)
  }

  traitementOngletFormationProcedureService(data:any = null){
    var API_URL = this.url.REST_API + '/traitement-onglet-formation-procedure';
    return this.http.post(API_URL, data, httpOptions)
  }

  getSousDepartementProcedureService(data:any = null){
    var API_URL = this.url.REST_API + '/get-sous-departement-procedure';
    return this.http.post(API_URL, data, httpOptions)
  }

  traitementSousDepartementProcedureService(data:any = null){
    var API_URL = this.url.REST_API + '/traitement-sous-departement-procedure';
    return this.http.post(API_URL, data, httpOptions)
  }
}
