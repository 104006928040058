import { Component, OnInit } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-portrait-formateur',
  templateUrl: './portrait-formateur.component.html',
  styleUrls: ['./portrait-formateur.component.css']
})
export class PortraitFormateurComponent implements OnInit {

  constructor(
    private calendrierService: CalendrierService,
    private toast:ToastService
  ) {
    this.getListeFormation()
  }

  ngOnInit(): void {
  }

  liste_formateur: any = []
  liste_formation: any = []
  is_loading: any = false

  getListeFormation() {
    this.is_loading = true
    this.calendrierService.getListeFormationService().subscribe((data: any) => {
      this.liste_formation = data
      this.getListeFormateur()
    })
  }

  getListeFormateur() {
    this.calendrierService.getFormateurService().subscribe((data: any) => {
      this.liste_formateur = data.filter(d => d.statut == 1)
      this.is_loading = false
    })
  }

  formationName(id: any) {
    if (id != null && id != undefined && id != "") {
      return this.liste_formation.find(f => f.id_liste_formation == id)
    }
    return ""
  }

  dropFormationFromFormateur(row: any, index: any, id_formation: any, id_formateur: any) {
    var dataObj = { id_formation: id_formation, id_formateur: id_formateur }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment désaffecter cette formation de ce formateur ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, désaffectée",
      cancelButtonText: "Non, annulée",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.dropFormationFromFormateurService(dataObj).subscribe(() => {
          this.toast.Success("Opération terminée avec succès")
          row.splice(index, 1)
        })
      }
    });
  }
}
