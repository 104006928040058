import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CryptageService } from 'src/app/services/cryptage/cryptage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sauveteur',
  templateUrl: './sauveteur.component.html',
  styleUrls: ['./sauveteur.component.css']
})
export class SauveteurComponent implements OnInit {

  constructor(
    private travailleurService: TravailleurService,
    private toast: ToastService,
    private cookies: CookieService,
    private cryptageService: CryptageService
  ) {
    this.getTravailleurs()
  }

  ngOnInit(): void {
  }

  listeSauveteurMinier: any = []
  formAddSauveteurMinier: any = {
    nom_sauveteur_minier: "",
    prenom_sauveteur_minier: "",
    matricule: "",
    departement: "",
    telephone: "",
    courriel: ""
  }
  is_verify: any = false
  is_editing:any = false
  dataUser:any = {}
  is_admin:any = false
  is_loading:any = false
  is_not_superviseur_or_visitore:any = false
  travailleur_search:any = ""
  filteredTravailleurs:any = []
  travailleurs:any = []
  is_saving:any = false

  filterWorkers() {
    const searchTerm = this.travailleur_search.toLowerCase();
    if (searchTerm) {
      this.filteredTravailleurs = this.travailleurs.filter(worker =>
        worker.unique_search.toLowerCase().startsWith(searchTerm)
      );
    } else {
      this.filteredTravailleurs = this.travailleurs;
    }
  }

  selectingWorker() {
    if (this.travailleur_search != "") {
      var result = this.travailleurs.find(t => t.unique_search == this.travailleur_search)
      this.formAddSauveteurMinier.nom_sauveteur_minier = result.nom_travailleur_cap
      this.formAddSauveteurMinier.prenom_sauveteur_minier = result.prenom_travailleur_cap
      this.formAddSauveteurMinier.departement = result.nom_departement
      this.formAddSauveteurMinier.matricule = result.matricule
    } else {
      this.resetFormAdd()
    }
  }

  getTravailleurs() {
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.travailleurs = data
      this.travailleurs.forEach(worker => {
        worker.unique_search = worker.nom_travailleur_cap + ", " + worker.prenom_travailleur_cap + " (" + worker.matricule + ")"
      })
      this.filteredTravailleurs = [...this.travailleurs]
    })
    this.getSauveteurMinier()
  }

  verificationRole(){
    this.dataUser = JSON.parse(this.cryptageService.decryptDecode(this.cookies.get('data_user')));
    this.is_admin = [10,99].includes(this.dataUser.role)
    this.is_not_superviseur_or_visitore = ![6,4].includes(this.dataUser.role)
    this.is_loading = false
  }

  getSauveteurMinier() {
    this.is_loading = true
    this.travailleurService.getDatatablePreventionisteService({table:"sauveteur_minier"}).subscribe((data: any) => {
      this.listeSauveteurMinier = data
      this.listeSauveteurMinier.forEach(element => {
        element.nom_prenom = element.nom_sauveteur_minier + ", " + element.prenom_sauveteur_minier
      });
      this.verificationRole()
    })
  }

  resetFormAdd() {
    this.formAddSauveteurMinier = {
      nom_sauveteur_minier: "",
      prenom_sauveteur_minier: "",
      matricule: "",
      departement: "",
      telephone: "",
      courriel: ""
    }
    this.is_verify = false
    this.is_editing = false
  }

  saveSauveteurMinier() {
    this.is_verify = true
    if (this.formAddSauveteurMinier.prenom_sauveteur_minier == "" || this.formAddSauveteurMinier.nom_sauveteur_minier == "") return
    if (this.formAddSauveteurMinier.departement == "" || this.formAddSauveteurMinier.matricule == "") return
    var dataObj = {
      ...this.formAddSauveteurMinier,
      traitement: this.is_editing ? 3 : 1
    }
    this.is_saving = true
    this.travailleurService.traitementSauveteurMinierService(dataObj).subscribe((data:any) => {
      if(!this.is_editing) {
        this.toast.Success("Données enregistrées")
        this.listeSauveteurMinier.push({
          ...this.formAddSauveteurMinier,
          id_sauveteur_minier: data[0].id_sauveteur_minier,
          nom_prenom: this.formAddSauveteurMinier.nom_sauveteur_minier + ", " + this.formAddSauveteurMinier.prenom_sauveteur_minier
        })
        this.resetFormAdd()
      } else {
        this.toast.Success("Données modifiées")
        // this.is_editing = false
        var index = this.listeSauveteurMinier.findIndex(l => l.id_sauveteur_minier == this.formAddSauveteurMinier.id_sauveteur_minier)
        this.listeSauveteurMinier[index] = {...this.formAddSauveteurMinier, nom_prenom : this.formAddSauveteurMinier.nom_sauveteur_minier + ", " + this.formAddSauveteurMinier.prenom_sauveteur_minier}
      }
      this.is_saving = false
    })
  }

  deleteSauveteurMinier(id_sauveteur, index) {
    var dataObj = { id_sauveteur_minier: id_sauveteur, traitement: 2 }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette ligne?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        this.travailleurService.traitementSauveteurMinierService(dataObj).subscribe(() => {
          this.toast.Success("Données supprimées")
          this.listeSauveteurMinier.splice(index, 1)
          this.resetFormAdd()
        })
      }
    });
  }

  toEditSauvateurMinier(id_sauveteurMinier) {
    this.is_editing = true
    var row = this.listeSauveteurMinier.find(l => l.id_sauveteur_minier == id_sauveteurMinier);
    this.formAddSauveteurMinier = {...row}
  }  

}
