import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CryptageService } from 'src/app/services/cryptage/cryptage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hazmat',
  templateUrl: './hazmat.component.html',
  styleUrls: ['./hazmat.component.css']
})
export class HazmatComponent implements OnInit {

  constructor(
    private travailleurService: TravailleurService,
    private toast: ToastService,
    private cookies: CookieService,
    private cryptageService: CryptageService
  ) {
    this.getTravailleurs()
  }

  ngOnInit(): void {
  }

  is_admin: any = false
  is_loading: any = false
  dataUser: any = {}
  is_not_superviseur_or_visitore: any = false
  is_editing: any = false
  formAddSauveteurMinier: any = {
    nom_hazmat: "",
    prenom_hazmat: "",
    matricule: "",
    departement: ""
  }
  is_verify: any = false
  travailleur_search: any = ""
  filteredTravailleurs: any = []
  travailleurs: any = []
  is_saving: any = false
  liste_hazmat: any = []

  filterWorkers() {
    const searchTerm = this.travailleur_search.toLowerCase();
    if (searchTerm) {
      this.filteredTravailleurs = this.travailleurs.filter(worker =>
        worker.unique_search.toLowerCase().startsWith(searchTerm)
      );
    } else {
      this.filteredTravailleurs = this.travailleurs;
    }
  }

  selectingWorker() {
    if (this.travailleur_search != "") {
      var result = this.travailleurs.find(t => t.unique_search == this.travailleur_search)
      this.formAddSauveteurMinier.prenom_hazmat = result.prenom_travailleur_cap
      this.formAddSauveteurMinier.nom_hazmat = result.nom_travailleur_cap
      this.formAddSauveteurMinier.departement = result.nom_departement
      this.formAddSauveteurMinier.matricule = result.matricule
    } else {
      this.resetFormAdd()
    }
  }

  getTravailleurs() {
    this.travailleurService.getTravailleurService().subscribe((data: any) => {
      this.travailleurs = data
      this.travailleurs.forEach(worker => {
        worker.unique_search = worker.nom_travailleur_cap + ", " + worker.prenom_travailleur_cap + " (" + worker.matricule + ")"
      })
      this.filteredTravailleurs = [...this.travailleurs]
    })
    this.getHazmat()
  }

  verificationRole() {
    this.dataUser = JSON.parse(this.cryptageService.decryptDecode(this.cookies.get('data_user')));
    this.is_admin = [10, 99].includes(this.dataUser.role)
    this.is_not_superviseur_or_visitore = ![6, 4].includes(this.dataUser.role)
    this.is_loading = false
  }

  saveSauveteur() {
    this.is_verify = true
    if (this.formAddSauveteurMinier.prenom_hazmat == "" || this.formAddSauveteurMinier.nom_hazmat == "") return
    if (this.formAddSauveteurMinier.departement == "" || this.formAddSauveteurMinier.matricule == "") return
    var dataObj = {
      id:this.formAddSauveteurMinier.id_hazmat,
      nom:this.formAddSauveteurMinier.nom_hazmat,
      prenom:this.formAddSauveteurMinier.prenom_hazmat,
      matricule:this.formAddSauveteurMinier.matricule,
      departement:this.formAddSauveteurMinier.departement,
      traitement: this.is_editing ? 3 : 1,
      table: "hazmat"
    }
    this.is_saving = true
    this.travailleurService.traitementDataPreventionisterService(dataObj).subscribe((data:any) => {
      if(!this.is_editing) {
        this.toast.Success("Données enregistrées")
        this.liste_hazmat.push({
          ...this.formAddSauveteurMinier,
          id_hazmat: data[0].id_hazmat,
          nom_prenom: this.formAddSauveteurMinier.nom_hazmat + ", " + this.formAddSauveteurMinier.prenom_hazmat
        })
        this.resetFormAdd()
      } else {
        this.toast.Success("Données modifiées")
        var index = this.liste_hazmat.findIndex(l => l.id_hazmat == this.formAddSauveteurMinier.id_hazmat)
        this.liste_hazmat[index] = {...this.formAddSauveteurMinier, nom_prenom : this.formAddSauveteurMinier.nom_hazmat + ", " + this.formAddSauveteurMinier.prenom_hazmat}
      }
      this.is_saving = false
    })
  }

  resetFormAdd() {
    this.formAddSauveteurMinier = {
      nom_hazmat: "",
      prenom_hazmat: "",
      matricule: "",
      departement: ""
    }
    this.is_verify = false
    this.is_editing = false
    this.travailleur_search = ""
  }

  deleteSauveteurMinier(id, index) {
    var dataObj = { id: id, traitement: 2, table:"hazmat" }
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette ligne?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        this.travailleurService.traitementDataPreventionisterService(dataObj).subscribe(() => {
          this.toast.Success("Données supprimées")
          this.liste_hazmat.splice(index, 1)
          this.resetFormAdd()
        })
      }
    });
  }

  toEditSauvateurMinier(id) {
    this.is_editing = true
    var row = this.liste_hazmat.find(l => l.id_hazmat == id);
    this.formAddSauveteurMinier = { ...row }
  }

  getHazmat() {
    this.is_loading = true
    this.travailleurService.getDatatablePreventionisteService({table:"hazmat"}).subscribe((data: any) => {
      this.liste_hazmat = data
      this.liste_hazmat.forEach(element => {
        element.nom_prenom = element.nom_hazmat + ", " + element.prenom_hazmat
      });
      this.verificationRole()
    })
  }
}
