<div class="p-3">
    <div class="mb-4">
        <div class="d-flex flex-row align-items-center">
            <div>
                <div class="input-group" style="width: 450px;">
                    <span class="input-group-text" id="basic-addon1">Recherche de travailleur</span>
                    <input type="text" class="form-control" list="nom_prenom_matricule" [(ngModel)]="travailleur_search" [ngClass]="{'is-invalid':travailleur_search == '' && is_verifing}" [disabled]="loading_data_travailleur" (input)="filterWorkers()">
                </div>
                <datalist id="nom_prenom_matricule">
                    <option *ngFor="let worker of filteredTravailleurs" [value]="worker.unique_search">{{worker.id_personne}}</option>
                </datalist>
            </div>
            <div *ngIf="!loading_data_travailleur">
                <button class="btn-special ms-3" (click)="afficheData()"> <i class="bi bi-search me-1"
                        style="font-size: 12px;"></i> Afficher les données</button>
                <button class="btn-special ms-1" (click)="resetFormSearch()"> <i class="bi bi-arrow-clockwise me-1"
                        style="font-size: 12px;"></i> Réinitialiser</button>
            </div>
            <div class="ms-3 d-flex flex-row align-items-center" *ngIf="loading_data_travailleur">
                <div class="spinner-grow spinner-grow-sm text-secondary" style="color: #707fdd;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span class="ms-2 text-secondary" style="font-size: 12px;"> Chargement des données... </span>
            </div>
        </div>
    </div>
    <!-- <hr style="background-color: #707fdd; padding: 2px;"> -->
    <div class="mt-4">
        <div class="row">
            <div class="col-12">
                <div class="p-3 mb-4 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row align-items-center">
                        <h5>Calendrier du travailleur</h5>
                        <div class="spinner-border spinner-border-sm ms-3" role="status" *ngIf="loading_calendrier">
                        </div>
                    </div>
                    <div class="mt-2">
                        <div style="overflow: auto;">
                            <table class="table table-sm table-bordered table-hover">
                                <thead class="sticky-row">
                                    <ng-container *ngFor="let year of dateData">
                                        <tr class="mois-ligne">
                                            <ng-container *ngFor="let month of year.months">
                                                <th [attr.colspan]="month.nombre_jour"
                                                    [ngStyle]="{'background-color': month.color, 'width': (month.nombre_jour * 25) + 'px'}">
                                                    {{month.mois}} {{month.annee}}</th>
                                            </ng-container>
                                        </tr>
                                        <tr class="jour-ligne">
                                            <ng-container *ngFor="let month of year.months">
                                                <th *ngFor="let jour of month.jours"
                                                    style="background-color: rgb(249, 245, 221);">
                                                    {{jour.num}}
                                                </th>
                                            </ng-container>
                                        </tr>
                                        <tr class="jour-ligne">
                                            <ng-container *ngFor="let month of year.months">
                                                <th *ngFor="let jour of month.jours"
                                                    style="background-color: rgb(252, 250, 241);">
                                                    {{jour.libelle}}</th>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let worker of travailleur_calendrier; let i = index">
                                        <ng-container *ngFor="let item of worker.days">
                                            <td
                                                [ngStyle]="{'background-color': item.valeur == '' ? '#f8f8f8' : '#ffffff'}">
                                                {{item.valeur}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5">
                <div class="mb-3 p-3 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row">
                        <h5>Profil du travailleur</h5>
                        <div class="spinner-border spinner-border-sm ms-3 mt-1" role="status" *ngIf="loading_data">
                        </div>
                    </div>
                    <div class="mt-3 d-flex flex-row align-items-start">
                        <div class="card" style="width: 50%;">
                            <div class="card-header"> INFO BASE </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Nom & prénom :</span>
                                    {{profil_travailleur.nom_prenom}} </li>
                                <li class="list-group-item"> <span>Sexe :</span> {{profil_travailleur.sexe |
                                    capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span>Date naissance :</span>
                                    {{profil_travailleur.date_naissance}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 50%;">
                            <div class="card-header"> INFO TRAVAILLEUR </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Statut :</span> {{profil_travailleur.statut |
                                    capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span>Matricule :</span> {{profil_travailleur.matricule}}
                                </li>
                                <li class="list-group-item"> <span>ID Personne :</span>
                                    {{profil_travailleur.id_personne}} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2 d-flex flex-row align-items-start">
                        <div class="card" style="width: 50%;">
                            <div class="card-header"> INFO EMPLOI </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Date début :</span> {{profil_travailleur.date_debut}}
                                </li>
                                <li class="list-group-item"> <span>Date fin :</span> {{profil_travailleur.date_fin}}
                                </li>
                                <li class="list-group-item"> <span>Type : </span> {{profil_travailleur.type_emploi |
                                    capitalizeFirstLetter}} </li>
                                <li class="list-group-item"> <span> Catégorie :</span> {{profil_travailleur.categorie |
                                    capitalizeFirstLetter}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 50%;">
                            <div class="card-header"> INFO AFFILIATION </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Département :</span>
                                    {{profil_travailleur.departement}} </li>
                                <li class="list-group-item"> <span>Sous département :</span>
                                    {{profil_travailleur.sous_departement}} </li>
                                <li class="list-group-item"> <span>Sup. Immédiat :</span>
                                    {{profil_travailleur.sup_immediat}} </li>
                                <li class="list-group-item"> <span>Superviseur :</span>
                                    {{profil_travailleur.superviseur}} </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2 d-flex flex-row align-items-start">
                        <div class="card" style="width: 50%;">
                            <div class="card-header"> AUTRE INFO </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Entrepreneur : </span>
                                    {{profil_travailleur.entrepreneur}} </li>
                                <li class="list-group-item"> <span>Sauveteur :</span> {{profil_travailleur.sauveteur}}
                                </li>
                                <li class="list-group-item"> <span>Pompier :</span> {{profil_travailleur.pompier}} </li>
                                <li class="list-group-item"> <span>Réalité :</span> {{profil_travailleur.realite}} </li>
                            </ul>
                        </div>
                        <div class="card ms-2" style="width: 50%;">
                            <div class="card-header"> INFO TRAVAIL </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item"> <span>Poste :</span> {{profil_travailleur.poste}} </li>
                                <li class="list-group-item"> <span>Emploi :</span> {{profil_travailleur.emploi}} </li>
                                <li class="list-group-item"> <span>Horaire :</span> {{profil_travailleur.horaire}} </li>
                                <li class="list-group-item"> <span>Equipe :</span> {{profil_travailleur.equipe |
                                    capitalizeFirstLetter}} </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="p-3 mb-3 bg-white shadow-sm border rounded">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div class="d-flex flex-row">
                            <h5>Organigramme lié au travailleur</h5>
                            <div class="spinner-border spinner-border-sm ms-3 mt-1" role="status"
                                *ngIf="loading_organigramme"></div>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-secondary" (click)="zoomIn()"> <i class="bi bi-plus-lg"
                                    style="font-size: 11px;"></i> </button>
                            <button class="btn btn-sm btn-secondary ms-1" (click)="zoomOut()"> <i class="bi bi-dash-lg"
                                    style="font-size: 11px;"></i> </button>
                            <button class="ms-2 btn-special" (click)="zoomToFit()">Ajuster le chart</button>
                        </div>
                    </div>
                    <div class="mt-2">
                        <div id='myDiagramDiv' class="rounded border mt-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>