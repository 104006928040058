import { last } from '@amcharts/amcharts5/.internal/core/util/Array';
import { Component, OnInit } from '@angular/core';
import { FileService } from 'src/app/services/file/file.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tableau-display',
  templateUrl: './tableau-display.component.html',
  styleUrls: ['./tableau-display.component.css']
})
export class TableauDisplayComponent implements OnInit {

  constructor(
    private fileService: FileService,
    private travailleurService: TravailleurService,
    private toast: ToastService
  ) {
    this.getDepartement()
  }

  ngOnInit(): void {
  }

  is_loading: any = false
  departements: any = []
  sous_departement:any = []
  sous_departement_dynamique:any = []
  formation_procedure: any = []
  departement: any = ""
  sous_departement_selected: any = ""
  sous_departement_added:any = ""
  equipe: any = ""
  new_onglet: any = ""
  liste_tab: any = []
  is_uploading:any = false
  is_added_sous_departement:any = false

  onFilesDropped(files: FileList) {
    this.is_uploading = true
    const droppedFile = files[0]
    if (droppedFile) {
      if (this.departement != "" && this.equipe != "") {
        var dataObj = { file: droppedFile, id_departement: this.departement, id_sous_departement: this.sous_departement_selected, id_onglet: this.equipe }
        this.fileService.uploadTableauDisplay(dataObj).subscribe((data:any) => {
          this.formation_procedure = this.prepareData(data)
          this.liste_tab.find(l => l.id_onglet_formation_procedure == this.equipe).data = [...this.formation_procedure]
          this.liste_tab.find(l => l.id_onglet_formation_procedure == this.equipe).data_temp = [...this.formation_procedure]
          this.toast.Success("Fichier importé avec succès")
          this.is_uploading = false
        })
      }
    }
  }

  getDepartement() {
    this.travailleurService.getDataDropDownService().subscribe((data: any) => {
      this.departements = data[2]
      this.getSousDepartement()
    })
  }

  
  getSousDepartement(){
    this.travailleurService.getSousDepartementProcedureService().subscribe((data:any) => {
      this.sous_departement = data
      this.sous_departement_dynamique = [...this.sous_departement]
    })
  }

  getOnglet() {
    var dataObj = { id_departement: this.departement, id_sous_departement: this.sous_departement_selected }
    this.travailleurService.getOngletFormationProcedureService(dataObj).subscribe((data: any) => {
      this.liste_tab = data
      this.liste_tab.forEach(l => { l.data = []; l.data_temp = [] })
      this.liste_tab.forEach(l => {
        l.data = [...this.formation_procedure.filter(f => f.id_onglet_formation_procedure == l.id_onglet_formation_procedure)]
        l.data_temp = [...this.formation_procedure.filter(f => f.id_onglet_formation_procedure == l.id_onglet_formation_procedure)]
      });
      if (this.liste_tab.length > 0) this.equipe = this.liste_tab[0].id_onglet_formation_procedure
      this.is_loading = false
    })
  }

  viewDataSousDepartement(departement: any) {
    if (departement != "") {
      this.sous_departement_dynamique = [...this.sous_departement.filter(sd => sd.id_departement == departement)]
      this.sous_departement_selected = ""
      this.liste_tab = []
    }
  }

  viewDataCompetence(departement:any, sous_departement:any){
    if(departement != "" && sous_departement != ""){
      this.is_loading = true
      this.getFormationProcedure()
    }
  }


  getFormationProcedure() {
    var dataObj = { id_departement: this.departement, id_sous_departement: this.sous_departement_selected }
    this.travailleurService.getFormationProcedure(dataObj).subscribe((data: any) => {
      this.formation_procedure = this.prepareData(data)
      this.getOnglet()
    })
  }

  prepareData(data: any) {
    data.forEach(d => {
      d.formation = JSON.parse(d.formation)
      d.procedure = JSON.parse(d.procedure)
    })
    let lastData = data.sort((a, b) => b.id_formation_procedure - a.id_formation_procedure)
    return lastData;
  }

  addOnglet(onglet: any) {
    if (this.departement != "") {
      var dataObj = { libelle: onglet, id_departement: this.departement, id_sous_departement: this.sous_departement_selected, traitement: 1 }
      this.travailleurService.traitementOngletFormationProcedureService(dataObj).subscribe((data: any) => {
        this.toast.Success("Nouvel onglet ajouté")
        if (this.liste_tab.length == 0) this.equipe = data.rows[0].id_onglet_formation_procedure
        this.liste_tab.push({
          id_onglet_formation_procedure: data.rows[0].id_onglet_formation_procedure,
          id_departement: this.departement,
          libelle: onglet,
          data: [], data_temp: []
        })
        this.new_onglet = ""
      })
    }
  }

  deleteOnglet(id: any, index: any) {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cet onglet? \n Toutes les données correspondant à cet onglet seront supprimées.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = { id_onglet: id, traitement: 2 }
        this.travailleurService.traitementOngletFormationProcedureService(dataObj).subscribe(() => {
          this.toast.Success("Onglet supprimé avec succès")
          this.liste_tab.splice(index, 1)
        })
      }
    });
  }

  editOnglet(libelle: any, id: any) {
    var dataObj = { libelle: libelle, id_onglet: id, traitement: 3 }
    this.travailleurService.traitementOngletFormationProcedureService(dataObj).subscribe(() => {
      this.toast.Success("Donnée modifiée avec succès")
    })
  }

  addSousDepartement(){
    this.is_added_sous_departement = true
    if(this.sous_departement_added == "") return
    var dataObj = {id_departement:this.departement, nom_sous_departement:this.sous_departement_added, traitement:1}
    this.travailleurService.traitementSousDepartementProcedureService(dataObj).subscribe((data:any) => {
      this.sous_departement_dynamique.push({
        id_sous_departement:data[0].id_sous_departement,
        nom_sous_departement:this.sous_departement_added,
        id_departement:this.departement
      })
      this.toast.Success("Sous département ajouté")
      this.resetFormSousDepartement()
    })
  }

  resetFormSousDepartement(){
    this.sous_departement_added = ""
    this.is_added_sous_departement = false
  }

  deleteSousDepartement(id:any, index:any){
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer ce sous-département ? \n Toutes les données correspondant seront supprimées.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = { id_sous_departement: id, traitement: 2 }
        this.travailleurService.traitementSousDepartementProcedureService(dataObj).subscribe(() => {
          this.toast.Success("Sous-département supprimé")
          this.sous_departement_dynamique.splice(index, 1)
          this.sous_departement_selected = ""
          this.liste_tab = []
        })
      }
    });
  }

  updateSousDepartement(id:any, nom:any){
    var dataObj = {id_sous_departement:id, nom_sous_departement:nom, traitement:3}
    this.travailleurService.traitementSousDepartementProcedureService(dataObj).subscribe(() => {
      this.toast.Success("Donnée modifiée")
    })
  }

}
