import { Directive, HostListener, EventEmitter, Output, HostBinding  } from '@angular/core';

@Directive({
  selector: '[appDragdrop]'
})
export class DragdropDirective {

  constructor() { }

  @Output() dropped = new EventEmitter<FileList>();   

  @HostBinding('class.drag-over') isDragOver = false;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.dropped.emit(event.dataTransfer.files);
    }
  }
}
