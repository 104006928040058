<app-load-page *ngIf="is_loading"></app-load-page>
<div class="px-3 py-2" *ngIf="!is_loading">
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#tableau-globale">Global</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tableau-detaille">Détaillé</a>
        </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane fade" id="tableau-detaille">
            <div class="px-2 py-2 mt-3" id="tableau-detaille">
                <div class="mb-2 d-flex flex-row align-items-center justify-content-between">
                    <span class="h4">Liste des formations</span>
                    <div class="d-flex flex-row">
                        <button class="btn-special me-2" (click)="exporterTableau()" *ngIf="is_admin || user_log.role == 8">
                            <span class="spinner-border spinner-border-sm me-1" aria-hidden="true" *ngIf="is_downloading"></span>
                            <span *ngIf="!is_downloading"> <i class="bi bi-file-earmark-excel me-1" style="font-size: 12px;"></i> </span>
                            Exporter</button>
                        <div class="input-group input-group-sm" style="width: 300px;">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="bi bi-search" *ngIf="formation_search == ''"></i>
                                <i class="bi bi-x-lg" *ngIf="formation_search != ''" (click)="resetSearch()"></i>
                            </span>
                            <input type="text" class="form-control" placeholder="Formation"
                                [(ngModel)]="formation_search" (keyup)="searchFormation(formation_search)"
                                aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                </div>
                <div style="overflow: auto; height: 79vh;">
                    <table class="table table-hover table-bordered table-sm"
                        style="vertical-align: middle; text-align: center;">
                        <thead style="position: sticky; top: -1px;">
                            <tr>
                                <th (click)="trieParKey('num_demande')">N° demande <i [class]="icon_trie" *ngIf="affiche_icon.num_demande"></i> </th>
                                <th (click)="trieParKey('demandeur')">Demandeur <i [class]="icon_trie"
                                        *ngIf="affiche_icon.demandeur"></i></th>
                                <th (click)="trieParKey('date_prevue')">Date prévue <i [class]="icon_trie"
                                        *ngIf="affiche_icon.date_prevue"></i></th>
                                <th (click)="trieParKey('statut_inscription')">Statut inscription <i [class]="icon_trie"
                                        *ngIf="affiche_icon.statut_inscription"></i></th>
                                <th (click)="trieParKey('num_formation')">N° formation <i [class]="icon_trie"
                                        *ngIf="affiche_icon.num_formation"></i></th>
                                <th (click)="trieParKey('nom_formation')">Formation <i [class]="icon_trie"
                                        *ngIf="affiche_icon.nom_formation"></i></th>
                                <th (click)="trieParKey('nom_formateur')">Formateur <i [class]="icon_trie"
                                        *ngIf="affiche_icon.nom_formateur"></i></th>
                                <th (click)="trieParKey('travailleur')">Travailleur <i [class]="icon_trie"
                                        *ngIf="affiche_icon.travailleur"></i></th>
                                <th *ngIf="is_admin"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of dataTableau_dynamique; let i = index">
                                <td> {{item.num_demande}} </td>
                                <td> {{item.demandeur}} </td>
                                <td> <span> {{item.date_prevue | date: "yyyy-MM-dd"}} </span> </td>
                                <td>
                                    <div *ngIf="item.statut_inscription.includes('progression')" class="rounded success"> {{item.statut_inscription | capitalizeFirstLetter}}</div>
                                    <div *ngIf="item.statut_inscription.includes('autorisé')" class="rounded primary"> {{item.statut_inscription | capitalizeFirstLetter}}</div>
                                    <div *ngIf="item.statut_inscription.includes('attente')" class="rounded secondary"> {{'en attente'| capitalizeFirstLetter}}</div>
                                    <div *ngIf="item.statut_inscription.includes('refu') || item.statut_inscription.includes('annu')" class="text-white rounded danger">{{item.statut_inscription | capitalizeFirstLetter}} </div>
                                    <div *ngIf="item.statut_inscription.includes('création')" class="rounded secondary">{{item.statut_inscription | capitalizeFirstLetter}} </div>
                                </td>
                                <td> {{item.num_formation}} </td>
                                <td> {{item.nom_formation}} </td>
                                <td> {{item.nom_formateur}} </td>
                                <td> {{item.travailleur }} </td>
                                <td *ngIf="is_admin"> <i class="bi bi-trash text-danger" style="font-size: 12px;"
                                        (click)="deleteFormation(dataTableau_dynamique, i)"></i> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="tab-pane fade show active" id="tableau-globale">
            <div class="mt-4" style="overflow: auto; height: 84vh;">
                <div class="row d-flex" style="flex-wrap: nowrap; width: 100%;">
                    <div class="col-2" *ngFor="let item of dataTableauGlobal; let i = index" style="margin-right: -5px;">
                        <div style="position:sticky; top: 0; background-color: #fff; z-index:1;">
                            <div class="text-center border rounded px-3 py-2 d-flex flex-row justify-content-center align-items-center"
                                style="height: 60px; width: 100%;" [ngStyle]="{'background-color': item.color}">
                                <span style="font-size: 12px;">{{ item.nom_formation }}</span>
                            </div>
                        </div>
                        <ng-container *ngFor="let worker of item.travailleurs; let i = index">
                            <div class="text-center border mt-2 rounded p-1" id="nom-complet" style="width: 100%;">
                                <div>
                                    <span *ngIf="worker.present" style="font-size: 9px;"> <i class="bi bi-check-circle-fill text-success" style="font-size: 9px;"></i> Présent ({{worker.shift}}) </span>
                                    <span *ngIf="!worker.present" style="font-size: 9px;"> <i class="bi bi-x-circle-fill text-danger" style="font-size: 9px;"></i> Absent </span>
                                </div>
                                <span style="font-size: 12px;">{{worker.nom_complet}}</span> 
                                <i class="bi bi-trash text-danger" id="icon-trash" *ngIf="is_admin" (click)="deleteFormation(item.travailleurs, i, true)"></i>
                                <div class="date_tbd" *ngIf="worker.show">
                                    <span>Formation prevue le {{worker.date_prevue}}</span>
                                </div>
                                <div class="card border-0 mt-2" style="width:100%;" *ngIf="worker.code_departement != null">
                                    <ul class="list-group list-group-flush" style="text-align: start;">
                                        <li class="list-group-item li-info">
                                            <span>Statut inscription :</span>
                                            <span *ngIf="!worker.passe_date" style="font-weight: normal; font-size:10px;"> {{worker.statut_inscription | capitalizeFirstLetter}}</span>
                                            <span *ngIf="worker.passe_date" style="font-weight: normal; font-size:10px;"> Passé date</span>
                                        </li>
                                        <li class="list-group-item li-info">
                                            <span>Dep :</span>
                                            {{worker.code_departement}} - {{worker.nom_departement}}
                                        </li>
                                        <li class="list-group-item li-info">
                                            <span>S.Dep :</span>
                                            {{worker.nom_sous_departement == null ? '--VIDE--'  : worker.nom_sous_departement | capitalizeFirstLetter}}
                                        </li>
                                        <li class="list-group-item li-info">
                                            <span>Equipe :</span>
                                            {{worker.equipe}}
                                        </li>
                                        <li class="list-group-item li-info">
                                            <span>Sup. immediat :</span>
                                            {{worker.nom_prenom_sup_1}}
                                        </li>
                                        <li class="list-group-item li-info">
                                            <span>Sup. Niv2 :</span>
                                            {{worker.nom_prenom_sup_2}}
                                        </li>
                                    </ul>
                                  </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>