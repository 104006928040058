<app-load-page *ngIf="is_loading"></app-load-page>
<div class="mb-2 d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center">
        <div class="bg-white border rounded shadow-sm px-2 py-1 d-flex flex-row align-items-center" id="state_nombre">
            <span>Total ligne : {{total_ligne}}</span>
            <span class="ms-3 me-3" style="color: #707fdd; font-size: 12px;">|</span>
            <span>Actif : {{actif_ligne}}</span>
            <span class="ms-3 me-3" style="color: #707fdd; font-size: 12px;">|</span>
            <span>Inactif : {{inactif_ligne}}</span>
            <span class="ms-3 me-3" style="color: #707fdd; font-size: 12px;">|</span>
            <span>Nb actuel : {{current_ligne}}</span>
        </div>
        <button class="btn-special ms-2" (click)="resetFilter()">Effacer les filtres</button>
        <button class="btn-special ms-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <i class="bi bi-gear me-1" style="font-size: 13px;"></i> Colonnes</button>
    </div>
    <div>
        <button class="btn btn-sm btn-outline-success me-2" (click)="exportData()" [disabled]="is_downloading" *ngIf="access || user_log.role == 8">
            <span class="spinner-border spinner-border-sm me-1" aria-hidden="true" *ngIf="is_downloading"></span>
            <span *ngIf="!is_downloading"> <i class="bi bi-filetype-xls me-1"></i> </span>
            Exporter
        </button>
        <button *ngIf="access" class="btn-special" data-bs-toggle="modal" data-bs-target="#add_worker"> <i class="bi bi-plus-lg me-1"></i> Ajout d'un nouveau travailleur</button>
    </div>
</div>
<div style="height: 88vh; overflow: auto;">
    <div #tableContainer></div>
</div>


<!-- Modal -->
<div class="modal fade" id="add_worker" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-body rounded p-4" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h4>Ajout d'un nouveau travailleur</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Nom <span
                                        class="text-primary">*</span></span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.nom"
                                    [ngClass]="{'is-invalid': form_new_worker.nom == '' && is_verifing}"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Prénom <span
                                        class="text-primary">*</span></span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.prenom"
                                    [ngClass]="{'is-invalid': form_new_worker.prenom == '' && is_verifing}"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Sexe <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.sexe"
                                    [ngClass]="{'is-invalid': form_new_worker.sexe == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let sexe of sexe_liste" value="{{sexe.id_sexe}}">{{sexe.libelle_sexe
                                        | capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Date naissance</span>
                                <input type="date" class="form-control" [(ngModel)]="form_new_worker.date_naissance"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Statut <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.statut"
                                    [ngClass]="{'is-invalid': form_new_worker.statut == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let statut of statut_employe_liste"
                                        value="{{statut.id_statut_employe}}">{{statut.libelle_statut |
                                        capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">ID Employé <span
                                        class="text-primary">*</span></span>
                                <input type="number" min="0" max="9999999" [(ngModel)]="form_new_worker.id_employe"
                                    [ngClass]="{'is-invalid': form_new_worker.id_employe == '' && is_verifing}"
                                    class="form-control" aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">ID Personne</span>
                                <input type="text" [(ngModel)]="form_new_worker.id_personne" class="form-control"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Horaire</span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.horaire"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Date emploi</span>
                                <input type="date" class="form-control" [(ngModel)]="form_new_worker.date_emploi"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Date fin emploi</span>
                                <input type="date" class="form-control" [(ngModel)]="form_new_worker.date_fin_emploi"
                                    [min]="form_new_worker.date_emploi" [disabled]="form_new_worker.date_emploi == ''"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Type d'emploi <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" id="inputGroupSelect01"
                                    [(ngModel)]="form_new_worker.type_emploi"
                                    [ngClass]="{'is-invalid': form_new_worker.type_emploi == '' && is_verifing}">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let type of type_emploi_liste" value="{{type.id_type_emploi}}">
                                        {{type.libelle_type_emploi | capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Catégorie d'emploi <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.categorie_emploi"
                                    [ngClass]="{'is-invalid': form_new_worker.categorie_emploi == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let cat of cat_emploi_liste" value="{{cat.id_cat_emploi}}">
                                        {{cat.libelle_cat_emploi | capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Nom entrepreneur <span
                                        class="text-primary">*</span> </label>
                                <select class="form-select" [(ngModel)]="form_new_worker.nom_entrepreneur"
                                    [ngClass]="{'is-invalid': form_new_worker.nom_entrepreneur == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let entrepreneur of entrepreneur_liste"
                                        value="{{entrepreneur.id_entrepreneur}}">{{entrepreneur.nom_entrepreneur}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Equipe <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.equipe"
                                    [ngClass]="{'is-invalid': form_new_worker.equipe == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let equipe of equipe_liste" value="{{equipe.id_equipe}}">
                                        {{equipe.nom_equipe | capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Département <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.departement"
                                    [ngClass]="{'is-invalid': form_new_worker.departement == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let departement of departement_liste"
                                        value="{{departement.id_departement}}">{{departement.code_organisation}} -
                                        {{departement.nom_departement}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Sous département</label>
                                <select class="form-select" [(ngModel)]="form_new_worker.sous_departement"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let sous_departement of sous_departement_liste"
                                        value="{{sous_departement.id_sous_departement}}">
                                        {{sous_departement.nom_sous_departement | capitalizeFirstLetter}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Titre du poste <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.titre_poste"
                                    [ngClass]="{'is-invalid': form_new_worker.titre_poste == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let fonction of fonction_liste" value="{{fonction.id_fonction}}">
                                        {{fonction.id_poste}} - {{fonction.nom_fonction | capitalizeFirstLetter}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Titre d'emploi <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.titre_emploi"
                                    [ngClass]="{'is-invalid': form_new_worker.titre_emploi == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let emploi of emploi_liste" value="{{emploi.id_emploi}}">
                                        {{emploi.id_emp}} - {{emploi.nom_emploi}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Sauveteur <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.sauveteur"
                                    [ngClass]="{'is-invalid': form_new_worker.sauveteur == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option value="1">OUI</option>
                                    <option value="0">NON</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Pompier <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.pompier"
                                    [ngClass]="{'is-invalid': form_new_worker.pompier == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option value="1">OUI</option>
                                    <option value="0">NON</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="input-group input-group-sm mb-2">
                                <label class="input-group-text" for="inputGroupSelect01">Réalité <span
                                        class="text-primary">*</span></label>
                                <select class="form-select" [(ngModel)]="form_new_worker.realite"
                                    [ngClass]="{'is-invalid': form_new_worker.realite == '' && is_verifing}"
                                    id="inputGroupSelect01">
                                    <option selected disabled value=""></option>
                                    <option value="1">OUI</option>
                                    <option value="0">NON</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Niveau supérieur</span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.niveau_superieur"
                                    aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Marticule supérieur</span>
                                <input type="text" class="form-control"
                                    [(ngModel)]="form_new_worker.matricule_superieur"
                                    (change)="getFullNameSuperieur(form_new_worker)" aria-label="Username"
                                    aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Nom supérieur</span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.nom_superieur"
                                    disabled aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group input-group-sm mb-2">
                                <span class="input-group-text" id="basic-addon1">Prénom supérieur</span>
                                <input type="text" class="form-control" [(ngModel)]="form_new_worker.prenom_superieur"
                                    disabled aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button class="btn btn-sm save" (click)="saveWorker()"> <i class="bi bi-save me-1"></i>
                            Enregistrer</button>
                        <button class="btn btn-sm reset ms-2" (click)="resetForm()"> <i
                                class="bi bi-arrow-clockwise me-1"></i> Réinitialiser</button>
                        <span class="ms-3" style="color:gray; font-size: 12px;">(<span class="text-primary">*</span>)
                            Champ obligatoire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des colonnes</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-2">
                    <table class="table table-sm table-bordered table-hover" style="vertical-align: middle;">
                        <thead>
                            <tr>
                                <th style="width: 8%;">N°</th>
                                <th>Nom colonne</th>
                                <th style="width: 12%;">Afficher</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let colonne of headers; let i = index">
                                <ng-container *ngIf="i != 0">
                                    <td>{{i}}</td>
                                    <td>{{colonne.nom_colonne}}</td>
                                    <td style="text-align: center;">
                                        <div class="form-check" style="margin: auto;">
                                            <input class="form-check-input" type="checkbox" value="" (change)="hideShowColumn(i)" [(ngModel)]="colonne.afficher" id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault"></label>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>