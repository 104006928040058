import { INPUT_MODALITY_DETECTOR_DEFAULT_OPTIONS } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { UsersService } from 'src/app/services/users/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-competence',
  templateUrl: './competence.component.html',
  styleUrls: ['./competence.component.css']
})
export class CompetenceComponent implements OnInit {

  constructor(
    private travailleurService: TravailleurService,
    private toast: ToastService,
    private userService: UsersService
  ) {    
    this.getUser()
    this.getDataDepartement()
    this.getStatutCompetence()
  }

  liste_statut_competence: any = []
  liste_travailleur: any = []
  form_travailleur: any = {
    nom_travailleur: "",
    prenom_travailleur: "",
    date_naissaice: "",
    equipe: ""
  }
  is_saving: any = false
  is_loading: any = false
  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  affiche_icon: any = {
    nom_prenom: false
  }
  formations: any
  liste_type: any
  formationAdded: any = ""
  liste_tab:any = []

  is_editing_formation: any = false
  id_formation_editing: any = false
  liste_departement:any = []
  departement:any = ""
  new_onglet:any = ""
  access:any = false
  role_access:any = [99,10,9]
  user_log:any = []

  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      this.user_log = data
      this.access = this.role_access.includes(this.user_log.role)
    })
  }

  ngOnInit(): void {
  }

  getStatutCompetence() {
    this.travailleurService.getStatutCompetenceService().subscribe((data: any) => {
      this.liste_statut_competence = data
    })
  }

  trieParKey(key: any, index: any) {
    if (this.type_key != key) this.type_switch = 1
    this.setActiveKey(key)
    this.liste_tab[index].data
    if (this.type_switch == 1) {
      this.liste_tab[index].data = this.liste_tab[index].data_temp.sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.liste_tab[index].data = this.liste_tab[index].data_temp.sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.liste_tab[index].data = [...this.liste_travailleur.filter(l => l.id_onglet == this.liste_tab[index].id_onglet)]
      this.switchSort()
    }
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string) {
    for (let prop in this.affiche_icon) {
      if (this.affiche_icon.hasOwnProperty(prop)) {
        this.affiche_icon[prop] = false;
      }
    }
    if (this.affiche_icon.hasOwnProperty(key)) {
      this.affiche_icon[key] = true;
    }
  }

  viewDataCompetence(departement:any){
    if(departement != ""){
      this.is_loading = true
      this.getOnglet()
      this.getFormationCompetence()
    }
  }

  getDataDepartement(){
    this.travailleurService.getDataDropDownService().subscribe((data:any) => {
      this.liste_departement = data[2]
    })
  }

  getOnglet(){
    var dataObj = {id_departement:this.departement}
    this.travailleurService.getOngletCompetenceService(dataObj).subscribe((data:any) => {
      this.liste_tab = data
      this.liste_tab.forEach(l => {l.data = []; l.data_temp = []})
    })
  }

  getFormationCompetence() {
    var dataObj = {id_departement:this.departement}
    this.travailleurService.getFormationCompetenceService(dataObj).subscribe((data: any) => {
      this.formations = data
      this.formations.forEach(formation => {
        formation.type_detail = formation.type_detail.sort((a, b) => a.id_type_formation - b.id_type_formation)
        if (formation.type.includes(null)) formation.type = []
      })
      this.liste_type = this.formations.flatMap(f => f.type_detail.map(t => t.libelle_type_formation)).filter(l => l != null)
      this.getDataTravailleur()
    })
  }

  addTypeFormation(index: any, new_type: any) {
    if (new_type != "") {
      this.formations[index].type.push(new_type)
      var dataObj = {
        id_formation: this.formations[index].id_formation,
        libelle_type_formation: new_type,
        col: this.formations[index].type.filter(f => f != null).length == 0 ? 1 : this.formations[index].type.length,
        row: this.formations[index].type.filter(f => f != null).length > 0 ? 1 : 2,
        traitement: 1
      }
      this.formations[index].type_detail.push({ libelle_type_formation: new_type })
      this.formations[index].typeAdded = ""
      this.travailleurService.traitementTypeFormationCompetenceService(dataObj).subscribe(() => {
        this.formations[index].col = this.formations[index].type.filter(f => f != null).length
        this.formations[index].row = this.formations[index].type.filter(f => f != null).length > 0 ? 1 : 2
        this.liste_type = this.formations.flatMap(f => f.type_detail.map(t => t.libelle_type_formation)).filter(l => l != null)
      })
    }
  }

  addFormation() {
    if (this.formationAdded != "" && this.departement != "") {
      var dataObj = { libelle_formation: this.formationAdded, id_departement:this.departement }
      this.travailleurService.insertFormationCompetenceService(dataObj).subscribe((data: any) => {
        var new_object = {
          id_formation: data[0].id_formation,
          col: 1, row: 2, type: [], type_detail: [], libelle_formation: this.formationAdded, typeAdded: "", id_departement:this.departement
        }
        this.formations.push(new_object)
        this.resetFormationAdded()
      })
    }
  }

  deleteTypeFormation(type: any, formation: any, index: any) {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette formation?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimée",
      cancelButtonText: "Non, annulée",
    }).then((result) => {
      if (result.isConfirmed) {
        var index_type = this.liste_type.findIndex(l => l == type.libelle_type_formation)
        formation.type_detail.splice(index, 1)
        formation.type.splice(index, 1)
        var dataObj = {
          id_formation: formation.id_formation,
          libelle_type_formation: type.libelle_type_formation,
          col: formation.type.length == 0 ? 1 : formation.type.length,
          row: formation.type.length > 0 ? 1 : 2,
          traitement: 2
        }
        this.travailleurService.traitementTypeFormationCompetenceService(dataObj).subscribe(() => {
          formation.col = formation.type.length == 0 ? 1 : formation.type.length
          formation.row = formation.type.length > 0 ? 1 : 2
          this.liste_type = this.formations.flatMap(f => f.type_detail.map(t => t.libelle_type_formation)).filter(l => l != null)
        })
        this.liste_travailleur.map(l => l.niveau).forEach(t => t.splice(index_type, 1))
      }
    });
  }

  setTypeFormation(type: any, libelle: any) {
    var dataObj = {
      id_type_formation: type.id_type_formation,
      libelle_type_formation: libelle,
      traitement: 3
    }
    this.travailleurService.traitementTypeFormationCompetenceService(dataObj).subscribe(() => {
      this.liste_type = this.formations.flatMap(f => f.type_detail.map(t => t.libelle_type_formation)).filter(l => l != null)
    })
  }

  getDataTravailleur() {
    var dataObj = {id_departement:this.departement}
    this.travailleurService.getTravailleurCompetenceService(dataObj).subscribe((data: any) => {
      this.liste_travailleur = data
      let data_type_detail = this.formations.flatMap(f => f.type_detail)
      this.liste_travailleur.forEach(travailleur => {
        data_type_detail.forEach(type => {
          if (!travailleur.niveau.some(item => item.id_type_formation == type.id_type_formation)) {
            travailleur.niveau.push({ ...type, code: 0, commentaire: "" })
          }
        })
        travailleur.niveau = travailleur.niveau.filter(tn => tn.id_type_formation != null)
        travailleur.niveau.sort((a, b) => {
          if (a.id_formation != b.id_formation) {
            return a.id_formation - b.id_formation;
          }
          return a.id_type_formation - b.id_type_formation;
        })
      });
      this.liste_tab.forEach(lt => {
        lt.data = [...this.liste_travailleur.filter(l => l.id_onglet == lt.id_onglet)]
        lt.data_temp = [...this.liste_travailleur.filter(l => l.id_onglet == lt.id_onglet)]
      })
      this.is_loading = false
    })
  }

  getColor(code: any): string {
    var result = this.liste_statut_competence.filter(l => l.code == code)
    if (result.length != 0) {
      return result[0].color
    }
    return "#f8f8f8"
  }

  setCode(worker: any, index: any, code: any, commentaire: any) {
    var dataObj = {
      id_travailleur_formation: worker.id_travailleur_formation,
      id_type_formation: worker.niveau[index].id_type_formation,
      code: code,
      commentaire: commentaire
    }
    this.travailleurService.setCompetenceTravailleurService(dataObj).subscribe(() => { })
  }

  addTravailleur() {
    this.is_saving = true
    if (this.form_travailleur.nom_travailleur == "" || this.form_travailleur.prenom_travailleur == "" || this.form_travailleur.equipe == "") return
    var dataObj = {
      ...this.form_travailleur,
      id_departement:this.departement
    }
    this.travailleurService.insertTravailleurCompetence(dataObj).subscribe(() => {
      this.toast.Success("Travailleur ajouté")
      this.resetFormTravailleur()
      this.getDataTravailleur()
    })
  }

  resetFormTravailleur() {
    this.form_travailleur = {
      nom_travailleur: "",
      prenom_travailleur: "",
      date_naissaice: "",
      equipe: ""
    }
    this.is_saving = false
  }

  deleteTravailleur(worker: any, index: any, table: any) {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer ce travailleur?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = { id_travailleur_formation: worker.id_travailleur_formation }
        this.travailleurService.deleteTravailleurCompetence(dataObj).subscribe(() => {
          this.toast.Success("Travailleur supprimé")
          this.liste_tab[table].data.splice(index, 1)
        })
      }
    });
  }

  setDataTravailleur(worker: any, value: any, type: any) {
    var dataObj = {
      id_travailleur_formation: worker.id_travailleur_formation,
      valeur: value,
      champ: type
    }
    this.travailleurService.updateTravailleurCompetence(dataObj).subscribe(() => { })
  }

  resetFormationAdded() {
    this.formationAdded = ""
    this.is_editing_formation = false
    this.id_formation_editing = ""
  }

  deleteFormation(formation: any, index: any) {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cette formation? \n NB: Tous les types de formation dans cette catégorie seront supprimés.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = { id_formation: formation.id_formation, traitement: 4 }
        this.travailleurService.traitementTypeFormationCompetenceService(dataObj).subscribe(() => {
          this.toast.Success("Formation supprimée")
          this.getFormationCompetence()
        })
      }
    });
  }

  toEditFormation(formation: any, index: any) {
    this.is_editing_formation = true
    this.formationAdded = formation.libelle_formation
    this.id_formation_editing = formation.id_formation
  }

  editFormation() {
    var dataObj = { id_formation: this.id_formation_editing, libelle_formation: this.formationAdded, traitement: 5 }
    this.travailleurService.traitementTypeFormationCompetenceService(dataObj).subscribe(() => {
      var index = this.formations.findIndex(f => f.id_formation == this.id_formation_editing)
      this.formations[index].libelle_formation = this.formationAdded
      this.toast.Success("Opération terminée")
      this.resetFormationAdded()
    })
  }

  addOnglet(onglet:any){
    if(this.departement != ""){
      var dataObj = {libelle:onglet, id_departement:this.departement, traitement:1}
      this.travailleurService.traitementOngletCompetenceService(dataObj).subscribe((data:any) => {
        this.toast.Success("Nouvel onglet ajouté")
        this.liste_tab.push({
          id_onglet : data.rows[0].id_onglet,
          id_departement : this.departement,
          libelle:onglet, 
          data: [], data_temp: []
        })
        this.new_onglet = ""
      })
    }
  }

  deleteOnglet(id:any, index:any){
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer cet onglet? \n Tous les travailleurs correspondant à cet onglet seront supprimés.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = {id_onglet:id, traitement:2}
        this.travailleurService.traitementOngletCompetenceService(dataObj).subscribe(() => {
          this.toast.Success("Onglet supprimé avec succès")
          this.liste_tab.splice(index, 1)
        })
      }
    });
  }

  editOnglet(libelle:any, id:any){
    var dataObj = {libelle:libelle, id_onglet:id, traitement:3}
    this.travailleurService.traitementOngletCompetenceService(dataObj).subscribe(() => {
      this.toast.Success("Donnée modifiée avec succès")
    })
  }
}
