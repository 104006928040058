<div class="p-2">
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h5>Liste des formations</h5>
        <button class="btn-special" data-bs-toggle="modal" data-bs-target="#formationModal" (click)="resetFormFormation()"> <i
                class="bi bi-plus-lg me-1" style="font-size: 12px;"></i> Ajouter une formation</button>
    </div>
    <app-load-page *ngIf="is_loading"></app-load-page>
    <div style="overflow: auto; max-height: 86vh;" class="mt-2" *ngIf="!is_loading">
        <table class="table table-sm table-bordered table-striped">
            <thead style="position: sticky; top:-1px;">
                <tr>
                    <th style="width:8%;" (click)="trieParKey('num_formation')">N° formation <i [class]="icon_trie"
                            *ngIf="affiche_icon.num_formation"></i> </th>
                    <th style="width:23%" (click)="trieParKey('nom_formation')">Nom formation <i [class]="icon_trie"
                            *ngIf="affiche_icon.nom_formation"></i> </th>
                    <th style="width:8%;" (click)="trieParKey('categorie')">Catégorie <i [class]="icon_trie"
                            *ngIf="affiche_icon.categorie"></i> </th>
                    <th style="width:6%;" (click)="trieParKey('interne')">Interne / Externe <i [class]="icon_trie"
                            *ngIf="affiche_icon.interne"></i> </th>
                    <th style="width:8%;" (click)="trieParKey('resultat')">Résultat <i [class]="icon_trie"
                            *ngIf="affiche_icon.resultat"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('nb_heure_theorie')">Théorie <i [class]="icon_trie"
                            *ngIf="affiche_icon.nb_heure_theorie"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('nb_heure_pratique')">Pratique <i [class]="icon_trie"
                            *ngIf="affiche_icon.nb_heure_pratique"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('examen')">Examen <i [class]="icon_trie"
                            *ngIf="affiche_icon.examen"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('annee_refresh')">Refresh <i [class]="icon_trie"
                            *ngIf="affiche_icon.annee_refresh"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('inclus_matrice')">Matrice <i [class]="icon_trie"
                            *ngIf="affiche_icon.inclus_matrice"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('suivi_requisition')">Suivi <i [class]="icon_trie"
                            *ngIf="affiche_icon.suivi_requisition"></i> </th>
                    <th style="width:4%;" (click)="trieParKey('accessible_demande_superviseur')">Superviseur <i
                            [class]="icon_trie" *ngIf="affiche_icon.accessible_demande_superviseur"></i> </th>
                    <th style="width:14%">Formateurs</th>
                    <th style="width:8%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let formation of liste_formation_dynamique; let i = index">
                    <td>{{formation.num_formation}}</td>
                    <td style="text-align: start;">{{formation.nom_formation | capitalizeFirstLetter}}</td>
                    <td>{{formation.categorie}}</td>
                    <td>{{formation.interne ? "Interne" : "Externe"}}</td>
                    <!-- <td></td> -->
                    <td>{{formation.resultat | capitalizeFirstLetter}}</td>
                    <td>{{formation.nb_heure_theorie}}</td>
                    <td>{{formation.nb_heure_pratique}}</td>
                    <td>{{formation.examen ? "OUI" : "NON"}}</td>
                    <td>{{formation.annee_refresh}}</td>
                    <td>{{formation.inclus_matrice ? "OUI" : "NON"}}</td>
                    <td>{{formation.suivi_requisition ? "OUI" : "NON"}}</td>
                    <!-- <td></td> -->
                    <td>{{formation.accessible_demande_superviseur ? "OUI" : "NON"}}</td>
                    <td>
                        <ul style="padding: 15px 10px 0px 12px;" *ngIf="formation.formateurs.length > 0 && formation.formateurs[0] != null">
                            <li style="font-size: 12px; text-align: start;" *ngFor="let formateur of formation.formateurs">{{formateurName(formateur)}}</li>
                        </ul>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-outline-warning border-0" 
                            (click)="toEditFormation(formation)" data-bs-toggle="modal" data-bs-target="#formationModal"> <i class="bi bi-pen-fill"
                                style="font-size: 12px;"></i> </button>
                        <button class="btn btn-sm btn-outline-danger border-0"
                            (click)="deleteFormation(formation.id_liste_formation, i)"> <i class="bi bi-trash"
                                style="font-size: 12px;"></i> </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Modal Formulaire Formation -->
<div class="modal fade" id="formationModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h4>
                        <span *ngIf="!is_editing">Ajout d'une formation</span>
                        <span *ngIf="is_editing">Modification de formation</span>
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-2">
                    <div class="row">
                        <div class="col-sm-12 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.nom_formation" [ngClass]="{'is-invalid':formFormation.nom_formation == '' && is_verifing}" type="text" class="form-control" id="nom_formation" placeholder="Password">
                                <label for="nom_formation">Nom formation *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.num_formation" [ngClass]="{'is-invalid':formFormation.num_formation == '' && is_verifing}" type="text" class="form-control" id="num_formation" placeholder="Password">
                                <label for="num_formation">N° Formation *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.categorie" [ngClass]="{'is-invalid':formFormation.categorie == '' && is_verifing}" class="form-select" id="categorie" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let categorie of liste_categorie" [value]="categorie.id_cat_formation">{{categorie.libelle | capitalizeFirstLetter}}</option>
                                </select>
                                <label for="categorie">Catégorie *</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.interne_externe" [ngClass]="{'is-invalid':formFormation.interne_externe == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Interne</option>
                                    <option value="0">Externe</option>
                                </select>
                                <label for="resultat">Interne / Externe *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.theorie" type="number" [ngClass]="{'is-invalid':formFormation.theorie == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Heure théorie *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.pratique" type="number" [ngClass]="{'is-invalid':formFormation.pratique == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Heure pratique *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <input [(ngModel)]="formFormation.refresh" type="number" [ngClass]="{'is-invalid':formFormation.refresh == null && is_verifing}" min="0" step="1" class="form-control" id="theorie"
                                    placeholder="Password">
                                <label for="theorie">Refresh *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.examen" [ngClass]="{'is-invalid':formFormation.examen == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Examen requis *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.resultat" [ngClass]="{'is-invalid':formFormation.resultat == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option *ngFor="let resultat of liste_resultat" [value]="resultat.id_type_formation">{{resultat.libelle_type | capitalizeFirstLetter}}</option>
                                </select>
                                <label for="resultat">Résultat *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.matrice" [ngClass]="{'is-invalid':formFormation.matrice == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Inclure matrice *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.suivi" [ngClass]="{'is-invalid':formFormation.suivi == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Suivi réquisition *</label>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-2">
                            <div class="form-floating">
                                <select [(ngModel)]="formFormation.superviseur" [ngClass]="{'is-invalid':formFormation.superviseur == '' && is_verifing}" class="form-select" id="resultat" aria-label="Floating label select example">
                                    <option selected disabled value=""></option>
                                    <option value="1">Oui</option>
                                    <option value="0">Non</option>
                                </select>
                                <label for="resultat">Accès superviseur *</label>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="mt-2">
                                <span class="h6">Formateurs <span class="text-secondary" style="font-size: 12px;">({{formFormation.formateurs.length}})</span> </span>
                                <div class="row mt-2">
                                    <ng-container *ngFor="let formateur of formFormation.formateurs; let i = index">
                                        <div class="col-4 mb-2 d-flex flex-row align-items-center" id="nom-complet">
                                            <input type="text" class="form-control text-center" [value]="formateurName(formateur)" style="font-size: 13px;" placeholder="+ Nouveau" readonly>
                                            <i class="bi bi-trash text-danger" id="icon-trash" style="font-size: 12px;" (click)="dropFormateurFormation(formFormation, i)"></i>
                                        </div>
                                    </ng-container>
                                    <div class="col-4 mb-2">
                                        <input type="text" list="listeFormateur" [(ngModel)]="formateur_added" [value]="formateurName(formateur_added)" (change)="addFormateurToFormation(formateur_added, formFormation)" class="form-control text-center" style="font-size: 13px;" placeholder="+ Nouveau">
                                    </div>
                                    <datalist id="listeFormateur">
                                        <option *ngFor="let formateur of liste_formateur_dynamique" [value]="formateur.id_formateur">{{formateur.nom_formateur}}</option>
                                    </datalist>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <button class="btn-special-reverse" (click)="saveFormation()"> <i class="bi bi-save me-1" style="font-size: 12px;"></i> Enregistrer</button>
                        <button class="btn btn-sm btn-secondary ms-1" (click)="resetFormFormation()" *ngIf="!is_editing"> <i class="bi bi-arrow-clockwise me-1" style="font-size: 12px;"></i> Réinitialiser</button>
                        <button class="btn btn-sm btn-secondary ms-1" *ngIf="is_editing" data-bs-dismiss="modal"> <i class="bi bi-x-lg me-1" style="font-size: 12px;"></i> Annuler</button>
                        <span class="ms-3 text-secondary" style="font-size: 11px;">(*) Champs obligatoire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>