<div class="px-2">
    <div class="calendar-container py-1">
      <div class="calendar-header shadow-sm d-flex flex-row align-items-center justify-content-between">
        <div style="margin-top: 5px;">
          <h3 *ngIf="currentView === 'month'">{{ viewDate | date : "MMMM" | capitalizeFirstLetter }} {{viewDate | date: "y"}}</h3>
        </div>
        <div class="calendar-controls">
          <button mat-icon-button (click)="previous()" (keydown.enter)="previous()" (keydown.space)="previous()"><i class="bi bi-chevron-left"></i></button>
          <button class="btn" (click)="viewToday()" (keydown.enter)="viewToday()" (keydown.space)="viewToday()">Aujourd'hui</button>
          <button mat-icon-button (click)="next()" (keydown.enter)="next()" (keydown.space)="next()"><i class="bi bi-chevron-right"></i></button>
        </div>
      </div>
  
      <div>
          <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-month" *ngIf="currentView === 'month'">
            <thead>
              <tr>
                <th *ngFor="let day of weekDays">
                  {{ day }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr *ngFor="let week of weeks">
                  <td *ngFor="let date of week" [class.today]="isToday(date)" [ngClass]="{'notCurrentMonthStyle' : !isCurrentMonth(date)}">
                  <div class="cell-overlay" tabindex="0"></div>
                  <div class="date">{{ date.getDate() }}</div>
                  <div class="appointments">
                    <ng-container *ngFor="let item of formations">
                      <div *ngIf="isSameDate(item.date_prevue, date)" class="appointment" (click)="openModal(item)" 
                      [ngStyle]="{ 'background-color': item.color }">
                          <span>{{ item.nom_formation }}</span>
                      </div>
                  </ng-container>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
  