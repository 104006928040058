import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users/users.service';
import { ToastService } from '../services/toast/toast.service';
import Swal from 'sweetalert2';
import { CryptageService } from '../services/cryptage/cryptage.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-gestion-utilisateur',
  templateUrl: './gestion-utilisateur.component.html',
  styleUrls: ['./gestion-utilisateur.component.css']
})
export class GestionUtilisateurComponent implements OnInit {

  constructor(
    private userService:UsersService,
    private toast:ToastService,
    private cryptageService:CryptageService,
    private cookies:CookieService
  ) { 
    this.getAllUser()
    this.getInfoLog()
  }

  getInfoLog(){
    this.userService.getUserService({}).subscribe((data: any) => {
      this.info_user_log = data
    })
  }

  role_list:any = [
    {libelle:"Administrateur", codename:"admin", code:"99-10", search_name:""},
    {libelle:"Formateur", codename:"formateur", code:"9", search_name:""},
    {libelle:"Agent de bureau", codename:"agent_bureau", code:"7", search_name:""},
    {libelle:"Preventioniste", codename:"preventioniste", code:"8", search_name:""},
    {libelle:"Superviseur", codename:"superviseur", code:"6", search_name:""},
    {libelle:"Visiteur", codename:"viewer", code:"4", search_name:""}
  ]

  utilisateur_form:any = {
    nom:"",
    prenom:"",
    email:"",
    role:"",
    username:"",
    password:""
  }
  is_verifing:any = false
  showPassword:any = false
  liste_user:any = []
  liste_user_temp:any = []
  liste_user_dynamique:any = []
  is_loading:any = false
  is_editing:any = false
  id_user_editing:any = ""
  current_role_editing:any = ""
  info_user_log:any = []
  affiche_icon:any = {}
  type_switch: any = 0
  type_key: any = ""
  icon_trie: any = ""
  current_niveau_trie:any = ""

  ngOnInit(): void {
  }

  resetForm(){
    this.is_verifing = false
    this.utilisateur_form = {
      nom:"",
      prenom:"",
      email:"",
      role:"",
      username:"",
      password:""
    }
    this.is_editing = false
    this.id_user_editing = ""
    this.current_role_editing = ""
  }

  saveUtilisateur(){
    this.is_verifing = true
    if(this.utilisateur_form.nom == "" || this.utilisateur_form.prenom == "") return
    if(this.utilisateur_form.email == "" || this.utilisateur_form.role == "") return
    if(this.utilisateur_form.username == "" || this.utilisateur_form.password == "") return
    this.userService.insertUserService(this.utilisateur_form).subscribe((data:any) => {
      this.toast.Success("Utilisateur ajouté")
      this.resetForm()
      this.getAllUser()
    })
  }

  getAllUser(){
    this.is_loading = true
    this.userService.getAllUserService().subscribe((data:any) => {
      this.liste_user = data
      data.map(d => d.nom_prenom = d.nom_user + ", " + d.prenom_user)
      const data_grouped = data.reduce((acc, user) => {
        let libelle_type = ""
        switch(user.role){
          case 99:
          case 10:
            libelle_type = "Administrateur"
            break
          case 9:
            libelle_type = "Formateur"
            break
          case 8: 
            libelle_type = "Preventioniste"
            break
          case 7: 
            libelle_type = "Agent de bureau"
            break
          case 6:
            libelle_type = "Superviseur"
            break
          case 4:
            libelle_type = "Visiteur"
            break
        }
        if(!acc[libelle_type]) {
          acc[libelle_type] = []
        }
        acc[libelle_type].push(user)
        return acc
      }, {})
      this.liste_user = data_grouped
      this.liste_user_dynamique = JSON.parse(JSON.stringify(this.liste_user))
      this.liste_user_temp = JSON.parse(JSON.stringify(this.liste_user))
      this.is_loading = false
      this.role_list.forEach(t => {
        if(this.liste_user[t.libelle] != undefined){
          this.affiche_icon[t.libelle] = {
            nom_prenom: false,
            email: false,
            user: false,
            password: false,
          }
        }
      })
    })
  }

  deleteUser(info:any, niveau:any, index:any){
    Swal.fire({
      title: "Suppression",
      text: "Voulez vous vraiment supprimer cet utilisateur?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, supprimé",
      cancelButtonText: "Non, annulé"
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = {id_user:info.id_user}
        this.userService.deleteUserService(dataObj).subscribe(() => {
          this.liste_user_dynamique[niveau].splice(index, 1)
          this.toast.Success("Utilisateur supprimé")
        })
      }
    });
  }

  toEditUser(info:any){
    this.is_editing = true
    this.utilisateur_form = {
      nom:info.nom_user,
      prenom:info.prenom_user,
      email:info.email,
      // role: info.role.includes("9") && info.id_formateur == null ? "admin" : info.role.includes("9") && info.id_formateur != null ? "formateur" : this.role_list.filter(r => r.code.includes(info.role))[0].codename,
      role: info.role.toString().includes("9") 
      ? (info.id_formateur == null ? "admin" : "formateur") 
      : this.role_list.find(r => r.code.includes(info.role))?.codename,
      username:info.user,
      password:info.password
    }
    this.id_user_editing = info.id_user
    this.current_role_editing = this.role_list.filter(r => r.code.includes(info.role))[0].codename
  }

  saveEditingUser(){
    this.is_verifing = true
    if(this.utilisateur_form.nom == "" || this.utilisateur_form.prenom == "") return
    if(this.utilisateur_form.email == "" || this.utilisateur_form.role == "") return
    if(this.utilisateur_form.username == "" || this.utilisateur_form.password == "") return
    this.utilisateur_form.id_user = this.id_user_editing
    this.utilisateur_form.current_role = this.current_role_editing
    this.userService.updateUserService(this.utilisateur_form).subscribe(() => {
      this.getAllUser()
      this.toast.Success("Information modifiée")
    })
  }

  trieParKey(key: any, niveau:any) {
    if (this.type_key != key || this.current_niveau_trie != niveau) this.type_switch = 1
    this.setActiveKey(key, niveau)
    if (this.type_switch == 1) {
      this.liste_user_dynamique[niveau] = this.liste_user_temp[niveau].sort((a, b) => b[key].toString().localeCompare(a[key].toString()))
      this.switchSort()
    } else if (this.type_switch == 2) {
      this.liste_user_dynamique[niveau] = this.liste_user_temp[niveau].sort((a, b) => a[key].toString().localeCompare(b[key].toString()))
      this.switchSort()
    } else {
      this.liste_user_dynamique[niveau] = [...this.liste_user[niveau]]
      this.switchSort()
    }
    this.current_niveau_trie = niveau
    this.type_key = key
  }

  switchSort() {
    if (this.type_switch == 0) {
      this.type_switch = 1
      this.icon_trie = ""
    } else if (this.type_switch == 1) {
      this.type_switch = 2
      this.icon_trie = "bi bi-arrow-down"
    } else if (this.type_switch == 2) {
      this.type_switch = 0
      this.icon_trie = "bi bi-arrow-up"
    }
  }

  setActiveKey(key: string, niveau:any) {
    if(this.current_niveau_trie != niveau) this.affiche_icon[this.current_niveau_trie] = { nom_prenom: false, email: false, user: false, password: false }
    for (let prop in this.affiche_icon[niveau]) {
      if (this.affiche_icon[niveau].hasOwnProperty(prop)) {
        this.affiche_icon[niveau][prop] = false;
      }
    }
    if (this.affiche_icon[niveau].hasOwnProperty(key)) {
      this.affiche_icon[niveau][key] = true;
    }
  }

  search_fullname(niveau:any, name_searched:any){
    if(name_searched != ""){
      this.liste_user_dynamique[niveau] = this.liste_user_temp[niveau].filter(t => t.nom_prenom.toLowerCase().includes(name_searched.toLowerCase()))
    } else {
      this.liste_user_dynamique[niveau] = [...this.liste_user[niveau]]
    }
  }
}
