<div class="container-fluid mt-2">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <span class="fs-3">Liste des utilisateurs</span>
        <div class="d-flex flex-row align-items-center justify-content-end">
            <div class="input-group" style="width: 40%;">
                <span class="input-group-text" id="basic-addon1"> <i class="bi bi-search"></i> </span>
                <input type="text" class="form-control me-2" placeholder="Matricule..." [(ngModel)]="matricule_filtre" (keyup)="filtre_matricule()">
                <!-- <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"> -->
            </div>
            <button class="btn btn-sm" style="background-color: #7cc69a5e" (click)="alertAdd()">Ajout d'un nouvel utilisateur</button>
        </div>
    </div>
    <!-- <hr> -->
    <div class="mt-2">
        <table class="table table-bordered" style="vertical-align: middle; text-align: center;">
            <thead>
                <tr class="text-center">
                    <th style="width: 4%;">Matricule</th>
                    <th style="width: 35%;">Nom & prénom</th>
                    <!-- <th style="width: 20%;">Prénoms</th> -->
                    <th style="width: 5%;">Rôle</th>
                    <th style="width: 2%;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let users of liste_user_dynamic">
                    <td>{{users.matricule}}</td>
                    <td>{{users.nom_user + " " +users.prenom_user}}</td>
                    <td>
                        <select class="form-control form-control-sm text-center" [(ngModel)]="users.role_user" [disabled]="(users.role_user == 'Administrateur' && role != 'Administrateur') || users.matricule == matricule_log">
                            <option value="Utilisateur">Utilisateur</option>
                            <option value="Manager">Manager</option>
                            <option value="Responsable">Responsable</option>
                            <option value="Administrateur" [disabled]="role != 'Administrateur'">Administrateur</option>
                        </select>
                    </td>
                    <td>
                        <button class="btn btn-sm" (click)="modifierUser(users)" [disabled]="(users.role_user == 'Administrateur' && role != 'Administrateur') || users.matricule == matricule_log"><i class="bi bi-pen-fill"></i></button>
                        <button class="btn btn-sm ms-2" (click)="supprimerUser(users.id_utilisateur)" *ngIf="role == 'Administrateur'" [disabled]="(users.role_user == 'Administrateur' && role != 'Administrateur') || users.matricule == matricule_log"><i class="bi bi-trash3-fill text-danger"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>