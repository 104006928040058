import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-fichier-reference',
  templateUrl: './fichier-reference.component.html',
  styleUrls: ['./fichier-reference.component.css']
})
export class FichierReferenceComponent {

  constructor(
    private travailleurService: TravailleurService,
    private toast: ToastService,
    private userService:UsersService
  ) {
    this.getUser()
    this.getAllDepartement()
  }

  liste_departement: any = []
  is_uploading: any = false
  is_loading: any = false
  is_downloading: any = false

  role_access:any = [99,10]
  access:any = false
  user_log:any

  getUser(){
    this.userService.getUserService({}).subscribe((data:any) => {
      this.user_log = data
      this.access = this.role_access.includes(this.user_log.role)
    })
  }

  getAllDepartement() {
    this.is_loading = true
    this.travailleurService.getFichierDepartementService().subscribe((data: any) => {
      this.liste_departement = data
      this.liste_departement.forEach(element => {
        element.data_file.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        element.data_file.map(d => d.is_downloading = false)
      });
      this.is_loading = false
    })
  }

  onFilesDropped(files: FileList, departement: any) {
    if(this.access){
      this.is_uploading = true
      const droppedFile = files[0]
      if (droppedFile) {
        const fileType = droppedFile.type
        const fileName = droppedFile.name
        this.is_uploading = true
        var dataObj = { id_departement: departement.id_departement, file: droppedFile }
        this.travailleurService.uploadFileReferenceService(dataObj).subscribe((data: any) => {
          this.toast.Success("Fichier déposé avec succès")
          this.is_uploading = false
          this.getAllDepartement()
        })
      }
    }
    else {
      this.toast.Warning("Partie réservée: Vous ne pouvez pas déposer de fichier.")
    }
  }

  downloadFile(id_fichier: any, id_departement: any, nom_fichier: any, fichier: any) {
    fichier.is_downloading = true
    var dataObj = { id_departement: id_departement, id_fichier_departement: id_fichier, nom_fichier: nom_fichier }
    this.travailleurService.downloadFileService(dataObj).subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = nom_fichier
      saveAs(file, filename);
      fichier.is_downloading = false
    })
  }

  deleteFile(id_fichier: any, fichier: any) {
    Swal.fire({
      title: "Suppression",
      text: "Voulez-vous vraiment supprimer ce fichier ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimé",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        var dataObj = {id_fichier_departement: id_fichier}
        this.travailleurService.deleteFichierDepartementService(dataObj).subscribe(() => {
          this.toast.Success("Fichier supprimé avec succès")
          fichier.id_fichier_departement = null
        })
      }
    });
  }

}
