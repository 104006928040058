<div id="body-login" *ngIf="!onLog">
    <div class="noir"></div>
    <div class="login-temp">
        <div class="p-2 rounded shadow-lg" id="login">
            <div class="px-5 py-5 text-center">
                <img src="assets/images/icons8-dashboard-100 (1).png" class="logo" alt="LOGO" width="130" height="130">
                <div style="margin-top: 16px;"><span id="titreApplication">Gestion des personnels</span></div>
                <div class="mt-5">
                    <div class="input-wrapper">
                        <input autocomplete="off" class="input" type="text" placeholder="33346"
                            [(ngModel)]="matricule" />
                        <label class="label" for="name">Identifiant</label>
                        <button class="clear" aria-label="Clear input" (click)="resetLogin()"> <i
                                class="bi bi-x-lg"></i> </button>
                    </div>
                    <div class="input-wrapper">
                        <input autocomplete="off" class="input" [type]="showPassword ? 'text' : 'password'"
                            placeholder="test" [(ngModel)]="password" />
                        <label class="label" for="name">Mot de passe</label>
                        <button class="clear" aria-label="Clear input" (click)="showPassword = !showPassword"> <i
                                [ngClass]="{'bi bi-eye':showPassword,'bi bi-eye-slash':!showPassword}"></i> </button>
                    </div>
                </div>
                <div class="mt-5">
                    <button class="px-4 py-2" id="btn_connexion" (click)="login()">Connexion</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="onLog">
    <app-body-form></app-body-form>
</div>