import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './page404/page404.component';
import { AccueilComponent } from './accueil/accueil.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { GestionMenuComponent } from './gestion-menu/gestion-menu.component';
import { AppComponent } from './app.component';
import { CalendrierComponent } from './formation/calendrier/calendrier.component';
import { AgendaComponent } from './formation/agenda/agenda.component';
import { TableauFormationComponent } from './formation/tableau-formation/tableau-formation.component';
import { RessourceComponent } from './ressource/ressource.component';
import { PersonnelComponent } from './ressource/personnel/personnel.component';
import { ProfilComponent } from './ressource/profil/profil.component';
import { ListeComponent } from './formation/liste/liste.component';
import { CalendrierTravailleurComponent } from './ressource/calendrier-travailleur/calendrier-travailleur.component';
import { PlanificationComponent } from './planification/planification.component';
import { CompetenceComponent } from './ressource/competence/competence.component';
import { GestionUtilisateurComponent } from './gestion-utilisateur/gestion-utilisateur.component';
import { FichierReferenceComponent } from './autre/fichier-reference/fichier-reference.component';
import { CalendrierFormateurComponent } from './formateur/calendrier-formateur/calendrier-formateur.component';
import { ProfilRhComponent } from './autre/profil-rh/profil-rh.component';
import { PortraitFormateurComponent } from './formateur/portrait-formateur/portrait-formateur.component';
import { HistoriqueJournalComponent } from './autre/historique-journal/historique-journal.component';
import { AuthGuard } from './guard/auth.guard';
import { TableauDisplayComponent } from './ressource/tableau-display/tableau-display.component';
import { PompierComponent } from './preventioniste/pompier/pompier.component';
import { SauveteurComponent } from './preventioniste/sauveteur/sauveteur.component';
import { MesureUrgenceComponent } from './preventioniste/mesure-urgence/mesure-urgence.component';


const routes: Routes = [
  { path: '', component: CalendrierComponent , data: {titreComponent : 'Gestion des formations'}},
  { path: 'accueil', component: AccueilComponent, data: {titreComponent : 'Accueil'}},
  { path: 'users', component: GestionUtilisateurComponent, canActivate:[AuthGuard], data: {titreComponent : 'Gestion des utilisateurs', roles:[99,10]}},
  { path: 'gestion-menu', component: GestionMenuComponent, canActivate:[AuthGuard], data: {titreComponent : 'Gestion de menu', roles:[99]}},
  { path: 'calendrier-formation', component: CalendrierComponent, data: {titreComponent : 'Calendrier des formations'}},
  { path: 'agenda-formation', component: AgendaComponent, data: {titreComponent : 'Agenda de formation'}},
  { path: 'tableau-formation', component: TableauFormationComponent, data: {titreComponent : 'Tableau des formations'}},
  { path: 'ressource', component: RessourceComponent, data: {titreComponent : 'Organigramme'}},
  { path: 'people', component: PersonnelComponent, data: {titreComponent : 'Tableau des ressources'}},
  { path: 'profil-individuel', component: ProfilComponent, data: {titreComponent : 'Profil individuel'}},
  { path: 'liste-formation', component: ListeComponent, data: {titreComponent : 'Formations'}},
  { path: 'calendrier-ressource', component: CalendrierTravailleurComponent, data: {titreComponent : 'Calendrier des rotations'}},
  { path: 'planification-formation', component: PlanificationComponent, data: {titreComponent : 'Planification de formation'}},
  { path: 'competence-travailleur', component: CompetenceComponent, data: {titreComponent : 'Compétence des travailleurs'}},
  { path: 'fichier-reference', component: FichierReferenceComponent, canActivate:[AuthGuard], data: {titreComponent : 'Fichier de référence', roles:[99,10,9,8]}},
  { path: 'calendrier-formateur', component: CalendrierFormateurComponent, data: {titreComponent : 'Calendrier des formateurs'}},
  { path: 'profil-rh', component: ProfilRhComponent, data: {titreComponent : 'Portrait RH'}},
  { path: 'portrait-formateur', component: PortraitFormateurComponent, data: {titreComponent : 'Portrait des formateurs'}},
  { path: 'historique-journal', component: HistoriqueJournalComponent, canActivate:[AuthGuard], data: {titreComponent : 'Historique des données', roles:[99,10]}},
  { path: 'tableau-display', component: TableauDisplayComponent, data: {titreComponent : 'Tableau de formation et procédure'}},
  { path: 'pompier', component: PompierComponent, data: {titreComponent : 'Pompier'}},
  { path: 'sauveteur', component: SauveteurComponent, data: {titreComponent : 'Sauveteur'}},
  { path: 'mesure-urgence', component: MesureUrgenceComponent, data: {titreComponent : 'Mesure d\'urgence'}},
  { path: '**', pathMatch: 'full', component: Page404Component, title: '404 Page'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


