import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  joursSemaine: { jour: string; date: Date }[] = [];
  semaineCourante: Date;
  numeroSemaine: number = 0;
  moisEnFrancais: string = '';
  annee: number = 0;
  taches: any = [
    { task: "Tâche A" },
    { task: "Tâche B" },
    { task: "Tâche C" },
    { task: "Tâche D" }
  ]
  tache_selected: any

  constructor() {
    this.semaineCourante = new Date();
    this.annee = this.semaineCourante.getFullYear();
    this.moisEnFrancais = this.getMoisEnFrancais(this.semaineCourante);
    this.numeroSemaine = this.getNumeroSemaine(this.semaineCourante);
    this.setJoursSemaine();
  }

  ngOnInit(): void {
  }

  backgroundColors: { [key: string]: string } = {};
  onSelectChange(jour: any, formateur: number, heure: number, selectedTask: string) {
    if (selectedTask === 'Tâche A') {
      this.backgroundColors[`${jour.jour}-${jour.date}-${formateur}-${heure}`] = 'lightblue';
    } else if (selectedTask === 'Tâche B') {
      this.backgroundColors[`${jour.jour}-${jour.date}-${formateur}-${heure}`] = 'lightcoral';
    } else if (selectedTask === 'Tâche C') {
      this.backgroundColors[`${jour.jour}-${jour.date}-${formateur}-${heure}`] = 'lightgreen';
    } else if (selectedTask === 'Tâche D') {
      this.backgroundColors[`${jour.jour}-${jour.date}-${formateur}-${heure}`] = 'gainsboro';
    } else {
      this.backgroundColors[`${jour.jour}-${jour.date}-${formateur}-${heure}`] = '';
    }
  }

  setJoursSemaine() {
    const debutSemaine = this.getLundiDeLaSemaine(this.semaineCourante);
    this.joursSemaine = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi'].map((jour, i) => ({
      jour,
      date: new Date(debutSemaine.getTime() + i * 24 * 60 * 60 * 1000),
    }));
  }

  getLundiDeLaSemaine(date: Date): Date {
    const jour = date.getDay();
    const lundi = new Date(date);
    lundi.setDate(date.getDate() - ((jour + 6) % 7));
    return lundi;
  }

  changerSemaine(increment: number) {
    this.semaineCourante.setDate(this.semaineCourante.getDate() + increment * 7);
    this.annee = this.semaineCourante.getFullYear();
    this.moisEnFrancais = this.getMoisEnFrancais(this.semaineCourante);
    this.numeroSemaine = this.getNumeroSemaine(this.semaineCourante);
    this.setJoursSemaine();
  }


  getNumeroSemaine(date: Date): number {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const debutAnnee = new Date(tempDate.getFullYear(), 0, 4);
    const numeroSemaine = Math.floor(
      (tempDate.getTime() - debutAnnee.getTime()) / (7 * 24 * 60 * 60 * 1000) + 1
    );
    if (numeroSemaine === 0) {
      const finAnneePrecedente = new Date(tempDate.getFullYear() - 1, 11, 31);
      return this.getNumeroSemaine(finAnneePrecedente);
    }

    return numeroSemaine;
  }

  getMoisEnFrancais(date: Date): string {
    return new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date);
  }

}
