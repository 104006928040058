<div class="px-2">
  <div class="calendar-container py-1">
    <div class="calendar-header shadow-sm d-flex flex-row align-items-center justify-content-between">
      <div style="margin-top: 5px;">
        <h5 *ngIf="currentView === 'month' || currentView === 'week'">{{ viewDate | date : "MMMM"
          |capitalizeFirstLetter}} {{viewDate | date: "y"}} <span class="text-secondary"
            style="font-size: 12px;">({{nombre_formation_mois}})</span> </h5>
      </div>
      <!-- <div>
        <button (click)="generatePDF()">test</button>
      </div> -->
      <div class="calendar-controls d-flex flex-row align-items-center">
        <div class="me-2">
          <div class="input-group input-group-sm">
            <button class="btn btn-outline-primary" type="button" [disabled]="selected_index_week_to_print == ''"
              (click)="generatePDF()">
              <span *ngIf="!is_printing" style="font-size: 13px;">Imprimer</span>
              <div *ngIf="is_printing" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
            <select class="form-select" id="inputGroupSelect01" [(ngModel)]="selected_index_week_to_print"
              (change)="getWeekToPrint(selected_index_week_to_print)" [disabled]="currentView != 'month'">
              <option selected value="" disabled></option>
              <option *ngFor="let week of weeks; let i = index" [value]="i">la semaine du {{week[1].date |
                date:"yyyy-MM-dd"}} au {{week[5].date | date:"yyyy-MM-dd"}}</option>
              <option value="6" [disabled]="formateur_filtered == ''">les 3 premières semaines du mois</option>
              <option value="7" [disabled]="formateur_filtered == ''">les 3 dernières semaines du mois</option>
              <option value="8" [disabled]="formateur_filtered == ''">toutes les semaines du mois</option>
            </select>
          </div>
        </div>
        <div class="me-2">
          <div class="input-group input-group-sm">
            <label class="input-group-text" for="inputGroupSelect01"> <i
                [ngClass]="{'bi bi-funnel-fill me-1 text-special':formateur_filtered != ''}"
                style="font-size: 12px;"></i> Formateurs</label>
            <select class="form-select" id="inputGroupSelect01" [(ngModel)]="formateur_filtered"
              (change)="filteredByFormateur(formateur_filtered, filtre_status)">
              <option selected value="">Tous</option>
              <option *ngFor="let formateur of formateurs" [value]="formateur.initial">{{formateur.nom_formateur}}
              </option>
            </select>
          </div>
        </div>
        <div style="margin-right: 35px;">
          <div class="input-group input-group-sm">
            <label class="input-group-text" for="inputGroupSelect01">Status</label>
            <select class="form-select" id="inputGroupSelect01" [(ngModel)]="filtre_status"
              (change)="filteredByFormateur(formateur_filtered, filtre_status)">
              <option selected value="0">Tous</option>
              <option selected value="1">Inscription ouverte</option>
            </select>
          </div>
        </div>
        <div>
          <ul class="nav nav-underline">
            <li class="nav-item">
              <a class="nav-link" style="font-size: 13px;" [ngClass]="{'active':currentView == 'month'}"
                aria-current="page" (click)="switchView(CalendarView.Month)">Mois</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" style="font-size: 13px;" [ngClass]="{'active':currentView == 'week'}"
                (click)="switchView(CalendarView.Week)">Semaine</a>
            </li>
          </ul>
        </div>
        <div class="ms-3">
          <button [disabled]="is_loading" mat-icon-button (click)="previous()"><i
              class="bi bi-chevron-left"></i></button>
          <button [disabled]="is_loading" class="btn" (click)="viewToday()">Aujourd'hui</button>
          <button [disabled]="is_loading" mat-icon-button (click)="next()"><i class="bi bi-chevron-right"></i></button>
        </div>
      </div>
    </div>

    <app-load-page *ngIf="is_loading"></app-load-page>
    <div *ngIf="!is_loading" style="overflow: auto; height: 85vh; margin-top: 8px; width: 100%;">
      <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-month" *ngIf="currentView === 'month'">
        <!-- <thead>
          <tr style="position: sticky; top:0; background: none;">
            <th *ngFor="let day of weekDays">
              {{ day }}
            </th>
          </tr>
        </thead> -->
        <tbody class="bg-white">
          <tr *ngFor="let week of weeks">
            <td *ngFor="let date of week" [class.today]="isToday(date.date)"
              [ngClass]="{'notCurrentMonthStyle' : !isCurrentMonth(date.date)}">
              <div class="cell-overlay" tabindex="0"></div>
              <div class="date">{{date.day}} {{ date.date.getDate() }}</div>
              <div class="appointments">
                <ng-container *ngFor="let item of formations_dynamique">
                  <div *ngIf="isSameDate(item.date_prevue, date.date)" [class]="item.class" (click)="openModal(item)"
                    [ngStyle]="{ 'background-color': item.statut_demande == 0 ? item.color : '' }">

                    <!-- Icône positionnée en haut à gauche -->

                    <div *ngIf="item.class == 'open_inscription'" style="margin-bottom: -4px;">
                      <span style="color: white;" class="span-inscription"> <i class="bi bi-pencil-square me-1"
                          style="font-size: 9px;"></i> Inscription ouverte</span>
                    </div>

                    <div>
                      <div style="margin-bottom: -4px;">
                        <span style="font-size: 9px;" *ngIf="item.statut_inscription.includes('compl')">
                          <i class="bi bi-check-circle-fill text-success" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;" *ngIf="item.statut_inscription.includes('progre')">
                          <i class="bi bi-dash-circle-fill text-warning" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="item.statut_inscription.includes('refu') || item.statut_inscription.includes('annu')">
                          <i class="bi bi-x-circle-fill text-primary" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="!item.passe_date && item.statut_inscription.includes('attente')">
                          <i class="bi bi-dash-circle-fill text-secondary" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="item.passe_date && (item.statut_inscription.includes('auto') || item.statut_inscription.includes('attente') )">
                          <i class="bi bi-x-circle-fill text-danger" style="font-size: 8px;"></i> Passé date - A
                          programmer
                        </span>

                        <span style="font-size: 9px;" class="text-primary"
                          *ngIf="item.old_date_prevue_text != null && item.old_date_prevue_text != item.date_prevue_text">
                          <i class="bi bi-arrow-left-right text-primary" style="font-size: 8px;"></i> Initialement
                          prévue le {{item.old_date_prevue_text}}
                        </span>

                      </div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{item.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{item.heure_debut}} -
                          {{item.heure_fin}}</span>
                      </div>
                    </div>
                    <span class="span-formation">{{ item.nom_formation }}</span>
                    <div>
                      <ul>
                        <li *ngFor="let worker of item.travailleurs">
                          {{worker.nom_complet}}
                          <span *ngIf="worker.code_departement != null"
                            style="font-size: 10px;"><{{worker.code_departement}}-{{worker.initialDepartement}}></span>
                          <div *ngIf="worker.nom_sous_departement != null" style="margin-top: -7px;">
                            <span style="font-size: 10px;" class="text-secondary">SD: {{worker.nom_sous_departement |
                              capitalizeFirstLetter}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- CALENDRIER ROTATION -->
              <div *ngIf="is_filtering" class="py-3">
                <ng-container *ngFor="let calendrier of filtre_calendrier_formateur">
                  <div *ngIf="isSameDate(calendrier.date_js, date.date)" class="p-2 shadow-sm"
                    [ngStyle]="{'background-color':calendrier.color_statut}" style="text-align: center;">
                    <span style="font-size: 13px; font-weight: bold;">{{calendrier.libelle_statut.toUpperCase()}}</span>
                  </div>
                </ng-container>
              </div>
              <!-- DEPLACEMENT -->
              <div>
                <ng-container *ngFor="let formation of deplacement_historique">
                  <div *ngIf="isSameDate(formation.old_date_prevue, date.date)" class="close_inscription shadow-sm"
                    style="text-align: center; background-color: rgba(46, 0, 0, 0.085);">
                    <div>
                      <div style="margin-bottom: -4px;">
                        <span style="font-size: 9px;" class="text-danger">
                          <i class="bi bi-arrow-left-right text-danger" style="font-size: 8px;"></i> Formation déplacée
                          le {{formation.date_prevue_text}}
                        </span>
                      </div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{formation.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{formation.heure_debut}} -
                          {{formation.heure_fin}}</span>
                      </div>
                    </div>
                    <span style="font-size:11px;">{{ formation.nom_formation }}</span>
                    <div>
                      <ul>
                        <li *ngFor="let worker of formation.travailleurs">
                          {{worker.nom_complet}}
                          <span *ngIf="worker.code_departement != null"
                            style="font-size: 10px;"><{{worker.code_departement}}-{{worker.initialDepartement}}></span>
                          <div *ngIf="worker.nom_sous_departement != null" style="margin-top: -7px;">
                            <span style="font-size: 10px;" class="text-secondary">SD: {{worker.nom_sous_departement |
                              capitalizeFirstLetter}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- AGENDA ACTIVITE -->
              <div>
                <ng-container *ngFor="let activite of agenda_activite_dynamique">
                  <div *ngIf="isSameDate(activite.date_activite, date.date)" class="close_inscription shadow-sm"
                    [ngStyle]="{ 'background-color': activite.color }">
                    <div style="position: absolute; top: -3px; left: 5px;">
                      <span><i class="bi bi-card-checklist text-secondary" style="font-size: 11px;"></i></span>
                    </div>
                    <div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{activite.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{activite.heure_debut}} -
                          {{activite.heure_fin}}</span>
                      </div>
                    </div>
                    <span style="font-size:11px;">{{ activite.libelle_activite }}</span>
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>


      <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-week" *ngIf="currentView === 'week'">
        <thead>
          <tr style="position: sticky; top:0; background: none;">
            <th></th>
            <th *ngFor="let day of weekDays; index as i" style="font-size: 18px;">
              {{ day }} {{ monthDays[i].getDate() }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let timeSlot of timeSlots">
            <td [width]="10" class="calendar-slot-cell" style="background-color: #f8f8f8;">
              <span class="border rounded shadow-sm px-3">{{ timeSlot }}</span>
            </td>
            <td *ngFor="let day of weekDays; index as i">
              <div tabindex="0" *ngFor="let item of getAppointmentsForDateTime(monthDays[i],timeSlot)"
                class="close_inscription_week" [ngStyle]="{'background-color': item.color }">
                <div style="margin-bottom: -8px;"><span
                    style="font-size: 10px; color: rgb(51, 51, 51);">{{item.initial_formateur}} -
                    {{item.num_formation}}</span></div>
                <span>{{ item.nom_formation }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="screenCalendar" *ngIf="!is_loading" class="p-5" style="margin-top: 500px;">
      <div class="mb-2 d-flex flex-row align-items-center justify-content-between">
        <span class="h3">{{ viewDate | date : "MMMM" |capitalizeFirstLetter}} {{viewDate | date: "y"}} </span>
        <span>Formateur : <span class="h4">{{getFormateurName(formateur_filtered)}}</span></span>
      </div>
      <hr>
      <table width="100%" cellspacing="0" cellpadding="0" class="calendar-view-month border shadow"
        *ngIf="currentView === 'month'">
        <tbody class="bg-white">
          <tr *ngFor="let week of week_to_print">
            <td *ngFor="let date of week" [class.today]="isToday(date.date)">
              <div class="cell-overlay" tabindex="0"></div>
              <div class="date" style="font-size: 17px;">{{date.day}} {{ date.date.getDate() }}</div>
              <div class="appointments">
                <ng-container *ngFor="let item of formations_dynamique">
                  <div *ngIf="isSameDate(item.date_prevue, date.date)" [class]="item.class"
                    style="margin-bottom: 4px; border: solid 1px black;"
                    [ngStyle]="{ 'background-color': item.statut_demande == 0 ? item.color : '' }"
                    (click)="openModal(item)">
                    <div *ngIf="item.class == 'open_inscription'" style="margin-bottom: -4px;">
                      <span style="color: white;" class="span-inscription"> <i class="bi bi-pencil-square me-1"
                          style="font-size: 9px;"></i> Inscription ouverte</span>
                    </div>

                    <div>
                      <div style="margin-bottom: -4px;">
                        <span style="font-size: 9px;" *ngIf="item.statut_inscription.includes('compl')">
                          <i class="bi bi-check-circle-fill text-success" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;" *ngIf="item.statut_inscription.includes('progre')">
                          <i class="bi bi-dash-circle-fill text-warning" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="item.statut_inscription.includes('refu') || item.statut_inscription.includes('annu')">
                          <i class="bi bi-x-circle-fill text-primary" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="!item.passe_date && item.statut_inscription.includes('attente')">
                          <i class="bi bi-dash-circle-fill text-secondary" style="font-size: 8px;"></i>
                          {{item.statut_inscription | capitalizeFirstLetter}}
                        </span>

                        <span style="font-size: 9px;"
                          *ngIf="item.passe_date && (item.statut_inscription.includes('auto') || item.statut_inscription.includes('attente') )">
                          <i class="bi bi-x-circle-fill text-danger" style="font-size: 8px;"></i> Passé date
                        </span>

                        <span style="font-size: 9px;" class="text-primary"
                          *ngIf="item.old_date_prevue_text != null && item.old_date_prevue_text != item.date_prevue_text">
                          <i class="bi bi-arrow-left-right text-primary" style="font-size: 8px;"></i> Initialement
                          prévue le {{item.old_date_prevue_text}}
                        </span>

                      </div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{item.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{item.heure_debut}} -
                          {{item.heure_fin}}</span>
                      </div>
                    </div>
                    <div style="margin-top: -5px;">
                      <span class="span-formation">{{ item.nom_formation }}</span>
                    </div>
                    <div style="margin-bottom: -8px;">
                      <ul>
                        <li *ngFor="let worker of item.travailleurs">
                          {{worker.nom_complet}}
                          <span *ngIf="worker.code_departement != null"
                            style="font-size: 10px;"><{{worker.code_departement}}-{{worker.initialDepartement}}></span>
                          <div *ngIf="worker.nom_sous_departement != null" style="margin-top: -7px;">
                            <span style="font-size: 10px;" class="text-secondary">SD: {{worker.nom_sous_departement |
                              capitalizeFirstLetter}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
               <!-- CALENDRIER ROTATION -->
              <div *ngIf="is_filtering" class="py-3">
                <ng-container *ngFor="let calendrier of filtre_calendrier_formateur">
                  <div *ngIf="isSameDate(calendrier.date_js, date.date)" class="p-2 shadow-sm"
                    [ngStyle]="{'background-color':calendrier.color_statut}" style="text-align: center;">
                    <span style="font-size: 13px; font-weight: bold;">{{calendrier.libelle_statut.toUpperCase()}}</span>
                  </div>
                </ng-container>
              </div>
               <!-- DEPLACEMENT -->
              <div>
                <ng-container *ngFor="let formation of deplacement_historique">
                  <div *ngIf="isSameDate(formation.old_date_prevue, date.date)" class="close_inscription shadow-sm"
                    style="text-align: center; margin-bottom: 4px; margin-top: 4px; border: solid 1px black; background-color: rgba(46, 0, 0, 0.085);">
                    <div>
                      <div style="margin-bottom: -4px;">
                        <span style="font-size: 9px;" class="text-danger">
                          <i class="bi bi-arrow-left-right text-danger" style="font-size: 8px;"></i> Formation déplacée
                          le {{formation.date_prevue_text}}
                        </span>
                      </div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{formation.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{formation.heure_debut}} -
                          {{formation.heure_fin}}</span>
                      </div>
                    </div>
                    <div style="margin-top: -5px;">
                      <span class="span-formation">{{ formation.nom_formation }}</span>
                    </div>
                    <div style="margin-bottom: -8px;">
                      <ul>
                        <li *ngFor="let worker of formation.travailleurs">
                          {{worker.nom_complet}}
                          <span *ngIf="worker.code_departement != null"
                            style="font-size: 10px;"><{{worker.code_departement}}-{{worker.initialDepartement}}></span>
                          <div *ngIf="worker.nom_sous_departement != null" style="margin-top: -7px;">
                            <span style="font-size: 10px;" class="text-secondary">SD: {{worker.nom_sous_departement |
                              capitalizeFirstLetter}}</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- AGENDA ACTIVITE -->
              <div>
                <ng-container *ngFor="let activite of agenda_activite_dynamique">
                  <div *ngIf="isSameDate(activite.date_activite, date.date)" class="close_inscription shadow-sm"
                    [ngStyle]="{ 'background-color': activite.color }" style="text-align: center; margin-bottom: 4px; margin-top: 4px; border: solid 1px black;">
                    <div>
                      <div style="position: absolute; top: -3px; left: 5px;">
                        <span><i class="bi bi-card-checklist text-secondary" style="font-size: 11px;"></i></span>
                      </div>
                      <div style="margin-bottom: -6px;">
                        <span style="font-size: 10px; color: rgb(51, 51, 51);"
                          class="fw-bold">{{activite.initial_formateur}}</span>
                        <span style="font-size: 10px; color: rgb(51, 51, 51);" class="ms-2">{{activite.heure_debut}} -
                          {{activite.heure_fin}}</span>
                      </div>
                    </div>
                    <div style="margin-top: -5px; margin-bottom: 5px;">
                      <span style="font-size:11px;" class="span-formation">{{ activite.libelle_activite }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>