import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { FileService } from 'src/app/services/file/file.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import Handsontable from "handsontable";
import 'handsontable/languages/fr-FR';
import { CookieService } from 'ngx-cookie-service';
import { CryptageService } from 'src/app/services/cryptage/cryptage.service';
import { DateTime } from 'luxon'
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-demande-externe',
  templateUrl: './demande-externe.component.html',
  styleUrls: ['./demande-externe.component.css']
})
export class DemandeExterneComponent {

  constructor(
    private fileService: FileService,
    private toast: ToastService,
    private calendrierService: CalendrierService,
    private cryptage: CryptageService
  ) {
    this.getColonneName()
  }

  is_loading: any = false
  is_uploading: any = false
  demande_externe_dynamique: any = []
  demande_externe_temp: any = []
  demande_externe: any = []

  @ViewChild('tableContainer', { static: true }) tableContainer: ElementRef;
  hot: any
  headers: any = []
  is_updating: any = false
  is_downloading: any = false

  getColonneName() {
    this.calendrierService.getColonneDemandeExterne().subscribe((data) => {
      this.headers = data
      this.headers.forEach((h, i) => {
        h.to_ui = `<label style='font-size:13px; font-weight:bold;'>${h.libelle_colonne}</label>`
      })
      this.getDemandeExterne()
    }, err => {}, () => {
      var data = sessionStorage.getItem('data_column_demande_externe')
      if (data == null || data == '' || data == undefined) sessionStorage.setItem('data_column_demande_externe', this.cryptage.encryptEncode(JSON.stringify(this.headers)))
    })
  }

  onFilesDropped(files: FileList) {
    this.is_uploading = true
    const droppedFile = files[0]
    if (droppedFile) {
      var dataObj = { file: droppedFile }
      this.fileService.uploadDemandeExterneService(dataObj).subscribe((data: any) => {
        this.toast.Success("Données importée")
        this.is_uploading = false
        this.getDemandeExterne()
      })
    }
  }

  getDemandeExterne() {
    this.is_loading = true
    this.calendrierService.getDemandeExterneService().subscribe((data: any) => {
      this.demande_externe = data
      this.demande_externe_dynamique = [...this.demande_externe]
      this.demande_externe_temp = [...this.demande_externe]
      const savedFilters = sessionStorage.getItem('handsontable_filters_demande_externe');
      const savedSortConfig = sessionStorage.getItem('handsontable_sortingColumnConfig_demande_externe')
      const savedHiddenColumn = sessionStorage.getItem('data_column_demande_externe')
      var condition = []
      var sortConfig = []
      var columnsHidden = []
      if (savedFilters) condition = JSON.parse(this.cryptage.decryptDecode(savedFilters));
      if (savedSortConfig) sortConfig = JSON.parse(this.cryptage.decryptDecode(savedSortConfig))
      if (savedHiddenColumn) {
        var columns = JSON.parse(this.cryptage.decryptDecode(savedHiddenColumn))
        this.headers = [...columns]
        columns.forEach((c, i) => {
          if (c.afficher == false) columnsHidden.push(i)
        })
      }
      if (this.hot) {
        this.hot.updateSettings({
          colHeaders: this.headers.map(h => h.to_ui)
        })
        this.hot.loadData(data);
        this.hot.render();
        this.applyFilter(condition)
        this.is_loading = false
        return;
      }
      this.Maketable(this.demande_externe_dynamique, this.headers.map(h => h.to_ui), condition, sortConfig, columnsHidden)
    })
  }

  Maketable(data: any, headers: any = null, condition: any, sortConfig: any, columnsHidden: any) {
    this.hot = new Handsontable(this.tableContainer.nativeElement, {
      language: 'fr-FR',
      data: data,
      width: '100%',
      colHeaders: headers,
      // readOnly: !this.is_loading,
      rowHeaders: true,
      cells: function (row, col, prop) {
        const cellProperties: any = {};
        cellProperties.renderer = (instance, td, row, col, prop, value) => {
          td.innerHTML = value;
          td.style.verticalAlign = 'middle'
          td.style.textAlign = 'center';
          td.style.fontSize = '13px';
        }
        switch (col) {
          case 1: // Date demande
            cellProperties.type = 'date';
            cellProperties.dateFormat = 'YYYY-MM-DD';
            break;
        }
        return cellProperties;
      },
      columns: [
        { data: "id_demande_externe" },
        { data: "date_demande" },
        { data: "num_demande" },
        { data: "statut_demande" },
        { data: "demandeur" },
        { data: "formation" },
        { data: "cout" },
        { data: "apzz" },
        { data: "travailleur" },
        { data: "matricule" },
        { data: "fonction" },
        { data: "statut_inscription" },
        { data: "departement" },
        { data: "cout_w" },
        { data: "sur_temps" },
        { data: "remunere" },
        { data: "commentaire" }
      ],
      hiddenColumns: {
        columns: columnsHidden,
        indicators: true
      },
      contextMenu: {
        items: {
          row_above: {
            name: 'Insérer une ligne'
          },
          separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
          remove_row: {
            name: 'Supprimer la ligne'
          }
        }
      },
      filters: true,
      dropdownMenu: ['filter_by_condition', '---------', 'filter_operators', 'filter_by_value', 'filter_action_bar'],
      manualRowMove: false,
      manualColumnMove: false,
      columnSorting: {
        initialConfig: sortConfig[0]
      },
      stretchH: 'all',
      licenseKey: 'non-commercial-and-evaluation',
      // autoColumnSize: {
      //   useHeaders: false
      // },
      autoColumnSize: true,
      manualColumnResize: true,
      afterFilter: (conditions) => {
        var stringCondition = this.cryptage.encryptEncode(JSON.stringify(conditions))
        sessionStorage.setItem('handsontable_filters_demande_externe', stringCondition)
      },
      afterColumnSort(currentSortConfig, destinationSortConfigs) {
        var stringSortConfig = this.cryptage.encryptEncode(JSON.stringify(destinationSortConfigs))
        sessionStorage.setItem('handsontable_sortingColumnConfig_demande_externe', stringSortConfig)
      },
      afterChange: (changes, source) => {
        if (!changes || this.is_updating || this.is_loading) return;
        let dataObj = { updates: [], ids: [] };
        let nombre_data = changes.length
        if (nombre_data > 1 || nombre_data == 1) {
          if (changes[0][1] == changes[nombre_data - 1][1]) {
            var row = changes[0][0]
            var col = changes[0][1]
            var oldValue = changes[0][2]
            var newValue = changes[0][3]

            this.is_updating = true
            switch (col) {
              default:
                dataObj.updates.push({ [col]: newValue })
                break
            }

            changes.forEach(change => {
              const updatedData = this.hot.getData()[change[0]];
              const id_travailleur = updatedData[0]
              dataObj.ids.push(id_travailleur)
            });

            this.is_updating = false
            this.is_loading = false
          }
        }
        this.calendrierService.updateDemandeExterneService(dataObj).subscribe((data: any) => { })
      },
      afterCreateRow: (index, amount) => {
        var dataObj = { traitement: 1 }
        this.calendrierService.traitementDemandeExterneService(dataObj).subscribe((data: any) => {
          this.getDemandeExterne()
        })
      },
      beforeRemoveRow: (index, amount, physicalRows, source) => {
        var ids = []
        physicalRows.forEach((row) => {
          var id_demande_externe = this.hot.getDataAtCell(row, 0)
          ids.push(id_demande_externe)
        })
        var combinedIds = ids.join(',')
        var dataObj = { ids: combinedIds, traitement: 2 }
        this.calendrierService.traitementDemandeExterneService(dataObj).subscribe(() => { })
      },
    })

    this.applyFilter(condition)

    const thisHot: Handsontable = this.hot;
    Handsontable.hooks.add('modifyColWidth', function (width) {
      const x: any = thisHot.getPlugin('dropdownMenu').menu;
      if (this === x.hotMenu) {
        return 300;
      }
      return width;
    });

    this.is_loading = false
  }

  applyFilter(condition: any) {
    const filtersPlugin = this.hot.getPlugin('filters');
    condition.forEach((cond, index) => {
      var column = cond.column
      var operation = cond.operation
      var data_condition = cond.conditions
      var name = data_condition[0].name
      var args = data_condition[0].args
      filtersPlugin.addCondition(column, name, args, operation);
    })
    filtersPlugin.filter();
  }

  resetFilter() {
    const filtersPlugin = this.hot.getPlugin('filters');
    filtersPlugin.clearConditions()
    filtersPlugin.filter()
  }

  hideShowColumn(index: any) {
    const hiddenColumnsPlugin = this.hot.getPlugin('hiddenColumns')
    if (hiddenColumnsPlugin.isHidden(index)) {
      hiddenColumnsPlugin.showColumn(index)
    } else {
      hiddenColumnsPlugin.hideColumn(index)
    }
    sessionStorage.setItem('data_column_demande_externe', this.cryptage.encryptEncode(JSON.stringify(this.headers)))
    this.hot.render()
  }

  exporterTableau() {
    this.is_downloading = true
    const current_date = DateTime.fromJSDate(new Date()).setZone('UTC').toFormat("yyyy-MM-dd")
    this.calendrierService.exportDemandeExterneService().subscribe((data: any) => {
      const file = new Blob([data], { type: data.type });
      const filename = `${current_date} Demande de formation externe`
      saveAs(file, filename);
      this.is_downloading = false
    })
  }

  changeColonneName(colonne: any) {
    var dataObj = { id_colonne: colonne.id_colonne, libelle_colonne: colonne.libelle_colonne }
    this.calendrierService.traitementColonneDemandeExterneService(dataObj).subscribe(() => {
      sessionStorage.removeItem('data_column_demande_externe')
      this.getColonneName()
    })
  }

}
