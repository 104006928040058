<div class="py-1 px-4">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <div>
            <button (click)="changerSemaine(-1)" mat-icon-button><i class="bi bi-chevron-left"></i></button>
            <span> Semaine {{numeroSemaine}}</span>
            <button (click)="changerSemaine(1)" mat-icon-button><i class="bi bi-chevron-right"></i></button>
        </div>
        <div>
            <span class="h4">{{moisEnFrancais | capitalizeFirstLetter}} {{annee}}</span>
        </div>
    </div>

    <div *ngFor="let jour of joursSemaine" class="mt-3">
        <table class="table table-hover table-sm table-bordered" style="vertical-align: middle; text-align: center;">
            <thead>
                <tr>
                    <th>{{ jour.jour }} {{ jour.date | date: 'dd/MM' }}</th>
                    <th style="width: 20%;">07:00</th>
                    <th style="width: 20%;">10:00</th>
                    <th style="width: 20%;">13:00</th>
                    <th style="width: 20%;">15:00</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let formateur of [1, 2, 3, 4, 5]">
                    <td style="font-size: 13px;">Formateur #{{ formateur }}</td>
                    <td *ngFor="let heure of [7, 10, 13, 15]" style="padding: 0; font-size: 13px;">
                        <ng-container>
                            <!-- <select
                                class="form-select form-select-sm border-0 rounded-0"
                                [ngStyle]="{'background-color': backgroundColors[jour.jour + '-' + jour.date + '-' + formateur + '-' + heure]}"
                                (change)="onSelectChange(jour, formateur, heure, $event.target.value)">
                                <option value="" selected disabled></option>
                                <option *ngFor="let item of taches">{{item.task}}</option>
                            </select> -->
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>