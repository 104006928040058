import { color } from '@amcharts/amcharts5';
import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendrierService } from 'src/app/services/calendrier/calendrier.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TravailleurService } from 'src/app/services/travailleur/travailleur.service';
import { UsersService } from 'src/app/services/users/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-calendrier-formateur',
  templateUrl: './calendrier-formateur.component.html',
  styleUrls: ['./calendrier-formateur.component.css']
})
export class CalendrierFormateurComponent {

  current_year: any = DateTime.now().year
  annee_liste: any = [{ annee: 0 }];
  formateurs: any = []

  // statuts:any = [
  //   {value:"J", color:"white", libelle:"Travaille"},
  //   {value:"T", color:"lightgreen", libelle:"Télétravail"},
  //   // {value:"C", color:"lightgray", libelle:"Congé"},
  //   {value:"A", color:"lightcoral", libelle:"Absent"},
  //   // {value:"V", color:"lightblue", libelle:"Vacance"},
  // ]

  statuts: any = []
  form_statut: any = {
    value: "",
    color: "#707fdd",
    libelle: ""
  }
  is_saving: any = false

  dataStatutCalendrier: any = []
  is_editing: any = false
  is_loading: any = false

  form_planification: any = {
    date_debut: DateTime.now().toFormat("yyyy-MM-dd"),
    date_fin: "",
    formateur_selected: "",
    activite: ""
  }
  is_verifing: any = false

  role_access: any = [99, 10, 9]
  access: any = false
  user_log: any

  getUser() {
    this.userService.getUserService({}).subscribe((data: any) => {
      this.user_log = data
      this.access = this.role_access.includes(this.user_log.role)
    })
  }

  constructor(
    private travailleurService: TravailleurService,
    private calendrierService: CalendrierService,
    private toast: ToastService,
    private userService: UsersService
  ) {
    this.getUser()
    this.getData()
  }

  prev_year() {
    this.current_year -= 1
    this.getData()
  }

  next_year() {
    this.current_year += 1
    this.getData()
  }

  getStatutCalendrierFormateur() {
    var dataObj = {
      date_debut: DateTime.fromObject({ year: this.current_year, month: 1, day: 1 }).setZone('UTC').toFormat("yyyy-MM-dd"),
      date_fin: DateTime.fromObject({ year: this.current_year + 1, month: 2, day: 1 }).setZone('UTC').toFormat("yyyy-MM-dd")
    }
    this.calendrierService.getStatutCalendrierFormateurService(dataObj).subscribe((data: any) => {
      data.forEach(d => d.jour_text = DateTime.fromISO(d.jour).setZone('UTC').toFormat("yyyy-MM-dd"))
      this.dataStatutCalendrier = data
    })
  }

  getListeStatutCalendrierFormateur() {
    this.calendrierService.getListeStatutCalendrierFormateurService().subscribe((data: any) => {
      this.statuts = data
      // console.log(this.statuts)
    })
  }

  getData() {
    this.is_loading = true
    this.makeData()
    this.getStatutCalendrierFormateur()
    const date01Fev = DateTime.fromObject({ year: this.current_year + 1, month: 2, day: 1 });
    var dataObj = { filtre: { fin: date01Fev.setZone('UTC').toFormat("dd/MM/yyyy"), formateur: true } }
    this.travailleurService.getCalendrierTravailleurService(dataObj).subscribe((data: any) => {
      this.formateurs = data
      // console.log(this.formateurs)
      var jours_to_affcher = this.annee_liste.flatMap(a => a.months.flatMap(m => m.jours))
      this.formateurs.forEach((worker, index) => {
        var dataCalendrier = this.dataStatutCalendrier.filter(d => d.id_formateur == worker.id_formateur)
        for (let index_day = worker.days.length - 1; index_day >= 0; index_day--) {
          worker.days[index_day].color = ""
          var day = worker.days[index_day];
          var day_year = parseInt(day.date.split('-')[0]);
          if (day_year < this.current_year) { worker.days.splice(index_day, 1); }
          let result_verification = dataCalendrier.filter(dc => dc.jour_text == day.date)
          // console.log(day.date)
          if (result_verification.length > 0) {
            worker.days[index_day].valeur = result_verification[0].value
          }
        }
        jours_to_affcher.forEach(j => {
          const exists = worker.days.some(day => day.date == j.date)
          if (!exists) { worker.days.push({ date: j.date, valeur: '', color: '#f8f8f8' }) }
        })
        worker.days = worker.days.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      })
      // console.log(this.formateurs)
      this.setColorStatut()
      this.is_loading = false
    })
  }

  setColorStatut() {
    this.formateurs.flatMap(f => f.days).filter(f => f.color == '').forEach(data => {
      data.color = this.statuts.filter(s => s.value == data.valeur)[0].color
    })
  }

  makeData() {
    this.annee_liste[0].annee = this.current_year
    this.annee_liste.forEach(y => {
      y.days = this.getDaysInYear(y.annee);
      y.months = this.construireMois(y.annee);
    });
    this.getListeStatutCalendrierFormateur()
  }

  getDaysInYear(year: number): number {
    const startOfYear = DateTime.fromObject({ year: year, month: 1, day: 1 });
    const endOfYear = startOfYear.endOf('year');
    const daysInYear = endOfYear.diff(startOfYear, 'days').days;
    return Math.round(daysInYear);
  }

  getDaysInMonth(year: number, month: number): number {
    const startOfMonth = DateTime.fromObject({ year: year, month: month, day: 1 });
    const endOfMonth = startOfMonth.endOf('month');
    const daysInMonth = endOfMonth.day;
    return daysInMonth;
  }

  construireMois(year: any) {
    let mois_liste = [
      { annee: year, mois: "Janvier", nombre_jour: this.getDaysInMonth(year, 1), jours: this.getJoursMois(year, 1), color: this.getRandomColor() },
      { annee: year, mois: "Février", nombre_jour: this.getDaysInMonth(year, 2), jours: this.getJoursMois(year, 2), color: this.getRandomColor() },
      { annee: year, mois: "Mars", nombre_jour: this.getDaysInMonth(year, 3), jours: this.getJoursMois(year, 3), color: this.getRandomColor() },
      { annee: year, mois: "Avril", nombre_jour: this.getDaysInMonth(year, 4), jours: this.getJoursMois(year, 4), color: this.getRandomColor() },
      { annee: year, mois: "Mai", nombre_jour: this.getDaysInMonth(year, 5), jours: this.getJoursMois(year, 5), color: this.getRandomColor() },
      { annee: year, mois: "Juin", nombre_jour: this.getDaysInMonth(year, 6), jours: this.getJoursMois(year, 6), color: this.getRandomColor() },
      { annee: year, mois: "Juillet", nombre_jour: this.getDaysInMonth(year, 7), jours: this.getJoursMois(year, 7), color: this.getRandomColor() },
      { annee: year, mois: "Aôut", nombre_jour: this.getDaysInMonth(year, 8), jours: this.getJoursMois(year, 8), color: this.getRandomColor() },
      { annee: year, mois: "Septembre", nombre_jour: this.getDaysInMonth(year, 9), jours: this.getJoursMois(year, 9), color: this.getRandomColor() },
      { annee: year, mois: "Octobre", nombre_jour: this.getDaysInMonth(year, 10), jours: this.getJoursMois(year, 10), color: this.getRandomColor() },
      { annee: year, mois: "Novembre", nombre_jour: this.getDaysInMonth(year, 11), jours: this.getJoursMois(year, 11), color: this.getRandomColor() },
      { annee: year, mois: "Décembre", nombre_jour: this.getDaysInMonth(year, 12), jours: this.getJoursMois(year, 12), color: this.getRandomColor() },
      { annee: year + 1, mois: "Janvier", nombre_jour: this.getDaysInMonth(year + 1, 1), jours: this.getJoursMois(year + 1, 1), color: this.getRandomColor() },
    ];
    return mois_liste;
  }

  getJoursMois(year: number, month: number) {
    const joursSemaine = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    let jours = [];
    const numJours = this.getDaysInMonth(year, month);
    for (let i = 1; i <= numJours; i++) {
      const jour = DateTime.fromObject({ year: year, month: month, day: i });
      const libelle = joursSemaine[jour.weekday - 1];
      const dateIso = jour.toISODate();
      jours.push({ num: i, libelle: libelle, date: dateIso });
    }
    return jours;
  }

  getRandomColor(): string {
    const r = Math.floor(Math.random() * 100);
    const g = Math.floor(Math.random() * 150);
    const b = 150 + Math.floor(Math.random() * 106);
    const a = 0.3;
    return `rgba(${r},${g},${b},${a})`;
  }

  changeStateDays(index_formateur: any, index_days: any) {
    if (this.access) {
      this.is_editing = true
      let index_statut = this.statuts.findIndex(s => s.value == this.formateurs[index_formateur].days[index_days].valeur)
      var index_next_statut = index_statut + 1 == this.statuts.length ? 0 : index_statut + 1
      var dataObj = {
        id_formateur: this.formateurs[index_formateur].id_formateur,
        jour: this.formateurs[index_formateur].days[index_days].date,
        statut: this.statuts[index_next_statut].value,
        id_statut: this.statuts[index_next_statut].id_statut
      }
      this.calendrierService.setStatutCalendrierFormateurService(dataObj).subscribe((data: any) => {
        this.formateurs[index_formateur].days[index_days].valeur = this.statuts[index_next_statut].value
        this.formateurs[index_formateur].days[index_days].color = this.statuts[index_next_statut].color
        this.is_editing = false
      })
    }
  }

  resetFormPlanification() {
    this.form_planification = {
      date_debut: DateTime.now().toFormat("yyyy-MM-dd"),
      date_fin: "",
      formateur_selected: "",
      activite: ""
    }
    this.is_verifing = false
  }

  savePlanification() {
    this.is_verifing = true
    if (this.form_planification.date_debut == "" || this.form_planification.date_fin == "") return
    if (this.form_planification.formateur_selected == "" || this.form_planification.activite == "") return
    var days_formateur = this.formateurs.filter(f => f.id_formateur == this.form_planification.formateur_selected)[0].days
    this.generateDatesInRange(this.form_planification.date_debut, this.form_planification.date_fin).forEach(jour => {
      const day = days_formateur.find(d => d.date == jour)
      if (day && day.valeur != '') {
        var dataObj = {
          id_formateur: this.form_planification.formateur_selected,
          jour: jour,
          statut: this.form_planification.activite,
          id_statut : this.statuts.find(s => s.value == this.form_planification.activite).id_statut
        }
        this.calendrierService.setStatutCalendrierFormateurService(dataObj).subscribe(() => { })
        day.valeur = this.form_planification.activite
        day.color = this.statuts.filter(s => s.value == day.valeur)[0].color
      }
    })
    this.is_verifing = false
    this.resetFormPlanification()
    this.toast.Success("Donnée enregistrée avec succès")
  }

  generateDatesInRange(start: string, end: string): string[] {
    const startDate = DateTime.fromISO(start);
    const endDate = DateTime.fromISO(end);
    var datesInRange = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      datesInRange.push(currentDate.toISODate());
      currentDate = currentDate.plus({ days: 1 });
    }
    return datesInRange
  }

  saveNewStatut() {
    this.is_saving = true
    if (this.form_statut.value == "" || this.form_statut.libelle == "") return
    this.form_statut.traitement = 1
    this.calendrierService.traitementListeStatutCalendrierFormateurService(this.form_statut).subscribe((data: any) => {
      this.toast.Success("Donnée enregistrée")
      this.statuts.push({ value: this.form_statut.value, color: this.form_statut.color, libelle: this.form_statut.libelle, id_statut: data[0].id_statut })
      this.resetFormNewStatut()
      this.is_saving = false
    })
  }

  resetFormNewStatut() {
    this.form_statut = {
      value: "",
      color: "#707fdd",
      libelle: ""
    }
    this.is_saving = false
  }

  deleteStatut(id: any, index: any) {
    var dataObj = { traitement: 2, id_statut: id }
    Swal.fire({
      title: "Suppressin",
      text: "Voulez-vous vraiment supprimer ce statut ? \n ATTENTION: Tous les plannings liés à ce statut seront supprimés.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, supprimée",
      cancelButtonText: "Non, annulé",
    }).then((result) => {
      if (result.isConfirmed) {
        this.calendrierService.traitementListeStatutCalendrierFormateurService(dataObj).subscribe(() => {
          this.statuts.splice(index, 1)
          this.toast.Success("Donnée supprimée")
          this.getData()
        })
      }
    });
  }

  updateStatut(statut:any) {
    if(statut.libelle == "" || statut.value == "") return
    statut.traitement = 3
    this.calendrierService.traitementListeStatutCalendrierFormateurService(statut).subscribe(() => {
      this.toast.Success("Donnée modifiée")
      this.getData()
    })
  }

}
