<app-load-page *ngIf="is_loading"></app-load-page>
<div class="px-2" *ngIf="!is_loading">
    <div class="d-flex flex-row align-items-center justify-content-between mt-2">
        <div class="d-flex flex-row align-items-center justify-content-between">
            <div class="d-flex flex-column align-items-left px-3 py-1 bg-white rounded border shadow-sm me-3">
                <ng-container *ngFor="let item of liste_statut_competence; let i = index">
                    <div class="d-flex flex-row align-items-center">
                        <div [ngStyle]="{'background-color':item.color}" class="rounded border ms-1" style="width: 15px; height: 15px;"></div>
                        <span style="font-size: 11px;" class="ms-2">{{item.libelle_etat}}</span> 
                    </div>
                </ng-container>
            </div>
            <div class="form-floating">
                <select class="form-select" id="floatingSelect" aria-label="Floating label select example" [(ngModel)]="departement" (change)="viewDataCompetence(departement)">
                    <option selected value="" disabled>Veuillez sélectionner un département</option>
                    <option *ngFor="let departement of liste_departement" [value]="departement.id_departement">{{departement.code_organisation}} - {{departement.nom_departement}}</option>
                </select>
                <label for="floatingSelect">Département</label>
            </div>
            <div class="ms-3">
                <button class="btn-special me-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" *ngIf="access" [hidden]="liste_tab.length == 0"> <i class="bi bi-person-add me-1" style="font-size: 12px;"></i> Ajouter un travailleur</button>
                <button class="btn-special me-1" data-bs-toggle="modal" data-bs-target="#param_formation" *ngIf="access" [hidden]="liste_tab.length == 0"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Formations </button>
                <button class="btn-special" data-bs-toggle="modal" data-bs-target="#onglet_param" [hidden]="departement == ''" *ngIf="access"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Onglets </button>
            </div>
            <div class="ms-3">
                <ul class="nav nav-tabs">
                    <ng-container *ngFor="let tab of liste_tab; let i = index">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{'active':i == 0}" data-bs-toggle="tab"
                                [href]="'#onglet'+tab.id_onglet">{{tab.libelle}}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
        <!-- <ng-template #popLegende>
            <div class="d-flex flex-column align-items-left px-3 py-1 bg-white rounded border shadow-sm">
                <ng-container *ngFor="let item of liste_statut_competence; let i = index">
                    <div class="d-flex flex-row align-items-center">
                        <div [ngStyle]="{'background-color':item.color}" class="rounded border ms-1" style="width: 15px; height: 15px;"></div>
                        <span style="font-size: 11px;" class="ms-2">{{item.libelle_etat}}</span> 
                    </div>
                </ng-container>
            </div>
        </ng-template> -->
        <div class="d-flex flex-row align-items-center justify-content-between">
            <div>
                <!-- <button class="btn-special" [ngbPopover]="popLegende" placement="left" [autoClose]="'outside'" [hidden]="liste_tab.length == 0"> <i class="bi bi-eye me-1" style="font-size: 12px;"></i> Légende</button> -->
                <!-- <button class="btn-special me-1 ms-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" *ngIf="access" [hidden]="liste_tab.length == 0"> <i class="bi bi-person-add me-1" style="font-size: 12px;"></i> Ajouter un travailleur</button> -->
                <!-- <button class="btn-special me-1" data-bs-toggle="modal" data-bs-target="#param_formation" *ngIf="access" [hidden]="liste_tab.length == 0"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Formations </button> -->
            </div>
            <!-- <div class="d-flex flex-column align-items-left px-3 py-1 bg-white rounded border shadow-sm">
                <ng-container *ngFor="let item of liste_statut_competence; let i = index">
                    <div class="d-flex flex-row align-items-center">
                        <div [ngStyle]="{'background-color':item.color}" class="rounded border ms-1" style="width: 15px; height: 15px;"></div>
                        <span style="font-size: 11px;" class="ms-2">{{item.libelle_etat}}</span> 
                    </div>
                </ng-container>
            </div> -->
        </div>
    </div>

    <div>
        <div class="tab-content">
            <ng-container *ngFor="let tab of liste_tab; let t = index">
                <div class="tab-pane fade" [ngClass]="{'show active':t == 0}" [id]="'onglet'+tab.id_onglet">
                    <div style="overflow: auto; height: 80vh;" class="mt-2">
                        <table class="table table-sm table-bordered table-hover" style="text-align: center;">
                            <thead class="sticky-row">
                                <tr>
                                    <th style="width: 5%;" class="sticky-column" rowspan="2" colspan="1"
                                        (click)="trieParKey('nom_prenom',t)">Employé <i [class]="icon_trie"
                                            *ngIf="affiche_icon.nom_prenom"></i></th>
                                    <th style="width: 5%;" rowspan="2" colspan="1">Permis de conduire</th>
                                    <th style="width: 5%;" rowspan="2" colspan="1">Procédure</th>
                                    <ng-container *ngFor="let item of formations; let i = index">
                                        <th *ngIf="item.type_detail.length > 0" [attr.colspan]="item.col" [attr.rowspan]="item.row">
                                            {{item.libelle_formation}} </th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <ng-container *ngFor="let item of liste_type">
                                        <th style="width: 3%;">{{item}}</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let worker of tab.data; let y = index">
                                    <td class="sticky-column" id="nom-complet">
                                        <span>{{worker.nom_prenom}}</span> <i class="bi bi-trash text-danger"
                                            id="icon-trash" (click)="deleteTravailleur(worker, y, t)" *ngIf="access"></i>
                                    </td>
                                    <td style="padding: 0;">
                                        <input type="text"
                                            class="form-control text-center form-control-sm padding:0; margin:0; border-0"
                                            [(ngModel)]="worker.permis" style="background: transparent;"
                                            (change)="setDataTravailleur(worker, worker.permis, 'permis')" [readOnly]="!access">
                                    </td>
                                    <td style="padding: 0;">
                                        <input type="text"
                                            class="form-control text-center form-control-sm padding:0; margin:0; border-0"
                                            [(ngModel)]="worker.procedure" style="background: transparent;"
                                            (change)="setDataTravailleur(worker, worker.procedure, 'procedure')" [readOnly]="!access">
                                    </td>
                                    <ng-container *ngFor="let item of worker.niveau; let i = index">
                                        <td [ngStyle]="{'background-color':getColor(item.code)+'70'}"
                                            style="padding: 0;">
                                            <div class="d-flex flex-row justify-content-between align-items-center">
                                                <select class="form-select form-select-sm select-small-arrow"
                                                    [ngStyle]="{'background-color':getColor(item.code)+'10'}"
                                                    [(ngModel)]="item.code"
                                                    style="width: 90%; border: none; border-radius: 0; margin-top: 1px;"
                                                    (change)="setCode(worker, i, item.code, item.commentaire)" [disabled]="!access">
                                                    <option *ngFor="let item of liste_statut_competence"
                                                        [value]="item.code" [ngStyle]="{'background-color':item.color}">
                                                    </option>
                                                </select>
                                                <ng-template #popContent>
                                                    <textarea class="textarea-popover border-0 form-control"
                                                        [(ngModel)]="item.commentaire"
                                                        (change)="setCode(worker, i, item.code, item.commentaire)"
                                                        placeholder="Laisser votre commentaire ici..." rows="4"
                                                        cols="40" [readOnly]="!access" *ngIf="access || item.commentaire != ''"></textarea>
                                                    </ng-template>
                                                <i class="me-2 ms-2"
                                                    [ngClass]="{'bi bi-chat-left-quote':(item.commentaire == '' || item.commentaire == null), 'bi bi-chat-left-quote-fill':(item.commentaire != '' && item.commentaire != null)}"
                                                    style="font-size: 11px;" [autoClose]="'outside'" container="body"
                                                    popoverClass="my-custom-class" [ngbPopover]="popContent"
                                                    popoverTitle="Commentaire"></i>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<!-- Modal TRAVAILLEUR -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Ajout d'un travailleur</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-2">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-floating mb-2">
                                <input type="text" class="form-control" id="nom" placeholder="name@example.com"
                                    [ngClass]="{'is-invalid':is_saving && form_travailleur.nom_travailleur == ''}"
                                    [(ngModel)]="form_travailleur.nom_travailleur">
                                <label for="nom">Nom travailleur *</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="prenom" placeholder="Password"
                                    [ngClass]="{'is-invalid':is_saving && form_travailleur.prenom_travailleur == ''}"
                                    [(ngModel)]="form_travailleur.prenom_travailleur">
                                <label for="prenom">Prénom travailleur *</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating mb-2">
                                <input type="date" class="form-control" id="date" placeholder="name@example.com"
                                    [(ngModel)]="form_travailleur.date_naissance">
                                <label for="date">Date naissance</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-floating">
                                <select class="form-select" id="floatingSelect"
                                    aria-label="Floating label select example" [(ngModel)]="form_travailleur.equipe"
                                    [ngClass]="{'is-invalid':is_saving && form_travailleur.equipe == ''}">
                                    <option selected disabled value="">Veuillez selectionner l'equipe</option>
                                    <option *ngFor="let tab of liste_tab" [value]="tab.id_onglet">{{tab.libelle}}</option>
                                </select>
                                <label for="floatingSelect">Equipe *</label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <button class="btn btn-sm btn-success me-2" (click)="addTravailleur()"> <i
                                class="bi bi-save me-1" style="font-size: 12px;"></i> Enregistrer</button>
                        <button class="btn btn-sm btn-secondary" (click)="resetFormTravailleur()"> <i
                                class="bi bi-arrow-clockwise me-1" style="font-size: 12px;"></i> Réinitialiser</button>
                        <span class="text-secondary ms-3" style="font-size: 11px;">(*) Champs obligatoire</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal ONGLET -->
<div class="modal fade" id="onglet_param" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des onglets</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <ng-container *ngFor="let onglet of liste_tab; let i = index">
                            <div class="col-4 mb-2 d-flex flex-row align-items-center"
                                id="nom-complet" *ngIf="onglet.libelle != null">
                                <input type="text" [(ngModel)]="onglet.libelle" class="form-control form-control-sm text-center" (change)="editOnglet(onglet.libelle, onglet.id_onglet)">
                                <i class="bi bi-trash text-danger" id="icon-trash" style="font-size: 12px;" (click)="deleteOnglet(onglet.id_onglet, i)"></i>
                            </div>
                        </ng-container>
                        <div class="col-4">
                            <input type="text" class="form-control form-control-sm text-center" [(ngModel)]="new_onglet" (change)="addOnglet(new_onglet)" placeholder="+ Nouveau">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal FORMATION -->
<div class="modal fade" id="param_formation" data-bs-backdrop="static" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des formations</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <input type="text" class="form-control p-3" [(ngModel)]="formationAdded"
                            style="font-size: 13px;" placeholder="Nouvelle formation">
                        <button class="btn-special ms-2" *ngIf="!is_editing_formation" (click)="addFormation()"
                            [disabled]="formationAdded == ''">
                            <i class="bi bi-check-lg"></i> </button>
                        <button class="btn-special ms-2" *ngIf="is_editing_formation" (click)="editFormation()"
                            [disabled]="formationAdded == ''">
                            <i class="bi bi-pen"></i> </button>
                        <button class="btn-special ms-1" (click)="resetFormationAdded()"
                            [disabled]="formationAdded == ''"> <i class="bi bi-x-lg"></i> </button>
                    </div>
                    <hr>
                    <div class="accordion" id="accordionExample">
                        <ng-container *ngFor="let item of formations; let i = index">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button"
                                        data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i"
                                        aria-expanded="true" aria-controls="collapseOne" id="nom-complet">
                                        {{i+1}} - {{item.libelle_formation}} <span class="text-secondary ms-2"
                                            style="font-size: 11px;">({{item.type.length}})</span>
                                        <i class="bi bi-pen text-warning" id="icon-edit"
                                            (click)="toEditFormation(item, i)" style="font-size: 12px;"></i>
                                        <i class="bi bi-trash text-danger" id="icon-trash"
                                            (click)="deleteFormation(item, i)" style="font-size: 12px;"></i>
                                    </button>
                                </h2>
                                <div [attr.id]="'collapseOne'+i" class="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row">
                                            <ng-container *ngFor="let type of item.type_detail; let y = index">
                                                <div class="col-4 mb-2 d-flex flex-row align-items-center"
                                                    id="nom-complet" *ngIf="type.libelle_type_formation != null">
                                                    <input type="text" [(ngModel)]="type.libelle_type_formation"
                                                        class="form-control form-control-sm text-center"
                                                        style="background-color: #f8f8f8;"
                                                        (change)="setTypeFormation(type, type.libelle_type_formation)">
                                                    <i class="bi bi-trash text-danger" id="icon-trash"
                                                        (click)="deleteTypeFormation(type, item, y)"
                                                        style="font-size: 12px;"></i>
                                                </div>
                                            </ng-container>
                                            <div class="col-4">
                                                <input type="text" [(ngModel)]="item.typeAdded"
                                                    class="form-control form-control-sm text-center"
                                                    style="background-color: #f8f8f8;" placeholder="+ Nouveau"
                                                    (change)="addTypeFormation(i, item.typeAdded)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>