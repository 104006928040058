import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Page404Component } from './page404/page404.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AccueilComponent } from './accueil/accueil.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GestionMenuComponent } from './gestion-menu/gestion-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipe } from './services/filter/filter.pipe';
import { MatButtonModule } from '@angular/material/button';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { BodyFormComponent } from './body-form/body-form.component';
import { DragdropDirective } from './directives/draganddrop/dragdrop.directive';
import { CalendrierComponent } from './formation/calendrier/calendrier.component';
import { ModalComponent } from './formation/calendrier/modal/modal.component';
import { AgendaComponent } from './formation/agenda/agenda.component';
import { TableauFormationComponent } from './formation/tableau-formation/tableau-formation.component';
import { ImportationComponent } from './formation/importation/importation.component';
import { RessourceComponent } from './ressource/ressource.component';
import { CapitalizeFirstLetterPipe } from './pipe/capitalize-first-letter.pipe';
import { OrgChartComponent } from './ressource/org-chart/org-chart.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    AccueilComponent,
    UtilisateurComponent,
    GestionMenuComponent,
    FilterPipe,
    CalendrierComponent,
    ModalComponent,
    AgendaComponent,
    BodyFormComponent,
    TableauFormationComponent,
    ImportationComponent,
    DragdropDirective,
    RessourceComponent,
    CapitalizeFirstLetterPipe,
    OrgChartComponent,
  ],
  imports: [ 
    MatButtonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
