 <div class="d-flex flex-row align-items-center justify-content-between">
    <div class="d-flex flex-row align-items-center">
        <div class="form-floating">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example" [(ngModel)]="departement" (change)="viewDataSousDepartement(departement)">
                <option selected value="" disabled>Veuillez sélectionner un département</option>
                <option *ngFor="let departement of departements" [value]="departement.id_departement">{{departement.code_organisation}} - {{departement.nom_departement}}</option>
            </select>
            <label for="floatingSelect">Département</label>
        </div>
        <div class="form-floating ms-2" *ngIf="departement != ''">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example" [(ngModel)]="sous_departement_selected" (change)="viewDataCompetence(departement, sous_departement_selected)">
                <option selected value="" disabled>Veuillez sélectionner un sous département</option>
                <option *ngFor="let sous_departement of sous_departement_dynamique" [value]="sous_departement.id_sous_departement">{{sous_departement.nom_sous_departement}}</option>
            </select>
            <label for="floatingSelect">Sous département</label>
        </div>
        <div class="ms-3" *ngIf="!is_loading">
            <button class="btn-special" data-bs-toggle="modal" data-bs-target="#sous_departement_param" [hidden]="departement == ''"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Sous-département </button>
            <button class="btn-special ms-2" data-bs-toggle="modal" data-bs-target="#onglet_param" [hidden]="sous_departement_selected == ''"> <i class="bi bi-gear me-1" style="font-size: 12px;"></i> Onglets </button>
        </div>
        <div class="ms-3">
            <ul class="nav nav-tabs">
                <ng-container *ngFor="let tab of liste_tab; let i = index">
                    <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active':i == 0}" data-bs-toggle="tab"
                            [href]="'#onglet'+tab.id_onglet_formation_procedure" (click)="equipe = tab.id_onglet_formation_procedure">{{tab.libelle}}</a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="p-3 rounded shadow-sm text-center" appDragdrop style="background-color: white; width: 15%;" (dropped)="onFilesDropped($event)" *ngIf="liste_tab.length > 0 && !is_uploading">
        <span class="text-secondary text-center" style="font-size: 12px;"> <i class="bi bi-download me-1"></i> Déposer ici le fichier à importer</span>
    </div>
    <div class="text-center text-secondary p-3 rounded shadow-sm" style="background-color: white; width: 15%;" *ngIf="is_uploading">
        <div class="spinner-grow spinner-grow-sm text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
 </div>

 <app-load-page *ngIf="is_loading"></app-load-page>

<div class="tab-content" *ngIf="!is_loading">
    <ng-container *ngFor="let tab of liste_tab; let t = index">
        <div class="tab-pane fade" [ngClass]="{'show active':t == 0}" [id]="'onglet'+tab.id_onglet_formation_procedure">
            <div class="mt-3 d-flex flex-row align-items-center" *ngIf="tab.data.length > 0">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" [href]="'#tableau-formation'+t">Formation</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" [href]="'#tableau-procedure'+t">Procédure</a>
                    </li>
                </ul>
                <button class="btn btn-sm btn-outline-success border-0" (click)="downloadFile(tab.data[0], tab.libelle)">
                    <span class="spinner-border spinner-border-sm me-1" style="font-size: 12px;" aria-hidden="true" *ngIf="is_downloading"></span>
                    <span *ngIf="!is_downloading"> <i class="bi bi-file-earmark-excel me-1" style="font-size: 12px;"></i></span>
                    Exporter les données</button>
            </div>
            <div class="tab-content" *ngIf="tab.data.length > 0">
                <div class="tab-pane fade show active" [id]="'tableau-formation'+t">
                    <div class="overflow-x-auto" style="max-height: 79vh;">
                        <table class="table table-sm table-bordered table-hover" style="text-align: center;">
                            <tbody>
                                <tr *ngFor="let ligne of tab.data[0].formation; let i = index" [ngClass]="{'ligne-fixed':i == 0}">
                                    <td *ngFor="let colonne of ligne; let t = index" [ngClass]="{'text-nom': i != 0 && t == 0 }" style="white-space:nowrap" [ngStyle]="{'background-color': i == 0 && colonne.bgColor == '#FFFFFF' ? '#bbdff2' : colonne.bgColor}">
                                        <span [ngClass]="{'text-vertical':i == 0}">
                                            {{colonne.value}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" [id]="'tableau-procedure'+t">
                    <div class="overflow-x-auto" style="max-height: 79vh;">
                        <table class="table table-sm table-bordered table-hover" style="text-align: center;">
                            <tbody>
                                <tr *ngFor="let ligne of tab.data[0].procedure; let i = index" [ngClass]="{'ligne-fixed':i == 0}">
                                    <td *ngFor="let colonne of ligne; let t = index" [ngClass]="{'text-nom': i != 0 && t == 0 }" style="white-space:nowrap" [ngStyle]="{'background-color': i == 0 && colonne.bgColor == '#FFFFFF' ? '#bbdff2' : colonne.bgColor}">
                                        <span [ngClass]="{'text-vertical':i == 0}">
                                            {{colonne.value}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<!-- Modal ONGLET -->
<div class="modal fade" id="onglet_param" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des onglets</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="row">
                        <ng-container *ngFor="let onglet of liste_tab; let i = index">
                            <div class="col-4 mb-2 d-flex flex-row align-items-center"
                                id="nom-complet" *ngIf="onglet.libelle != null">
                                <input type="text" [(ngModel)]="onglet.libelle" class="form-control form-control-sm text-center" (change)="editOnglet(onglet.libelle, onglet.id_onglet_formation_procedure)">
                                <i class="bi bi-trash text-danger" id="icon-trash" style="font-size: 12px;" (click)="deleteOnglet(onglet.id_onglet_formation_procedure, i)"></i>
                            </div>
                        </ng-container>
                        <div class="col-4">
                            <input type="text" class="form-control form-control-sm text-center" [(ngModel)]="new_onglet" (change)="addOnglet(new_onglet)" placeholder="+ Nouveau">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal SOUS DEPARTEMENT -->
<div class="modal fade" id="sous_departement_param" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body rounded" style="background-color: #f8f8f8;">
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <h5>Paramétrage des sous départements</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="mt-3">
                    <div class="d-flex flex-row align-items-center"> 
                        <input type="text" style="font-size: 12px;" [ngClass]="{'is-invalid':is_added_sous_departement && sous_departement_added == ''}" class="form-control" placeholder="Sous département" [(ngModel)]="sous_departement_added">
                        <div class="d-flex flex-row align-items-center ms-3">
                            <button class="btn-special" (click)="addSousDepartement()"> <i class="bi bi-plus-lg" style="font-size: 12px;"></i> </button>
                            <button class="btn-special ms-1" (click)="resetFormSousDepartement()"> <i class="bi bi-x-lg" style="font-size: 12px;"></i> </button>
                        </div>
                    </div>
                    <hr>
                    <div class="mt-2">
                        <ng-container *ngFor="let sous_departement of sous_departement_dynamique; let i = index">
                            <div class="d-flex flex-row align-items-center mt-2">
                                <input type="text" class="form-control" (change)="updateSousDepartement(sous_departement.id_sous_departement, sous_departement.nom_sous_departement)" [(ngModel)]="sous_departement.nom_sous_departement">
                                <button class="btn btn-sm btn-outline-danger border-0 ms-1" (click)="deleteSousDepartement(sous_departement.id_sous_departement, i)"> <i class="bi bi-trash"></i> </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>