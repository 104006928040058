import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from 'src/app/services/file/file.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-importation',
  templateUrl: './importation.component.html',
  styleUrls: ['./importation.component.css']
})
export class ImportationComponent {

  is_uploading:any = false

  constructor(
    public activeModal: NgbActiveModal,
    private toast:ToastService,
    private fileService:FileService
  ) { }

  onFilesDropped(files: FileList){
    const droppedFile = files[0]
    if(droppedFile){
      const fileType = droppedFile.type
      const fileName = droppedFile.name
      const isExcelMimeType = fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel';
      const isExcelExtension = fileName.endsWith('.xlsx') || fileName.endsWith('.xls');
      if (!isExcelMimeType && !isExcelExtension){
        this.toast.Error("Format de fichier invalide")
        return
      }
      this.is_uploading = true
      this.fileService.uploadFileService(droppedFile).subscribe((data:any) => {
        this.toast.Success("Importation terminée")
        this.is_uploading = false
      })
    }
  }

}
